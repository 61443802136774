import { Button, Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../../theme";

export const StyledFlexbox = styled(Flexbox)`
  padding: 4px 8px;
  background-color: ${COLORS.iceberg};
  width: fit-content;
  font-weight: 600;
`;

export const StyledButton = styled(Button)`
  padding: 4px 8px;
  background-color: ${COLORS.iceberg};
  font-weight: 600;
`;
