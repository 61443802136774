import { useState } from "react";

import { COMMENT_MAXIMUM_LENGTH } from "../../Anomaly.constants";
import CommentCharacterCounter from "../CommentCharacterCounter";
import {
  COMMENT_MODAL_PRIMARY_ACTION_LABEL,
  COMMENT_MODAL_SECONDARY_ACTION_LABEL,
  COMMENT_MODAL_TEXTAREA_PLACEHOLDER,
  COMMENT_MODAL_TEXTAREA_TEST_ID,
  COMMENT_MODAL_TITLE,
} from "./CommentModal.constants";
import { StyledModal, StyledTextArea } from "./CommentModal.styles";
import { ICommentModalProps } from "./CommentModal.types";

export const CommentModal = ({ onSaveClick, ...args }: ICommentModalProps) => {
  // #region Local state
  const [isCommentInvalid, setIsCommentInvalid] = useState(false);
  const [comment, setComment] = useState("");
  // #endregion

  const validateAndSave = () => {
    if (!comment || comment.length > COMMENT_MAXIMUM_LENGTH) {
      setIsCommentInvalid(true);
      return;
    }

    onSaveClick(comment);
    setComment("");
  };

  return (
    <StyledModal
      {...args}
      onPrimaryActionClick={() => {
        validateAndSave();
      }}
      onSecondaryActionClick={() => {
        setComment("");
        args.onSecondaryActionClick?.();
      }}
      onClose={() => {
        setComment("");
        args.onClose?.();
      }}
      title={args.title || COMMENT_MODAL_TITLE}
      primaryActionLabel={
        args.primaryActionLabel || COMMENT_MODAL_PRIMARY_ACTION_LABEL
      }
      secondaryActionLabel={
        args.secondaryActionLabel || COMMENT_MODAL_SECONDARY_ACTION_LABEL
      }
    >
      <StyledTextArea
        data-testid={COMMENT_MODAL_TEXTAREA_TEST_ID}
        value={comment}
        placeholder={COMMENT_MODAL_TEXTAREA_PLACEHOLDER}
        invalid={isCommentInvalid}
        onChange={(e) => {
          setComment(e.target.value);
          setIsCommentInvalid(false);
        }}
      />
      <CommentCharacterCounter commentLength={comment.length} />
    </StyledModal>
  );
};

export default CommentModal;
