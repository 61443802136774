import { useAtom } from "jotai";

import { useTabletMode } from "../../../../common/hooks";
import ExpandIcon from "../../../../components/TableAddons/ExpandIcon";
import { StyledTable } from "../../../../components/TableWrapper/TableWrapper.styles";
import { TopHeader } from "../../../../components/TopHeader/TopHeader.styles";
import LandingEndurTraderExpandedRow from "../../../Landing/components/LandingEndurTraderExpandedRow";
import ResponsiveDataView from "../../../Landing/components/ResponsiveDataView";
import { anomalyRowDataAtom } from "../../../Landing/Landing.atoms";
import { ANOMALIES_COLUMNS } from "../../../Landing/Landing.constants";
import {
  IEndurTraderAnomaliesRowData,
  IEndurTraderAnomaliesTableData,
} from "../../../Landing/Landing.types";
import { ANOMALY_HEADER_TEXT } from "./AnomalyTableDetailSection.constants";
import { AnomalyTableDetailSectionContainer } from "./AnomalyTableDetailSection.styles";

const AnomalyTableDetailSection = () => {
  // #region Atoms
  const [anomaly] = useAtom(anomalyRowDataAtom);
  // #endregion

  // #region isTabletMode
  const isTabletMode = useTabletMode();
  // #endregion

  if (!anomaly) {
    return null;
  }

  const tableData: IEndurTraderAnomaliesTableData = {
    totalAnomalies: 1,
    anomalies: [anomaly],
  };

  return (
    <AnomalyTableDetailSectionContainer flexDirection="column">
      <TopHeader>{ANOMALY_HEADER_TEXT}</TopHeader>
      {isTabletMode ? (
        <ResponsiveDataView
          data={tableData?.anomalies.length ? tableData.anomalies : []}
          columns={ANOMALIES_COLUMNS}
          rowKey={({ id }) => `${id}`}
          expandable={{
            expandedRowRender: (row) => (
              <LandingEndurTraderExpandedRow
                row={row as IEndurTraderAnomaliesRowData}
                displayMobileView
              />
            ),
          }}
          variant="detailsView"
        />
      ) : (
        <StyledTable
          data={tableData?.anomalies.length ? tableData.anomalies : []}
          columns={ANOMALIES_COLUMNS}
          rowKey={({ id }) => `${id}`}
          expandable={{
            expandIcon: ExpandIcon,
            expandedRowRender: (row) => (
              <LandingEndurTraderExpandedRow
                row={row as IEndurTraderAnomaliesRowData}
              />
            ),
          }}
        />
      )}
    </AnomalyTableDetailSectionContainer>
  );
};

export default AnomalyTableDetailSection;
