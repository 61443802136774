/* eslint-disable camelcase */
import { ColumnType } from "@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types";
import classnames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TdHTMLAttributes } from "react";

dayjs.extend(utc);

import {
  IIdentifier,
  IScoreLevel,
  TAnomalyInvestigationStatus,
  TAnomalyResolution,
  TEndurTraderAnomaliesQueryParamsOptions,
  TScoreLevel,
  TSortDirection,
} from "../../app/services/services.types";
import { DefaultRecordType } from "../../common/types";
import { INavbarMenuItem } from "../../components/Navbar/Navbar.types";
import DescriptorCellContainer from "../../components/TableAddons/DescriptorCellContainer/index";
import ResolutionCellContainer from "../../components/TableAddons/ResolutionCellContainer";
import ZScoredCellContainer from "../../components/TableAddons/ZScoredCellContainer";
import { TLandingHeaderAriaLabel } from "./Landing.types";

export const SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS =
  "shell-table--text-align-right";

export const SHELL_TABLE_MAX_WIDTH_CLASS = "shell-table--max-width";

export const LANDING_TOP_TABLE_HEADER_TEXT = "Anomalies";

export const STATUS_MENU_ITEMS: INavbarMenuItem<
  TAnomalyInvestigationStatus | undefined
>[] = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Created",
    value: "created",
  },
  {
    label: "Investigation",
    value: "investigation",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

export const SEVERITY_OPTIONS: {
  label: string;
  value: Exclude<TScoreLevel, "none"> | undefined;
}[] = [
  {
    label: "Low",
    value: "low",
  },
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
];

export const ALL_REPORTS_DROPDOWN_MENU_ITEMS: INavbarMenuItem[] = [
  {
    label: "Endur Trader",
  },
];

export const COLUMN_HEADER_TEXTS: TLandingHeaderAriaLabel = {
  descriptor: "Descriptor",
  trade_date_count: "Trade date count",
  zscore_count: "Z-score: count",
  zscore_volume: "Z-score: volume",
  zscore_average: "Z-score: average",
  reporting_date: "Date Created",
  resolution: "Resolution",
};

export const COLUMN_HEADER_ARIA_LABELS: TLandingHeaderAriaLabel = {
  descriptor: "descriptor",
  trade_date_count: "trade_date_count",
  zscore_count: "zscore_count",
  zscore_volume: "zscore_volume",
  zscore_average: "zscore_average",
  reporting_date: "reporting_date",
  resolution: "resolution",
};

export const ANOMALIES_COLUMNS: ColumnType<DefaultRecordType>[] = [
  {
    title: "Descriptor",
    dataIndex: "descriptor",
    width: "300px",
    className: "descriptor",
    key: "descriptor",
    render: (descriptor: IIdentifier[]) => (
      <DescriptorCellContainer descriptor={descriptor} />
    ),
  },
  {
    title: "Trade date count",
    dataIndex: "tradeDateCount",
    key: "tradeDateCount",
    className: classnames(
      SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
      SHELL_TABLE_MAX_WIDTH_CLASS
    ),
    onHeaderCell: () => {
      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
      props.className = classnames(
        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
        SHELL_TABLE_MAX_WIDTH_CLASS
      );
      return props;
    },
  },
  {
    title: "Z-score: count",
    dataIndex: "zScoreCount",
    key: "zScoreCount",
    className: classnames(
      SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
      SHELL_TABLE_MAX_WIDTH_CLASS
    ),
    render: ({ value, score }: IScoreLevel) => (
      <ZScoredCellContainer value={value} score={score} />
    ),
    onHeaderCell: () => {
      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
      props.className = classnames(
        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
        SHELL_TABLE_MAX_WIDTH_CLASS
      );
      return props;
    },
  },
  {
    title: "Z-score: volume",
    dataIndex: "zScoreVolume",
    key: "zScoreVolume",
    className: `${SHELL_TABLE_MAX_WIDTH_CLASS}`,
    render: ({ value, score }: IScoreLevel) => (
      <ZScoredCellContainer value={value} score={score} />
    ),
    onHeaderCell: () => {
      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
      props.className = classnames(
        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
        SHELL_TABLE_MAX_WIDTH_CLASS
      );
      return props;
    },
  },
  {
    title: "Z-score: average",
    dataIndex: "zScoreAverage",
    key: "zScoreAverage",
    className: `${SHELL_TABLE_MAX_WIDTH_CLASS}`,
    render: ({ value, score }: IScoreLevel) => (
      <ZScoredCellContainer value={value} score={score} />
    ),
    onHeaderCell: () => {
      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
      props.className = classnames(
        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
        SHELL_TABLE_MAX_WIDTH_CLASS
      );
      return props;
    },
  },
  {
    title: "Date Created",
    dataIndex: "reportingDate",
    key: "reportingDate",
    render: (reportingDate) => dayjs.utc(reportingDate).format("MM/DD/YYYY"),
  },
  {
    title: "Resolution",
    dataIndex: "resolution",
    key: "resolution",
    width: "150px",
    render: (resolution: TAnomalyResolution) => (
      <ResolutionCellContainer
        resolution={resolution}
      ></ResolutionCellContainer>
    ),
  },
];

export const EXPORT_ERROR_MESSAGE =
  "There was a problem exporting your file, please try again later.";
export const EXPORT_SUCCESS_MESSAGE = "Your file was exported successfully.";

// #region TESTING CONSTANTS
export const SORT_KEY: TEndurTraderAnomaliesQueryParamsOptions = "sort";
export const DIRECTION_KEY: TEndurTraderAnomaliesQueryParamsOptions =
  "direction";
export const ASCENDING_DIRECTION: TSortDirection = "ascending";
export const DESCENDING_DIRECTION: TSortDirection = "descending";
// # endregion
