import { Modal } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";
// This are common styled components used in the app

export const StyledH1 = styled.h1`
  font-family: "Shell Font";
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 36px;
`;

export const StyledModal = styled(Modal)`
  .shell-modal-container {
    min-width: 43.75rem;
  }

  .shell-modal-container-header {
    border-bottom: none;
  }

  .shell-modal-container-close {
    display: none;
  }

  .shell-modal-container-footer {
    border-top: none;
  }
`;
