import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../theme";

export const NoAccessContainer = styled(Flexbox)`
  padding-top: 30vh;
`;

export const NoAccessText = styled.p`
  max-width: 31.875rem;
  font-family: "SF Pro Text", sans-serif;
`;

export const NoAccessShellLogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2.5rem;
`;

export const NoAccessHeader = styled.h1`
  margin: 1.188rem 0;
  font-family: "Shell Font", sans-serif;
  font-weight: 700;
`;

export const StyledAnchor = styled.a`
  color: ${COLORS.mariner};
`;
