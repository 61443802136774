import { TELESCOPE_API_ROUTES } from "./services.constants";
import { IAdminBidDayLabel, ITelescopeAPIResponse } from "./services.types";
import { telescopeBaseAPIService } from "./telescope.base.service";

export const adminAPISlice = telescopeBaseAPIService.injectEndpoints({
  endpoints: (builder) => ({
    getBiddayLabel: builder.query<IAdminBidDayLabel | null, void>({
      query: () => ({
        url: `${TELESCOPE_API_ROUTES.admin.platts}/biddaylabel`,
      }),
      transformResponse: (response: ITelescopeAPIResponse<IAdminBidDayLabel>) =>
        response.data,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetBiddayLabelQuery } = adminAPISlice;
