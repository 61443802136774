import { Button } from "@sede-x/shell-ds-react-framework";
import {
  ChevronRight,
  ChevronUp,
} from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { ReactElement } from "react";

import { RenderExpandIconProps } from "../../TableWrapper/TableWrapper.types";
import {
  COLLAPSE_ICON_TEST_ID,
  EXPAND_ICON_TEST_ID,
} from "./ExpandIcon.constants";

const ExpandIcon = <T extends Record<string, any>>({
  expanded,
  record,
  onExpand,
}: RenderExpandIconProps<T>): ReactElement => (
  <Button
    iconOnly
    icon={
      expanded ? (
        <ChevronUp data-testid={EXPAND_ICON_TEST_ID} />
      ) : (
        <ChevronRight data-testid={COLLAPSE_ICON_TEST_ID} />
      )
    }
    size="small"
    variant="transparent"
    onClick={(e) => {
      onExpand(record, e);
    }}
    className={expanded ? EXPAND_ICON_TEST_ID : COLLAPSE_ICON_TEST_ID}
  />
);

export default ExpandIcon;
