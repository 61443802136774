import { Flexbox } from "@sede-x/shell-ds-react-framework";

import { StyledH1 } from "../Common.styles";
import { ChildrenContainer } from "./Header.styles";
import { IHeaderProps } from "./Header.types";

const Header = ({ text, children, testId, className }: IHeaderProps) => (
  <Flexbox data-testid={testId} className={className}>
    <StyledH1>{text}</StyledH1>
    {children && <ChildrenContainer>{children}</ChildrenContainer>}
  </Flexbox>
);

export default Header;
