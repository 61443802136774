import { ISvgIconInterface } from "../icons.types";

const NoAccessIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="105"
    height="111"
    viewBox="0 0 105 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M33.8176 16.0139L76.7933 19.3406L95.842 59.5681L71.1824 94.9217L27.6724 90.4666L9.52434 52.1411L33.8176 16.0139Z"
      fill="#E2E5EA"
    />
    <path
      d="M52.4996 42.1406V39.3281C52.4996 38.3957 52.87 37.5015 53.5293 36.8422C54.1886 36.1829 55.0828 35.8125 56.0152 35.8125C56.9476 35.8125 57.8419 36.1829 58.5012 36.8422C59.1605 37.5015 59.5309 38.3957 59.5309 39.3281V44.9531C59.5309 44.0207 59.9013 43.1265 60.5606 42.4672C61.2199 41.8079 62.1141 41.4375 63.0465 41.4375C63.9789 41.4375 64.8731 41.8079 65.5324 42.4672C66.1917 43.1265 66.5621 44.0207 66.5621 44.9531V59.7188C66.5621 61.5655 66.1984 63.3941 65.4917 65.1002C64.785 66.8064 63.7491 68.3566 62.4433 69.6624C61.1375 70.9683 59.5872 72.0041 57.8811 72.7108C56.175 73.4175 54.3463 73.7812 52.4996 73.7812C44.7301 73.7812 41.2496 69.5625 35.5191 57.4863C35.0529 56.6775 34.9272 55.7165 35.1695 54.8149C35.4117 53.9133 36.0023 53.1449 36.8111 52.6787C37.62 52.2125 38.5809 52.0867 39.4825 52.329C40.3841 52.5713 41.1526 53.1618 41.6188 53.9707L45.4684 60.6504V42.1406C45.4684 41.2082 45.8388 40.314 46.4981 39.6547C47.1574 38.9954 48.0516 38.625 48.984 38.625C49.9164 38.625 50.8106 38.9954 51.4699 39.6547C52.1292 40.314 52.4996 41.2082 52.4996 42.1406V42.1406Z"
      fill="#CDD1D6"
    />
    <path
      d="M59.5309 54.0938V44.9531C59.5309 44.0207 59.9013 43.1265 60.5606 42.4672C61.2199 41.8079 62.1141 41.4375 63.0465 41.4375C63.9789 41.4375 64.8731 41.8079 65.5324 42.4672C66.1917 43.1265 66.5621 44.0207 66.5621 44.9531V59.7188C66.5621 61.5655 66.1984 63.3941 65.4917 65.1002C64.785 66.8064 63.7491 68.3566 62.4433 69.6624C61.1375 70.9683 59.5872 72.0041 57.8811 72.7108C56.175 73.4175 54.3463 73.7812 52.4996 73.7812C44.7301 73.7812 41.2496 69.5625 35.5191 57.4863C35.0529 56.6775 34.9272 55.7165 35.1695 54.8149C35.4117 53.9133 36.0023 53.1449 36.8111 52.6787C37.62 52.2125 38.5809 52.0867 39.4825 52.329C40.3841 52.5713 41.1526 53.1618 41.6188 53.9707L45.4684 60.6504V42.1406C45.4684 41.2082 45.8388 40.314 46.4981 39.6547C47.1574 38.9954 48.0516 38.625 48.984 38.625C49.9164 38.625 50.8106 38.9954 51.4699 39.6547C52.1292 40.314 52.4996 41.2082 52.4996 42.1406V54.0938"
      stroke="#999EA3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.5312 44.9531V39.3281C59.5312 38.3957 59.1609 37.5015 58.5015 36.8422C57.8422 36.1829 56.948 35.8125 56.0156 35.8125C55.0832 35.8125 54.189 36.1829 53.5297 36.8422C52.8704 37.5015 52.5 38.3957 52.5 39.3281V42.1406"
      stroke="#999EA3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.7998 35.9804L53.4885 17.0536C52.8758 16.7054 52.1251 16.7054 51.5125 17.0536L19.2132 35.4054C18.5876 35.7609 18.2012 36.4249 18.2012 37.1444V74.5952C18.2012 75.3025 18.5748 75.9572 19.1837 76.317L51.5027 95.4146C52.1198 95.7793 52.8849 95.7858 53.5082 95.4317L85.7878 77.091C86.4133 76.7355 86.7998 76.0715 86.7998 75.352V53.9096"
      stroke="#999EA3"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default NoAccessIcon;
