import { ITrendDataProps } from "./TrandData.types";
import {
  StyledAnomalyTrendCaption,
  StyledAnomalyTrendCaptionContainer,
  StyledAnomalyTrendCount,
  StyledAnomalyTrendCountContainer,
  StyledAnomalyTrendIconContainer,
} from "./TrendData.styles";

const TrendDataSection = ({
  color,
  count,
  caption,
  icon,
  size,
  testId,
  lowSeverity = false,
  ...props
}: ITrendDataProps) => (
  <StyledAnomalyTrendCountContainer
    color={color}
    flexDirection={size === "large" ? "column" : "row"}
    size={size}
    data-testid={testId}
    {...props}
  >
    <StyledAnomalyTrendCount color={color} size={size}>
      <p>{count}</p>
    </StyledAnomalyTrendCount>
    <StyledAnomalyTrendCaptionContainer lowSeverity={lowSeverity} size={size}>
      <StyledAnomalyTrendCaption color={color}>
        <p>{caption}</p>
      </StyledAnomalyTrendCaption>
      {icon && (
        <StyledAnomalyTrendIconContainer lowSeverity={lowSeverity}>
          {icon}
        </StyledAnomalyTrendIconContainer>
      )}
    </StyledAnomalyTrendCaptionContainer>
  </StyledAnomalyTrendCountContainer>
);

export default TrendDataSection;
