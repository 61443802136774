import { ISvgIconInterface } from "../icons.types";

const NotFoundAnomaliesIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="105"
    height="111"
    viewBox="0 0 105 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M33.8176 16.0139L76.7933 19.3406L95.842 59.5681L71.1824 94.9217L27.6724 90.4666L9.52434 52.1411L33.8176 16.0139Z"
      fill="#E2E5EA"
    />
    <path
      d="M86.7988 35.9804L53.4875 17.0536C52.8748 16.7054 52.1242 16.7054 51.5115 17.0536L19.2122 35.4054C18.5866 35.7609 18.2002 36.4249 18.2002 37.1444V74.5952C18.2002 75.3025 18.5738 75.9572 19.1827 76.317L51.5017 95.4146C52.1188 95.7793 52.8839 95.7858 53.5072 95.4317L85.7868 77.091C86.4124 76.7355 86.7988 76.0715 86.7988 75.352V53.9096"
      stroke="#999EA3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M50.1611 69.4981C59.2021 69.4981 66.5313 62.1689 66.5313 53.1279C66.5313 44.087 59.2021 36.7578 50.1611 36.7578C41.1202 36.7578 33.791 44.087 33.791 53.1279C33.791 62.1689 41.1202 69.4981 50.1611 69.4981Z"
      fill="#CDD1D6"
    />
    <path
      d="M50.1611 69.4981C59.2021 69.4981 66.5313 62.1689 66.5313 53.1279C66.5313 44.087 59.2021 36.7578 50.1611 36.7578C41.1202 36.7578 33.791 44.087 33.791 53.1279C33.791 62.1689 41.1202 69.4981 50.1611 69.4981Z"
      stroke="#999EA3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.7373 64.707L71.2086 74.1783"
      stroke="#999EA3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotFoundAnomaliesIcon;
