import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  IIdentifier,
  IInvestigator,
  IScoreLevel,
  IStdDeviation,
  ITerm,
  ITradingDate,
  TAnomalyInvestigationStatus,
  TAnomalyResolution,
  TEndurTraderAnomaliesQueryParams,
  TReportType,
  TScoreLevel,
  TSortDirection,
} from "../../app/services/services.types";

dayjs.extend(utc);

export type TAnomaliesParams = {
  limit?: number;
  offset: number;
  sort?: TLandingHeaderKeys;
  direction?: TSortDirection;
  anomaliesFilters: TAnomaliesFilters;
};

export type TAnomalyFilter<T = string> = {
  value: T;
  label: string;
};

export type TAnomaliesFilters = {
  status?: TAnomalyFilter<TAnomalyInvestigationStatus>;
  report?: TAnomalyFilter<TReportType>[];
  resolution?: TAnomalyFilter;
  severity?: TAnomalyFilter<Exclude<TScoreLevel, "none">>;
  startDate?: string;
  endDate?: string;
  trader?: string[];
  location?: string[];
  counterparty?: string[];
  broker?: string[];
  tradeGroup?: string[];
  dealType?: string[];
  investigator?: TAnomalyFilter[];
};

export const anomalyParamsToQueryParams = (
  params: TAnomaliesParams
): TEndurTraderAnomaliesQueryParams => ({
  limit: params.limit,
  offset: params.offset,
  sort: params.sort,
  direction: params.direction,
  status: params.anomaliesFilters.status?.value,
  report: params.anomaliesFilters.report?.map(({ value }) => value),
  resolution: params.anomaliesFilters.resolution?.value,
  severity: params.anomaliesFilters.severity?.value,
  startDate: params.anomaliesFilters.startDate
    ? dayjs.utc(params.anomaliesFilters.startDate).startOf("day").format()
    : undefined,
  endDate: params.anomaliesFilters.endDate
    ? dayjs.utc(params.anomaliesFilters.endDate).startOf("day").format()
    : undefined,
  trader: params.anomaliesFilters.trader,
  location: params.anomaliesFilters.location,
  counterparty: params.anomaliesFilters.counterparty,
  broker: params.anomaliesFilters.broker,
  tradeGroup: params.anomaliesFilters.tradeGroup,
  dealType: params.anomaliesFilters.dealType,
  investigator: params.anomaliesFilters.investigator?.map(({ value }) => value),
});

export interface IEndurTraderAnomaliesRowData {
  id: number;
  descriptor: IIdentifier[];
  tradeDateCount: number;
  tradeDateVolume: number;
  zScoreCount: IScoreLevel;
  zScoreVolume: IScoreLevel;
  zScoreAverage: IScoreLevel;
  investigators: IInvestigator[];
  resolution: TAnomalyResolution;
  stdDeviation: IStdDeviation;
  tradingDate: ITradingDate;
  term: ITerm;
  reportName: TReportType;
  status: TAnomalyInvestigationStatus;
  reportingDate: string;
}

export interface IEndurTraderAnomaliesTableData {
  totalAnomalies: number;
  anomalies: IEndurTraderAnomaliesRowData[];
}

export type TLandingHeaderAriaLabel = {
  descriptor: string;
  trade_date_count: string;
  zscore_count: string;
  zscore_volume: string;
  zscore_average: string;
  reporting_date: string;
  resolution: string;
};

export type TLandingHeaderKeys = keyof TLandingHeaderAriaLabel;
