import { Menu, NavBar } from "@sede-x/shell-ds-react-framework";
import {
  HomeSolid,
  Map as MapIcon,
  Refinery,
} from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import styled from "styled-components";

import { COLORS } from "../../theme";
import { INavbarIconProps } from "./Navbar.types";

export const StyledNavBar = styled(NavBar)`
  padding: 8px 12px;
  background-color: ${COLORS.white};
  min-width: 40.625rem;
`;

export const StyledRefineryIcon = styled(Refinery)<INavbarIconProps>`
  path {
    fill: ${(props) => (props.disabled ? COLORS.boulder : COLORS.tundora)};
  }
`;

export const StyledHomeSolidIcon = styled(HomeSolid)<INavbarIconProps>`
  path {
    fill: ${(props) => (props.disabled ? COLORS.boulder : COLORS.tundora)};
  }
`;

export const StyledMapIcon = styled(MapIcon)<INavbarIconProps>`
  path {
    fill: ${(props) => (props.disabled ? COLORS.boulder : COLORS.tundora)};
  }
`;

export const StyledMenu = styled(Menu)`
  .shell-menu-item-disabled span {
    color: ${COLORS.boulder} !important;
  }
`;
