import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { StyledTable } from "../../../../components/TableWrapper/TableWrapper.styles";

export const AnomalyRawDataSectionContainer = styled(Flexbox)``;

export const RawDataTable = styled(StyledTable)`
  & tr:hover td {
    background-color: white !important;
  }

  .shell-table-cell-row-hover {
    background-color: white !important;
  }
`;
