import { Flexbox } from "@sede-x/shell-ds-react-framework";

import { StyledH1 } from "../../Common.styles";
import NoAnomaliesIcon from "../../icons/NoAnomalies";
import NotFoundAnomaliesIcon from "../../icons/NotFoundAnomalies";
import {
  NO_ANOMALIES_HEADER,
  NO_ANOMALIES_ICON_TEST_ID,
  NO_ANOMALIES_MESSAGE,
  NO_ANOMALIES_YET_HEADER,
  NO_RESULTS_FOUND_HEADER,
  NO_RESULTS_FOUND_ICON_TEST_ID,
  NO_RESULTS_FOUND_MESSAGE,
} from "./NoData.constants";
import { MessageContainer } from "./NoDataMessageRow.styles";
import { INoDataMessageRowProps } from "./NoDataMessageRow.types";

const NoDataMessageRow = ({
  showNoData = false,
  showNoResultsFound = false,
  showNoDataYet = false,
}: INoDataMessageRowProps) => {
  if (showNoData) {
    return (
      <Flexbox flexDirection="column" alignItems="center">
        <NoAnomaliesIcon testId={NO_ANOMALIES_ICON_TEST_ID} />
        <StyledH1>{NO_ANOMALIES_HEADER}</StyledH1>
        <MessageContainer>{NO_ANOMALIES_MESSAGE}</MessageContainer>
      </Flexbox>
    );
  }

  if (showNoDataYet) {
    return (
      <Flexbox flexDirection="column" alignItems="center">
        <NoAnomaliesIcon testId={NO_ANOMALIES_ICON_TEST_ID} />
        <StyledH1>{NO_ANOMALIES_YET_HEADER}</StyledH1>
        <MessageContainer>{NO_ANOMALIES_MESSAGE}</MessageContainer>
      </Flexbox>
    );
  }

  if (showNoResultsFound) {
    return (
      <Flexbox flexDirection="column" alignItems="center">
        <NotFoundAnomaliesIcon testId={NO_RESULTS_FOUND_ICON_TEST_ID} />
        <StyledH1>{NO_RESULTS_FOUND_HEADER}</StyledH1>
        <MessageContainer>{NO_RESULTS_FOUND_MESSAGE}</MessageContainer>
      </Flexbox>
    );
  }

  return null;
};

export default NoDataMessageRow;
