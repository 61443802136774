import { Button, Flexbox, Grid } from "@sede-x/shell-ds-react-framework";
import { ChevronRight } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { ReactNode } from "react";

import { useGetAnomalyReportOptionsQuery } from "../../../../app/services/telescope.dropdowns.slice";
import ReviewedBy from "../../../../components/ReviewedBy";
import {
  DETAILS_BUTTON_TEST_ID,
  DETAILS_BUTTON_TEXT,
  EXPANDED_ROW_TEST_ID,
} from "./LandingEndurTraderExpandedRow.constants";
import {
  CellBorder,
  CellHeader,
  GridContainer,
  GridMobileViewContainer,
  ReportContainer,
  StyledHeader,
} from "./LandingEndurTraderExpandedRow.styles";
import { ILandingEndurTraderExpandedRow } from "./LandingEndurTraderExpandedRow.types";

const displayGridCell = (
  CellComponent: typeof CellHeader | typeof Grid.Cell,
  area: string,
  label: string,
  data: ReactNode
) => (
  <CellComponent area={area}>
    <Flexbox flexDirection="column">
      <StyledHeader>{label}:</StyledHeader>
      <span>{data}</span>
    </Flexbox>
  </CellComponent>
);

const LandingEndurTraderExpandedRow = ({
  row,
  onClick,
  className,
  displayMobileView,
  testId = EXPANDED_ROW_TEST_ID,
}: ILandingEndurTraderExpandedRow) => {
  const { data: reportDictionary, isError } = useGetAnomalyReportOptionsQuery();

  if (isError) {
    return <div>Bad row information Error</div>;
  }

  const reportName = reportDictionary?.find(
    (rep) => rep.reportName === row.reportName
  )?.displayName;

  const GridCellComponent = displayMobileView ? CellHeader : Grid.Cell;
  const cells = {
    tradeDateVolume: displayGridCell(
      GridCellComponent,
      "tradeDateVolume",
      "Trade date volume",
      row.tradingDate.volume
    ),
    tradeDateAverage: displayGridCell(
      GridCellComponent,
      "tradeDateAverage",
      "Trade date average",
      row.tradingDate.average
    ),
    termCount: displayGridCell(
      GridCellComponent,
      "termCount",
      "Term count",
      row.term.count
    ),
    termVolume: displayGridCell(
      GridCellComponent,
      "termVolume",
      "Term volume",
      row.term.volume
    ),
    termAverage: displayGridCell(
      GridCellComponent,
      "termAverage",
      "Term average",
      row.term.average
    ),
    stdDevCount: displayGridCell(
      GridCellComponent,
      "stdDevCount",
      "Std Dev count",
      row.stdDeviation.count
    ),
    stdDevVolume: displayGridCell(
      GridCellComponent,
      "stdDevVolume",
      "Std Dev volume",
      row.stdDeviation.volume
    ),
    stdDevAverage: displayGridCell(
      GridCellComponent,
      "stdDevAverage",
      "Std Dev average",
      row.stdDeviation.average
    ),
    reviewedBy: displayGridCell(
      GridCellComponent,
      "reviewedBy",
      "Reviewed by",
      <ReviewedBy
        reviewersEmails={row.investigators.map(({ email }) => email)}
      />
    ),
    report: reportName
      ? displayGridCell(
          GridCellComponent,
          "report",
          "Report",
          <ReportContainer>{reportName}</ReportContainer>
        )
      : null,
  };

  return displayMobileView ? (
    <GridMobileViewContainer
      areas={[
        "tradeDateVolume tradeDateAverage termCount",
        "rowBorder1 rowBorder1 rowBorder1",
        "termVolume termAverage stdDevCount",
        "rowBorder2 rowBorder2 rowBorder2",
        "stdDevVolume stdDevAverage reviewedBy",
        "rowBorder3 rowBorder3 rowBorder3",
        "report report report",
      ]}
      className={className}
      data-testid={testId}
    >
      {cells.tradeDateVolume} {cells.tradeDateAverage} {cells.termCount}
      <Grid.Cell area="rowBorder1">
        <CellBorder />
      </Grid.Cell>
      {cells.termVolume} {cells.termAverage} {cells.stdDevCount}
      <Grid.Cell area="rowBorder2">
        <CellBorder />
      </Grid.Cell>
      {cells.stdDevVolume} {cells.stdDevAverage} {cells.reviewedBy}
      <Grid.Cell area="rowBorder3">
        <CellBorder />
      </Grid.Cell>
      {cells.report}
    </GridMobileViewContainer>
  ) : (
    <GridContainer
      data-testid={testId}
      className={className}
      areas={[
        "tradeDateVolume tradeDateAverage termCount termVolume termAverage stdDevCount",
        "rowBorder1 rowBorder1 rowBorder1 rowBorder1 rowBorder1 rowBorder1",
        "report report stdDevVolume stdDevAverage reviewedBy detailsButton",
      ]}
    >
      {cells.tradeDateVolume} {cells.tradeDateAverage} {cells.termCount}
      {cells.termVolume} {cells.termAverage} {cells.stdDevCount}
      <Grid.Cell area="rowBorder1">
        <CellBorder />
      </Grid.Cell>
      {cells.report} {cells.stdDevVolume} {cells.stdDevAverage}
      {cells.reviewedBy}
      {onClick && (
        <Grid.Cell area="detailsButton">
          <Flexbox flexDirection="column" justifyContent="center">
            <Button
              data-testid={DETAILS_BUTTON_TEST_ID}
              icon={<ChevronRight />}
              size="small"
              variant="outlined"
              iconPosition="right"
              onClick={onClick}
            >
              {DETAILS_BUTTON_TEXT}
            </Button>
          </Flexbox>
        </Grid.Cell>
      )}
    </GridContainer>
  );
};

export default LandingEndurTraderExpandedRow;
