import { Flexbox } from "@sede-x/shell-ds-react-framework";
import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import BackButton from "../../../../components/BackButton";
import ResolutionCellContainer from "../../../../components/TableAddons/ResolutionCellContainer";
import { anomalyRowDataAtom } from "../../../Landing/Landing.atoms";
import AnomalyResolution from "../AnomalyResolution";
import { StyledModal } from "../CommentModal/CommentModal.styles";
import {
  ANOMALY_HEADER_SECTION_TEST_ID,
  ANOMALY_RESOLUTION_MODAL_INTRO_TEXT,
} from "./AnomalyHeaderSection.constants";
import {
  AnomalyModalIntroContainer,
  StyledDescriptorCellContainer,
  TopHeaderContainer,
} from "./AnomalyHeaderSection.styles";

const AnomalyHeaderSection = () => {
  // #region atoms
  const [anomaly] = useAtom(anomalyRowDataAtom);
  // #endregion

  // #region Local state
  const [openModal, setOpenModal] = useState(false);
  // #endregion

  // #region Navigation
  const navigate = useNavigate();
  // #endregion

  if (!anomaly) {
    return null;
  }

  return (
    <TopHeaderContainer
      flexDirection="column"
      alignItems="flex-start"
      gap="1.625rem"
      data-testid={ANOMALY_HEADER_SECTION_TEST_ID}
    >
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <Flexbox gap="1.125rem">
        <StyledDescriptorCellContainer descriptor={anomaly.descriptor} />
        <div>
          <ResolutionCellContainer
            resolution={anomaly.resolution}
            onClick={() => {
              setOpenModal(true);
            }}
            buttonOption
          />
        </div>
      </Flexbox>
      <StyledModal
        open={openModal}
        title="Resolution"
        showFooter={false}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <AnomalyModalIntroContainer>
          <span>{ANOMALY_RESOLUTION_MODAL_INTRO_TEXT}</span>
        </AnomalyModalIntroContainer>
        <AnomalyResolution
          onDiscardClick={() => {
            setOpenModal(false);
          }}
          onSaveSuccessfully={() => {
            setOpenModal(false);
          }}
          enableModalView
        />
      </StyledModal>
    </TopHeaderContainer>
  );
};

export default AnomalyHeaderSection;
