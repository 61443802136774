import { Button, Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

export const RowContent = styled(Flexbox)`
  min-height: 3.5rem;
  align-items: center;
`;

export const MoreDataBtn = styled(Button)`
  padding: 0 0.9375rem;
`;
