import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../theme";

export const FirstReviewerContainer = styled.span`
  overflow-wrap: anywhere;
`;

export const OtherReviewersQuantityContainer = styled.span`
  font-weight: bold;
  color: ${COLORS.bondiBlue};
`;

export const OtherReviewersTooltipContainer = styled(Flexbox)`
  padding: 0.25rem 1rem;
`;
