import { ISvgIconInterface } from "../icons.types";

export const ShellLogo = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3053 92.4514H43.8938L44.7431 93.1202C46.3255 94.1751 48.1752 94.7917 50 94.7917C51.9855 94.7917 53.8657 94.2097 55.3879 93.0258L56.1061 92.4514H80.6802L83.016 74.1771L97.0972 63.9714L97.2766 63.1464C97.9309 60.1367 98.2372 56.9035 98.2372 53.4455C98.2372 26.827 76.6606 5.20834 50 5.20834C23.3816 5.20834 1.76288 26.7849 1.76288 53.4455C1.76288 56.9035 2.06918 60.1367 2.72346 63.1464L2.90398 63.9768L17.1069 74.177L19.3053 92.4514Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.6692 90.1131H21.266L19.1857 72.8207L4.88376 62.5493C4.23367 59.5589 3.97363 56.4385 3.97363 53.318C3.97363 27.8345 24.6465 7.29166 50 7.29166C75.3535 7.29166 96.0263 27.9645 96.0263 53.318C96.0263 56.4385 95.7663 59.5589 95.1162 62.5493L80.9442 72.8207L78.7339 90.1131H55.3307L54.0305 91.1533C52.8604 92.0634 51.4302 92.4534 50 92.4534C48.5698 92.4534 47.1396 91.9334 45.9694 91.1533L44.6692 90.1131Z"
      fill="#FBCF09"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.266 90.1131H44.6692L45.9694 91.1533C47.1396 91.9334 48.5698 92.4534 50 92.4534C51.4302 92.4534 52.8604 92.0634 54.0305 91.1533L55.3307 90.1131H78.7339L80.9442 72.8207L95.1162 62.5493C95.7663 59.5589 96.0263 56.4385 96.0263 53.318C96.0263 27.9645 75.3535 7.29166 50 7.29166C24.6465 7.29166 3.97363 27.8345 3.97363 53.318C3.97363 56.4385 4.23367 59.5589 4.88376 62.5493L19.1857 72.8207L21.266 90.1131ZM28.4172 82.052H45.4496L47.7899 83.8723C48.44 84.2623 49.2201 84.5224 50.0002 84.5224C50.7803 84.5224 51.5604 84.3923 52.2105 83.8723L54.8108 82.052H71.7132L73.4034 68.2701L87.4453 58.1287C87.8354 56.4385 88.0954 54.8783 88.0954 53.1881C88.0954 50.4577 87.5753 47.7273 86.4052 45.257L61.5718 70.3504L85.6251 40.5763C84.7149 36.6758 82.6346 33.1653 79.6442 30.565L58.0613 67.8801L77.3039 27.0545C74.8336 23.804 71.3231 21.3337 67.4226 20.1635L54.1607 66.5799L63.7821 18.3433C60.7917 16.7831 57.5412 15.8729 54.1607 15.8729C53.5107 15.8729 52.7305 15.8729 52.0805 16.003L50.1302 66.0598L48.1799 16.003C47.5298 15.8729 46.7497 15.8729 46.0996 15.8729C42.7192 15.8729 39.4687 16.653 36.4783 18.3433L46.0996 66.5799L32.8378 20.2935C28.9373 21.4637 25.4268 23.934 22.9565 27.1845L42.0691 67.8801L20.4861 30.565C17.4957 33.2953 15.4154 36.6758 14.5053 40.5763L38.5586 70.3504L13.7252 45.257C12.555 47.7273 12.035 50.4577 12.035 53.1881C12.035 54.8783 12.295 56.5685 12.6851 58.1287L26.727 68.2701L28.4172 82.052Z"
      fill="#DD1D21"
    />
  </svg>
);
