import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import env from "../../common/env";
import { appRoutes } from "../../config/routes";
import { getToken, getUserInformation, isUserAuthorized } from "..";
import { HasNoAccessAtom } from "../Authentication.atoms";
import {
  selectIsAuthenticated,
  setIsAuthenticated,
  setUserInformation,
} from "../Authentication.slice";

/**
 * This hooks protects the app from unauthorized access.
 */
function useAuthCallbackRedirect({ route }: { route: string }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHasNoAccess] = useAtom(HasNoAccessAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();

  const effectRan = useRef(false);
  const query = useRef(new URLSearchParams(window.location.search));

  useEffect(() => {
    if (isAuthenticated) {
      navigate(route);
    } else {
      if (location.pathname !== appRoutes.login) {
        navigate(appRoutes.login);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    async function token() {
      const isAuthenticatedResponse = await getToken(query.current);

      if (!isAuthenticatedResponse) {
        navigate(appRoutes.login);
        return;
      }

      dispatch(setIsAuthenticated(isAuthenticatedResponse));

      const isUserAuth = await isUserAuthorized();
      if (!isUserAuth) {
        navigate(appRoutes.noAccess);
        return;
      }

      const simaasUserInfoResponse = await getUserInformation();
      if (simaasUserInfoResponse) {
        setHasNoAccess(false);
        dispatch(
          setUserInformation({
            firstName: simaasUserInfoResponse?.given_name,
            lastName: simaasUserInfoResponse.last_name,
            email: simaasUserInfoResponse.email,
            id: simaasUserInfoResponse.uid,
          })
        );
        navigate(route);
      }
    }

    if (
      (effectRan.current || env.NODE_ENV !== "development") &&
      location.pathname === appRoutes.callback
    ) {
      token();
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  return null;
}

export default useAuthCallbackRedirect;
