import { Grid } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../../../theme";

export const StyledHeader = styled.span`
  font-size: 0.75rem;
  color: ${COLORS.boulder};
  font-weight: 600;
`;

export const ReportContainer = styled.span`
  font-size: 0.875rem;
  color: ${COLORS.trout};
  font-weight: 600;
`;

export const GridContainer = styled(Grid)`
  grid-auto-rows: auto;
  grid-gap: 1.5rem;
`;

export const GridMobileViewContainer = styled(Grid)`
  margin-top: 1rem;
  grid-auto-rows: auto;
  grid-gap: 0;
`;

export const CellBorder = styled.div`
  border-top: 1px solid rgba(179, 183, 188, 0.3);
`;

export const CellHeader = styled(Grid.Cell)`
  padding: 1.875rem 0 1.875rem 2.5rem;
`;
