import { useAtom } from "jotai";
import { Outlet } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import { HasNoAccessAtom } from "../../Authentication/Authentication.atoms";
import { selectUserInformation } from "../../Authentication/Authentication.slice";
import { abbrevName } from "../../common/utils";
import Navbar from "../Navbar/index";
import { INavbarProps } from "../Navbar/Navbar.types";
import NoAccessNavbar from "../NoAccessNavBar/index";

const Layout = () => {
  const [hasNoAccess] = useAtom(HasNoAccessAtom);
  const userInformation = useAppSelector(selectUserInformation);
  const fullName = `${userInformation.firstName ?? ""} ${
    userInformation.lastName
  }`;
  const navbarProps: INavbarProps = {
    fullName: `${userInformation.firstName} ${userInformation.lastName}`,
    email: userInformation.email,
    nameAbbreviation: abbrevName(fullName),
    hideMenu: true,
  };

  return (
    <div>
      {hasNoAccess ? <NoAccessNavbar /> : <Navbar {...navbarProps} />}
      <Outlet />
    </div>
  );
};

export default Layout;
