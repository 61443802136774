import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useLazyGetAnomalyDealsQuery } from "../../../../app/services/telescope.anomalies.slice";
import { useLazyGetAnomalyDealExportQuery } from "../../../../app/services/telescope.export.slice";
import { useTabletMode } from "../../../../common/hooks";
import AnomalyExportButton from "../../../../components/AnomalyExportButton";
import { StyledModal } from "../../../../components/Common.styles";
import { Loading } from "../../../../components/Loading";
import {
  TopHeader,
  TopHeaderContainer,
} from "../../../../components/TopHeader/TopHeader.styles";
import { AnomalyExportButtonModalContainer } from "../../../Landing/components/AnomalyExportModal/AnomalyExportModal.styles";
import { anomalyRowDataAtom } from "../../../Landing/Landing.atoms";
import {
  EXPORT_ERROR_MESSAGE,
  EXPORT_SUCCESS_MESSAGE,
} from "../../../Landing/Landing.constants";
import {
  ANOMALIES_COLUMNS,
  ROW_DATA_HEADER_TEXT,
  TABLET_MODE_SCROLL,
} from "./AnomalyRawDataSection.constants";
import {
  AnomalyRawDataSectionContainer,
  RawDataTable,
} from "./AnomalyRawDataSection.styles";

const AnomalyRawDataSection = () => {
  // #region Atoms
  const [anomaly] = useAtom(anomalyRowDataAtom);
  // #endregion

  // #region isTabletMode
  const isTabletMode = useTabletMode();
  // #endregion

  // #region local state
  const [openModal, setOpenModal] = useState(false);
  // #endregion

  // #region Queries
  const [triggerAnomalyDeals, { data: anomalyDealsData, isFetching }] =
    useLazyGetAnomalyDealsQuery();
  const [
    tiggerAnomalyDealExport,
    {
      isError: exportError,
      isSuccess: exportSuccess,
      isLoading: exportLoading,
      isFetching: exportFetching,
    },
  ] = useLazyGetAnomalyDealExportQuery();
  // #endregion

  // #region useEffect
  useEffect(() => {
    if (anomaly?.id) {
      triggerAnomalyDeals({ anomalyId: anomaly.id });
    }
  }, [anomaly]);

  useEffect(() => {
    setOpenModal(false);
    if (exportSuccess) {
      toast.success(EXPORT_SUCCESS_MESSAGE);
      return;
    }

    if (exportError) {
      toast.error(EXPORT_ERROR_MESSAGE);
    }
  }, [exportSuccess, exportError]);
  // #endregion

  // #region handlers
  const handleExportClick = () => {
    setOpenModal(true);
    if (anomaly?.id) {
      tiggerAnomalyDealExport({ anomalyId: anomaly.id });
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <AnomalyRawDataSectionContainer flexDirection="column">
      <TopHeader>
        <TopHeaderContainer>
          <div>
            {ROW_DATA_HEADER_TEXT} ({anomalyDealsData?.length})
          </div>
          <AnomalyExportButton onExportClick={handleExportClick} />
        </TopHeaderContainer>
      </TopHeader>
      <RawDataTable
        data={anomalyDealsData?.length ? anomalyDealsData : []}
        columns={ANOMALIES_COLUMNS}
        rowKey={({ uniqueDealKey }) => `${uniqueDealKey}`}
        scroll={{ x: isTabletMode ? TABLET_MODE_SCROLL : undefined }}
      ></RawDataTable>
      <Loading isLoading={isFetching} />
      <StyledModal
        open={openModal}
        title={"Exporting trades involved"}
        onClose={() => {
          handleClose();
        }}
        showFooter={false}
      >
        <AnomalyExportButtonModalContainer>
          {(exportLoading || exportFetching) && (
            <Loading removeMargin size="large" isLoading={exportLoading} />
          )}
        </AnomalyExportButtonModalContainer>
      </StyledModal>
    </AnomalyRawDataSectionContainer>
  );
};

export default AnomalyRawDataSection;
