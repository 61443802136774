import { AvatarWithText, Flexbox } from "@sede-x/shell-ds-react-framework";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { abbrevName } from "../../../../common/utils";
import {
  CommentContainer,
  OldCommentContainer,
} from "../AnomalyComments/AnomalyComments.styles";
import { DATE_SECTION_TEST_ID } from "./CommentItem.constants";
import {
  CommentTextSection,
  StyledFlexbox,
  TimeInfoContainer,
} from "./CommentItem.styles";
import { ICommentItemProps } from "./CommentItem.types";

dayjs.extend(utc);

const CommentItem = ({
  anomalyComment,
  className,
  testId,
  latestComment = false,
}: ICommentItemProps) => {
  const commentContent = (
    <>
      <Flexbox justifyContent="space-between">
        <AvatarWithText
          title={anomalyComment.investigator.name}
          abbreviation={abbrevName(anomalyComment.investigator.name)}
          description={anomalyComment.investigator.email}
        />
        <StyledFlexbox>{anomalyComment.status}</StyledFlexbox>
      </Flexbox>
      <TimeInfoContainer data-testid={DATE_SECTION_TEST_ID}>
        <div>
          <span className="bolder">Date:</span>{" "}
          {dayjs.utc(anomalyComment.timeStamp).format("MM/DD/YYYY")}
        </div>
        <div>
          <span className="bolder">Time:</span>{" "}
          {dayjs.utc(anomalyComment.timeStamp).format("HH:mm")}
        </div>
      </TimeInfoContainer>
      <CommentTextSection flexDirection="column" gap="0.625rem">
        <span className="bolder">Comment:</span>
        <p>{anomalyComment.comment}</p>
      </CommentTextSection>
    </>
  );

  if (latestComment) {
    return (
      <CommentContainer
        flexDirection="column"
        data-testid={testId}
        className={className}
        gap="1.125rem"
      >
        {commentContent}
      </CommentContainer>
    );
  }

  return (
    <OldCommentContainer
      flexDirection="column"
      data-testid={testId}
      className={className}
      gap="1.125rem"
    >
      {commentContent}
    </OldCommentContainer>
  );
};

export default CommentItem;
