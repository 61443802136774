import { cleanNullishValues, toStringParams } from "../../common/utils";
import HttpStatusCode from "../../utils/http";
import {
  TGetAnomalyDealsExportArgs,
  TGetGetAnomalyDealsExportArgs,
} from "./services.types";
import { telescopeBaseAPIService } from "./telescope.base.service";

export const anomaliesExportSlice = telescopeBaseAPIService.injectEndpoints({
  endpoints: (builder) => ({
    getAnomalyDealExport: builder.query<void, TGetAnomalyDealsExportArgs>({
      query: ({ anomalyId }) => ({
        method: "GET",
        url: `anomaly/${anomalyId}/deals/export`,
        responseHandler: async (response) => {
          if (response.status !== HttpStatusCode.OK) {
            throw new Error("Error exporting deal");
          }

          return window.location.assign(
            window.URL.createObjectURL(await response.blob())
          );
        },
        cache: "no-cache",
      }),
    }),
    getAnomalyDealsExport: builder.query<void, TGetGetAnomalyDealsExportArgs>({
      query: ({ queryParams }) => ({
        method: "GET",
        url: `anomalies/export?${toStringParams(
          cleanNullishValues(queryParams)
        )}`,
        responseHandler: async (response) => {
          if (response.status !== HttpStatusCode.OK) {
            throw new Error("Error exporting deals");
          }
          return window.location.assign(
            window.URL.createObjectURL(await response.blob())
          );
        },
        cache: "no-cache",
      }),
    }),
  }),
});

export const {
  useLazyGetAnomalyDealsExportQuery,
  useLazyGetAnomalyDealExportQuery,
} = anomaliesExportSlice;
