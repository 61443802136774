import { BASE_API_URL } from "../app/services/services.constants";
import env from "../common/env";
import { appRoutes } from "../config/routes";
import { IUserInformation } from "./Authentication.types";

const { protocol, host } = window.location;
const appURI = `${protocol}//${host}`;
const ssoHost = env.REACT_APP_SSO_HOST;

export const LAST_PAGE_VISITED_SS_KEY = "lastPageVisited";
export const SCOPE = "openid profile";
export const REDIRECT_URI = `${appURI}${appRoutes.callback}`;
export const TOKEN_URL = `${ssoHost}/as/token.oauth2`;
export const AUTH_URL = `${ssoHost}/as/authorization.oauth2`;
export const USER_INFO_URL = `${ssoHost}/idp/userinfo.openid`;
export const LOGOUT_URL = `${ssoHost}/as/revoke_token.oauth2`;
export const CONTENT_TYPE_FORM = "application/x-www-form-urlencoded";
export const IS_USER_AUTHORIZED = `${BASE_API_URL}/auth`;

export const EMPTY_USER_INFO: IUserInformation = {
  firstName: "",
  lastName: "",
  email: "",
  id: "",
};
