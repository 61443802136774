import styled from "styled-components";

export const TopHeader = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
`;

export const TopHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
