import { Tooltip } from "@sede-x/shell-ds-react-framework";

import {
  FirstReviewerContainer,
  OtherReviewersQuantityContainer,
  OtherReviewersTooltipContainer,
} from "./ReviewedBy.styles";
import { IReviewedByProps } from "./ReviewedBy.types";

const ReviewedBy = ({ reviewersEmails }: IReviewedByProps) => {
  if (!reviewersEmails.length) {
    return <span>--</span>;
  }

  const otherReviewersQuantity = reviewersEmails.length - 1;
  return (
    <div>
      <FirstReviewerContainer>{reviewersEmails[0]}</FirstReviewerContainer>
      {!!otherReviewersQuantity && (
        <>
          {", "}
          <Tooltip
            overlay={
              <OtherReviewersTooltipContainer
                flexDirection="column"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {reviewersEmails.splice(1).map((email) => (
                  <span key={`${email}`}>{email}</span>
                ))}
              </OtherReviewersTooltipContainer>
            }
            placement="top"
          >
            <OtherReviewersQuantityContainer>
              {`+${otherReviewersQuantity}`}
            </OtherReviewersQuantityContainer>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default ReviewedBy;
