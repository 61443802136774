import styled from "styled-components";

import { breakpoint } from "../../../../theme/breakpoints";
import { BaseStyledSelect } from "../../Landing.styles";

export const StyledSelect = styled(BaseStyledSelect)`
  @media ${breakpoint.mediaQuery.sm} {
    max-width: 10.3125rem;
    min-width: 7.5625rem;
  }
`;
