import { INavbarMenuItem } from "../../components/Navbar/Navbar.types";
import { TELESCOPE_API_ROUTES } from "./services.constants";
import {
  IAnomalyReportOption,
  IResolution,
  ITelescopeAPIResponse,
  TAnomaliesFiltersOptions,
  TResolutionsMapping,
} from "./services.types";
import { telescopeBaseAPIService } from "./telescope.base.service";

export const anomalyDropdowns = telescopeBaseAPIService.injectEndpoints({
  endpoints: (builder) => ({
    getSupportedResolutions: builder.query<INavbarMenuItem<string>[], void>({
      query: () => ({
        method: "GET",
        url: TELESCOPE_API_ROUTES.anomaly.resolutions,
      }),
      transformResponse: (response: ITelescopeAPIResponse<IResolution[]>) => {
        if (!response.data) {
          return [];
        }

        return [
          ...response.data.map((resolution) => ({
            label: resolution.displayName,
            value: resolution.resolutionName,
          })),
        ];
      },
    }),
    getResolutionsMapping: builder.query<TResolutionsMapping | null, void>({
      query: () => ({
        method: "GET",
        url: TELESCOPE_API_ROUTES.anomaly.resolutionsMapping,
      }),
      transformResponse: (
        response: ITelescopeAPIResponse<TResolutionsMapping>
      ) => response.data,
    }),
    getAnomalyReportOptions: builder.query<IAnomalyReportOption[] | null, void>(
      {
        query: () => ({
          method: "GET",
          url: TELESCOPE_API_ROUTES.anomaly.reports,
        }),
        transformResponse: (
          response: ITelescopeAPIResponse<IAnomalyReportOption[]>
        ) => response.data,
      }
    ),
    getAdvancedFiltersOptions: builder.query<
      TAnomaliesFiltersOptions | null,
      void
    >({
      query: () => ({
        method: "GET",
        url: TELESCOPE_API_ROUTES.anomaly.anomaliesParamsValues,
      }),
      transformResponse: (
        response: ITelescopeAPIResponse<TAnomaliesFiltersOptions>
      ) => response.data,
    }),
  }),
});

export const {
  useLazyGetSupportedResolutionsQuery,
  useLazyGetResolutionsMappingQuery,
  useLazyGetAnomalyReportOptionsQuery,
  useLazyGetAdvancedFiltersOptionsQuery,
  useGetAnomalyReportOptionsQuery,
  useGetSupportedResolutionsQuery,
} = anomalyDropdowns;
