import env from "../../common/env";

export const NO_ACCESS_HEADER_TEXT = "No Access";
export const NO_ACCESS_MESSAGE =
  "It seems you don’t have access to the Deal Anomaly Tool, if you think this is a mistake please contact the Compliance Team to get access.";
export const NO_ACCESS_MESSAGE_CONTACT = "Compliance team contact:";
export const NO_ACCESS_CONTACT_EMAIL = env.REACT_APP_SUPPORT_EMAIL;

export const NO_ACCESS_ICON_TEST_ID = "no-access-icon";
export const SHELL_ENERGY_LOGO_TEST_ID = "shell-energy-logo";
