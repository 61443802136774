import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { breakpoint } from "../theme/breakpoints";
import { ISize } from "./common.types";

// hook to search and return query params value
export const useQuery = (queryParam: string) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const useWindowSize = ({
  innerHeight,
  innerWidth,
}: {
  innerHeight: number;
  innerWidth: number;
}) => {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: innerWidth || undefined,
    height: innerHeight || undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

export const useTabletMode = () => {
  const { innerWidth, innerHeight } = window;
  const { width = 0 } = useWindowSize({ innerHeight, innerWidth });

  return width < breakpoint.devices.tablet;
};
