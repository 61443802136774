import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

export const AnomalyExportButtonContainer = styled(Flexbox)`
  justify-content: flex-end;
  gap: 0.625rem;
  margin-top: 1.5rem;
`;

export const AnomalyExportButtonModalContainer = styled.div``;
