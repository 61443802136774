import { DefaultRecordType } from "../../../../common/types";
import CardView from "../../../../components/CardView";
import { CARD_VIEW_TEST_ID } from "./ResponsiveDataView.constants";
import { ResponsiveDataViewContainer } from "./ResponsiveDataView.styles";
import { IResponsiveDataViewProps } from "./ResponsiveDataView.types";

const ResponsiveDataView = <T extends DefaultRecordType>({
  data,
  columns,
  testId,
  rowKey,
  expandable,
  variant,
}: IResponsiveDataViewProps<T>) => {
  if (!data?.length || !columns?.length) {
    return null;
  }

  const rowCards = data.map((row, index) => {
    const uniqueKey = rowKey
      ? typeof rowKey === "function"
        ? rowKey(row)
        : row[rowKey]
      : index;
    return (
      <CardView
        testId={CARD_VIEW_TEST_ID}
        key={uniqueKey}
        columns={columns}
        record={row}
        expandable={expandable}
        variant={variant}
      />
    );
  });

  return (
    <ResponsiveDataViewContainer
      data-testid={testId}
      flexDirection="column"
      gap="1.125rem"
    >
      {rowCards}
    </ResponsiveDataViewContainer>
  );
};

export default ResponsiveDataView;
