/**
 * The deployment process uses window.ENV while local builds access
 * environment variables via process.env
 *
 * This module abstracts that difference out.
 */

const windowENV = (window as any).ENV;
const env = windowENV ? windowENV : process.env;

const isLocalEnvironment = env.REACT_APP_ENVIRONMENT === "local";
const isDevEnvironment = env.REACT_APP_ENVIRONMENT === "development";
const isStagingEnvironment = env.REACT_APP_ENVIRONMENT === "staging";

export default env;
export { isDevEnvironment, isLocalEnvironment, isStagingEnvironment };
