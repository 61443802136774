import dayjs from "dayjs";
import { Control } from "react-hook-form";

import {
  TAnomaliesFiltersOptions,
  TAnomaliesInvestigatorFilterOptions,
} from "../../../../app/services/services.types";
import { TAnomaliesFilters, TAnomalyFilter } from "../../Landing.types";

export type TAnomaliesAdvancedFilterParams = {
  startDate?: string;
  endDate?: string;
  trader?: string[];
  location?: string[];
  counterparty?: string[];
  broker?: string[];
  tradeGroup?: string[];
  dealType?: string[];
  investigator?: { value: string; label: string }[];
};

export type TAnomaliesAdvancedFilterFormParams = {
  startDate?: dayjs.Dayjs | string;
  endDate?: dayjs.Dayjs | string;
  trader?: string[];
  location?: string[];
  counterparty?: string[];
  broker?: string[];
  tradeGroup?: string[];
  dealType?: string[];
  investigator?: string[];
};

export const anomaliesAdvancedFiltersToFormParams = (
  obj: TAnomaliesAdvancedFilterParams
): TAnomaliesAdvancedFilterFormParams => {
  const startDate =
    obj.startDate && obj.startDate !== "" ? dayjs(obj.startDate) : undefined;
  const endDate =
    obj.endDate && obj.endDate !== "" ? dayjs(obj.endDate) : undefined;
  const investigator = obj.investigator?.map(({ value }) => value);
  return { ...obj, startDate, endDate, investigator };
};

export const formParamsToAnomaliesAdvancedFilters = (
  obj: TAnomaliesAdvancedFilterFormParams,
  filtersOptions: TAnomaliesFiltersOptions | null | undefined
): TAnomaliesAdvancedFilterParams => {
  const startDate =
    obj.startDate && typeof obj.startDate !== "string"
      ? new Date(obj.startDate.toDate().toDateString()).toISOString()
      : "";

  const endDate =
    obj.endDate && typeof obj.endDate !== "string"
      ? new Date(obj.endDate.toDate().toDateString()).toISOString()
      : "";

  const investigator =
    obj.investigator?.map((id) => ({
      value: id,
      label:
        filtersOptions?.investigator?.find((opt) => opt.id === id)?.name || id,
    })) || [];

  return { ...obj, startDate, endDate, investigator };
};

const validKeys = [
  "trader",
  "location",
  "counterparty",
  "broker",
  "tradeGroup",
  "dealType",
  "investigator",
  "startDate",
  "endDate",
];

export const cleanAnomaliesAdvancedFilterParams = (
  obj: TAnomaliesFilters
): TAnomaliesAdvancedFilterParams =>
  Object.entries(obj).reduce(
    (acc, [key, value]) =>
      validKeys.indexOf(key) !== -1 ? { ...acc, [key]: value } : acc,
    {}
  ) as TAnomaliesAdvancedFilterParams;

export interface IAnomaliesAdvancedFilterProps {
  currentAdvancedFiltersValues: TAnomaliesAdvancedFilterParams;
  onFiltersApplied: (newValues: TAnomaliesAdvancedFilterParams) => void;
}

export interface IAnomaliesAdvancedFilterDatePickerControllerProps {
  control: Control<TAnomaliesAdvancedFilterFormParams, object>;
  id: "startDate" | "endDate";
  dataTestId: string;
  label: string;
  placeHolder: string;
  disabled?: boolean;
  invalid?: boolean;
}

export type AnomaliesAdvancedFilterSelectControllerId =
  | "trader"
  | "location"
  | "counterparty"
  | "broker"
  | "tradeGroup"
  | "dealType"
  | "investigator";

export interface IAnomaliesAdvancedFilterSelectControllerProps {
  control: Control<TAnomaliesAdvancedFilterFormParams, object>;
  isLoading: boolean;
  selectOptions: string[] | TAnomaliesInvestigatorFilterOptions[];
  id: AnomaliesAdvancedFilterSelectControllerId;
  dataTestId: string;
  optionsDataTestId: string;
  label: string;
  placeHolder: string;
  optionValueTransformer?: (
    option: string | TAnomaliesInvestigatorFilterOptions
  ) => TAnomalyFilter;
}
