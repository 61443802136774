import { DownArrow } from "../../icons/DownArrow";
import { Sort } from "../../icons/Sort";
import { UpArrow } from "../../icons/UpArrow";
import { SortIconWrapper } from "./SortIcon.styles";
import { ISortIcon } from "./SortIcon.types";

export const SortIcon: <T>(props: ISortIcon<T>) => JSX.Element = ({
  orderedBy,
  order,
  field,
}) => {
  const getSortIcon = () => {
    if (orderedBy === field || !orderedBy) {
      if (order === "ascending") {
        return <UpArrow data-testid={`sort-up-arrow-${field}`} />;
      } else if (order === "descending") {
        return <DownArrow data-testid={`sort-down-arrow-${field}`} />;
      } else {
        return <Sort data-testid={`sort-${field}`} />;
      }
    } else {
      return <Sort data-testid={`sort-outside-${field}`} />;
    }
  };

  return (
    // if this field is not being sorted by, show the sort icon
    // if it is, show ascending or descending based on val
    <SortIconWrapper>{getSortIcon()}</SortIconWrapper>
  );
};
