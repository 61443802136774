import { ChevronDown } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";

import { useGetSupportedResolutionsQuery } from "../../../app/services/telescope.dropdowns.slice";
import { RESOLUTION_BUTTON_TEST_ID } from "./ResolutionCellContainer.constants";
import { StyledButton, StyledFlexbox } from "./ResolutionCellContainer.styles";
import { IResolutionCellContainerProps } from "./ResolutionCellContainer.types";

const ResolutionCellContainer = ({
  resolution,
  buttonOption = false,
  onClick,
}: IResolutionCellContainerProps) => {
  const { data: resolutionDictionary, isError } =
    useGetSupportedResolutionsQuery();

  if (isError) {
    return <div>Bad row information Error</div>;
  }

  const resolutionName = resolutionDictionary?.find(
    (res) => res.value === resolution
  )?.label;

  if (buttonOption) {
    return (
      <StyledButton
        variant="transparent"
        icon={<ChevronDown width="20px" height="20px" />}
        iconPosition="right"
        onClick={onClick}
        data-testid={RESOLUTION_BUTTON_TEST_ID}
      >
        {resolutionName}
      </StyledButton>
    );
  }

  return <StyledFlexbox>{resolutionName}</StyledFlexbox>;
};

export default ResolutionCellContainer;
