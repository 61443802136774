import AnomalyExportButton from "../../../../components/AnomalyExportButton";
import AnomalyExportModal from "../AnomalyExportModal";
import AnomalyExportSectionMessage from "../AnomalyExportSectionMessage";
import { FilterBarContainer } from "./AnomalyExportSection.styles";
import { IAnomalyExportSectionProps } from "./AnomalyExportSection.types";

const AnomalyExportSection = ({
  children,
  areFiltersActive,
  onConfirmClick,
  isLoading,
  openModal,
  onOpenModalChange,
  onExportClick,
}: IAnomalyExportSectionProps) => (
  <>
    <AnomalyExportButton onExportClick={onExportClick} />
    <AnomalyExportModal
      openModal={openModal}
      isLoading={isLoading}
      onConfirmClick={onConfirmClick}
      onOpenModalChange={onOpenModalChange}
    >
      <AnomalyExportSectionMessage areFiltersActive={areFiltersActive} />
      {areFiltersActive && <FilterBarContainer>{children}</FilterBarContainer>}
    </AnomalyExportModal>
  </>
);

export default AnomalyExportSection;
