import { Card, Flexbox } from "@sede-x/shell-ds-react-framework";
import { Property } from "csstype";
import styled from "styled-components";

export const StyledCard = styled(Card)<{
  backgroundColor?: Property.BackgroundColor;
}>`
  width: 100%;
  border-radius: 10px;
  padding: 1.75rem 1.625rem;
  font-family: "SF Pro Text", sans-serif;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const TitleContainer = styled.span`
  font-weight: 600;
  min-width: 15.75rem;
  padding-left: 1rem;
`;

export const RowContent = styled(Flexbox)`
  min-height: 3.5rem;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
`;
