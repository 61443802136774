import { ColumnType } from "@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types";
import { TdHTMLAttributes } from "react";

import {
  TLegalIdentity,
  TTraderLocation,
} from "../../../../app/services/services.types";
import { DefaultRecordType } from "../../../../common/types";

export const ROW_DATA_HEADER_TEXT = "Trades Involved";

export const ANOMALIES_COLUMNS: ColumnType<DefaultRecordType>[] = [
  {
    title: "Unique Deal Key",
    dataIndex: "uniqueDealKey",
    key: "uniqueDealKey",
  },
  {
    title: "Trader/Location",
    dataIndex: "traderLocation",
    key: "traderLocation",
    render: ({ trader, location }: TTraderLocation) => (
      <div>
        <div>Trader: {trader}</div>
        <div>Location: {location ? location : "--"}</div>
      </div>
    ),
    width: "17%",
  },
  {
    title: "Volume",
    dataIndex: "volume",
    key: "volume",
    className: "shell-table--text-align-right shell-table--max-width",
    onHeaderCell: () => {
      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
      props.className = "shell-table--text-align-right shell-table--max-width";
      return props;
    },
  },
  {
    title: "Company name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Broker",
    dataIndex: "broker",
    key: "broker",
    render: (broker: string) => (broker ? broker : "--"),
  },
  {
    title: "Legal identity",
    dataIndex: "legalIdentity",
    key: "legalIdentity",
    render: ({ external, internal }: TLegalIdentity) => (
      <div>
        <div>External: {external}</div>
        <div>Internal: {internal}</div>
      </div>
    ),
  },
  {
    title: "Direction",
    dataIndex: "direction",
    key: "direction",
  },
];

export const TABLET_MODE_SCROLL = 1440;
