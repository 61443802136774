import { SHELL_LOGO_TEST_ID } from "../../features/Login/Login.constants";
import { ShellLogo } from "../icons/ShellLogo";
import { NO_ACCESS_NAVBAR_TEST_ID } from "./NoAccessNavBar.constants";
import { NoAccessStyledNavbar } from "./NoAccessNavBar.styles";

const NoAccessNavbar = () => (
  <NoAccessStyledNavbar
    leftArea={<ShellLogo width="36" height="34" testId={SHELL_LOGO_TEST_ID} />}
    data-testid={NO_ACCESS_NAVBAR_TEST_ID}
  ></NoAccessStyledNavbar>
);

export default NoAccessNavbar;
