import { Flexbox, Text } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../../../theme";
import { breakpoint } from "../../../../theme/breakpoints";
import { IAnomalyTrendContainer, ITrendDataProps } from "./TrandData.types";

export const StyledCard = styled(Flexbox)`
  border-radius: 0.625rem;
  box-shadow: none;
  background: ${COLORS.white};
  width: 100%;
  padding: 1.25rem;
`;

export const StyledHeading = styled(Text)`
  font-family: "Shell Font";
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
`;

export const StyledTag = styled.div`
  border: 1px solid ${COLORS.midBlue};
  color: ${COLORS.midBlue};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0em;
  height: 2rem;
  padding: 0.5rem;
  border-radius: 2px;
`;

export const StyledAnomalyTrendRow = styled.div`
  margin-top: 0.75rem;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  grid-column-gap: 0.625rem;
`;

export const StyledAnomalyTrendCountSmallContainer = styled(Flexbox)`
  gap: 0.625rem;
`;

export const StyledAnomalyTrendCountContainer = styled(
  Flexbox
)<IAnomalyTrendContainer>`
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 10px;
  justify-content: center;
  padding: ${(props) =>
    props.size === "small" ? "0.625rem 0.8125rem" : "1.25rem"};
  max-height: ${(props) =>
    props.size === "small" ? "5.625rem" : "11.5625rem"};
  flex-grow: 1;
  align-items: ${(props) => (props.size === "small" ? "center" : "flex-start")};
`;

export const StyledAnomalyTrendCount = styled(Flexbox)<IAnomalyTrendContainer>`
  font: 700 1.75rem/105.4% "Avenir Next", "Avenir", "Arial", sans-serif;
  color: ${(props) => props.color};

  @media ${breakpoint.mediaQuery.md} {
    font-size: ${(props) => (props.size === "large" ? "6.25rem" : "3.75rem")};
  }
`;

export const StyledAnomalyTrendCaptionContainer = styled(Flexbox)<
  Partial<Pick<ITrendDataProps, "lowSeverity" | "size">>
>`
  flex-direction: row;
  align-items: flex-start;
  padding-left: 0.625rem;
  font-size: 0.875rem;

  ${({ size = "large", lowSeverity = false }) =>
    size === "small"
      ? lowSeverity
        ? `
        @media (min-width: 818px) {
          height: 1.5rem;
          align-items: center;
        }
        `
        : `
          @media (min-width: 901px) {
            height: 1.5rem;
            align-items: center;
          }
  `
      : `
      @media ${breakpoint.mediaQuery.md} {
        height: 1.5rem;
        align-items: center;
        font-size: 1rem;
      }
      `}
`;

export const StyledAnomalyTrendCaption = styled(Flexbox)`
  color: inherit;
  align-items: center;
  color: ${(props: IAnomalyTrendContainer) => props.color};
  font-weight: 600;
  font-family: "SF Pro Text", sans-serif;
`;

export const StyledAnomalyTrendIconContainer = styled(Flexbox)<
  Pick<ITrendDataProps, "lowSeverity">
>`
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  height: min-content;

  ${({ lowSeverity }) =>
    lowSeverity
      ? `
      @media (min-width: 818px) {
        margin-top: 0;
      }
  `
      : `
      @media (min-width: 901px) {
        margin-top: 0;
      }
  `}
`;
