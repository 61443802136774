import { Property } from "csstype";
import styled from "styled-components";

export const StyledCornerIndicator = styled.div<{
  color: Property.Color;
}>`
  height: 0.7rem;
  width: 0.7rem;
  background-color: ${(p) => p.color};
  border-radius: 50%;
  position: absolute;
  left: 0.7rem;
`;
