/* eslint-disable max-len */

import { ISvgIconInterface } from "../icons.types";

export const DownArrow = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M7.29316 0C7.01702 -3.29297e-09 6.79316 0.223858 6.79316 0.5V12.17L1.55797 6.9348C1.36222 6.73905 1.04467 6.73962 0.849615 6.93606L0.144223 7.64645C-0.0498621 7.84191 -0.0493043 8.15753 0.14547 8.35231L7.43961 15.6464C7.63487 15.8417 7.95145 15.8417 8.14672 15.6464L15.4396 8.35355C15.6349 8.15829 15.6349 7.84171 15.4396 7.64645L14.7364 6.94324C14.5413 6.7481 14.2249 6.74796 14.0296 6.94292L8.79316 12.17L8.79316 0.499999C8.79316 0.223857 8.56931 1.52178e-08 8.29316 1.19248e-08L7.29316 0Z"
      fill="currentColor"
    />
  </svg>
);
