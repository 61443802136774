import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../theme";
import { ILoadingProps } from "./loading.types";

export const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.66);
`;

export const StyledFlexbox = styled(Flexbox)<
  Pick<ILoadingProps, "removeMargin">
>`
  margin-top: ${({ removeMargin }) => (removeMargin ? 0 : "3rem")};

  .loading--supernova-color circle[type="default"] {
    stroke: ${COLORS.supernova};
  }
`;
