export const NO_ANOMALIES_HEADER = "No anomalies.";
export const NO_ANOMALIES_MESSAGE = "Great news! There are no anomalies here.";
export const NO_ANOMALIES_ICON_TEST_ID = "no-anomalies-icon";

export const NO_ANOMALIES_YET_HEADER = "No anomalies yet.";

export const NO_RESULTS_FOUND_HEADER = "No results found";
export const NO_RESULTS_FOUND_MESSAGE =
  "Try adjusting your filters to find what you are looking for.";
export const NO_RESULTS_FOUND_ICON_TEST_ID = "not-found-anomalies-icon";
