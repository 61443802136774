/* eslint-disable camelcase */
import cryptoRandomString from "crypto-random-string";
import pkceChallenge from "pkce-challenge";

import env from "../common/env";
import statusCodes from "../utils/http";
import {
  AUTH_URL,
  CONTENT_TYPE_FORM,
  IS_USER_AUTHORIZED,
  LOGOUT_URL,
  REDIRECT_URI,
  SCOPE,
  TOKEN_URL,
  USER_INFO_URL,
} from "./Authentication.constants";
import { ISIMAASUserInformationResponse } from "./Authentication.types";

let refresh_token = "";
let access_token = "";

async function generateAuthUrl() {
  const { code_challenge, code_verifier } = pkceChallenge();
  const state = cryptoRandomString({ length: 10 });
  localStorage.setItem("codeChallenge", code_challenge);
  localStorage.setItem("codeVerifier", code_verifier);
  localStorage.setItem("state", state);
  const params = new URLSearchParams({
    client_id: env.REACT_APP_SSO_CLIENT_ID,
    response_type: "code",
    redirect_uri: REDIRECT_URI,
    scope: SCOPE,
    state,
    code_challenge,
    code_challenge_method: "S256",
  }).toString();
  return `${AUTH_URL}?${params}`;
}

async function login() {
  const authUrl = await generateAuthUrl();
  try {
    window.location.replace(authUrl);
  } catch (e) {
    console.log(e);
  }
}

async function getToken(query: URLSearchParams) {
  const code: string | null = query.get("code");
  const state: string | null = query.get("state");

  const requestOptions = {
    method: "POST",
    headers: { "content-type": CONTENT_TYPE_FORM },
    body: new URLSearchParams({
      client_id: env.REACT_APP_SSO_CLIENT_ID,
      code: code || "",
      code_verifier: localStorage.getItem("codeVerifier") || "",
      grant_type: "authorization_code",
      redirect_uri: REDIRECT_URI,
      state: state || "",
    }),
  };

  try {
    const response = await fetch(TOKEN_URL, requestOptions);
    const data = await response.json();
    if (data.refresh_token && data.access_token) {
      refresh_token = data.refresh_token;
      access_token = data.access_token;
      return true;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getUserInformation(): Promise<
  ISIMAASUserInformationResponse | undefined
> {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };

  try {
    const response = await fetch(USER_INFO_URL, requestOptions);
    return (await response.json()) as ISIMAASUserInformationResponse;
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

export async function isUserAuthorized(): Promise<boolean> {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };

  try {
    const response = await fetch(IS_USER_AUTHORIZED, requestOptions);
    return response.status === statusCodes.OK;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function refreshToken() {
  const requestOptions = {
    method: "POST",
    headers: { "content-type": CONTENT_TYPE_FORM },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token,
    }),
  };

  try {
    const response = await fetch(TOKEN_URL, requestOptions);

    // throw an error if the response is not 200
    if (response.status !== statusCodes.OK) {
      throw new Error("Refresh token failed");
    }

    const data = await response.json();
    refresh_token = data.refresh_token;
    access_token = data.access_token;
  } catch (e) {
    refresh_token = "";
    access_token = "";
    throw e;
  }
}

async function logout() {
  const requestOptions = {
    method: "POST",
    headers: { "content-type": CONTENT_TYPE_FORM },
    body: new URLSearchParams({
      client_id: env.REACT_APP_SSO_CLIENT_ID,
      token: access_token,
    }),
  };

  try {
    const resp = await fetch(LOGOUT_URL, requestOptions);
    if (resp.status !== statusCodes.OK) {
      throw new Error("Logout failed");
    }
    refresh_token = "";
    access_token = "";
  } catch (e) {
    console.log(e);
  }
}

export {
  access_token,
  generateAuthUrl,
  getToken,
  login,
  logout,
  refresh_token,
  refreshToken,
};
