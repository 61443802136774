import { Flexbox } from "@sede-x/shell-ds-react-framework";
import { useEffect } from "react";

import { useGetBiddayLabelQuery } from "../../../../app/services/telescope.admin.slice";
import { useLazyGetTrendDataQuery } from "../../../../app/services/telescope.anomalies.slice";
import HighSeverityIcon from "../../../../components/icons/HighSeverity";
import { COLORS } from "../../../../theme";
import {
  HIGH_SEVERITY,
  ONGOING_INVESTIGATIONS,
  TREND_DATA_HEADING,
} from "./TrendData.constants";
import {
  StyledAnomalyTrendRow,
  StyledCard,
  StyledHeading,
  StyledTag,
} from "./TrendData.styles";
import TrendDataSection from "./TrendDataSection";

const TrendData = () => {
  const label = useGetBiddayLabelQuery().data?.label || "";
  const [getTrendDataQuery, { data: trendData }] = useLazyGetTrendDataQuery();

  useEffect(() => {
    getTrendDataQuery();
  }, []);

  const highSeverity = (
    <TrendDataSection
      color={`${COLORS.cinnabar}`}
      count={trendData?.highSeverityCount}
      caption={HIGH_SEVERITY}
      icon={<HighSeverityIcon />}
      size="large"
      testId="high-severity-count"
    />
  );

  // const mediumSeverity = (
  //   <TrendDataSection
  //     color={`${COLORS.tahitiGold}`}
  //     count={trendData?.mediumSeverityCount}
  //     caption={MEDIUM_SEVERITY}
  //     icon={<MediumSeverityIcon />}
  //     size="small"
  //     testId="medium-severity-count"
  //   />
  // );

  // const lowSeverity = (
  //   <TrendDataSection
  //     color={`${COLORS.funGreen}`}
  //     count={trendData?.lowSeverityCount}
  //     caption={LOW_SEVERITY}
  //     icon={<LowSeverityIcon />}
  //     size="small"
  //     testId="low-severity-count"
  //     lowSeverity
  //   />
  // );

  const investigations = (
    <TrendDataSection
      color={`${COLORS.midBlue}`}
      count={trendData?.investigationCount}
      caption={ONGOING_INVESTIGATIONS}
      size="large"
      testId="investigations"
    />
  );

  if (trendData) {
    return (
      <StyledCard flexDirection="column">
        <Flexbox flexDirection="row" justifyContent="space-between">
          <StyledHeading>{TREND_DATA_HEADING}</StyledHeading>
          {label && <StyledTag data-testid="bid-week-label">{label}</StyledTag>}
        </Flexbox>
        <StyledAnomalyTrendRow>
          {highSeverity}
          {/* <StyledAnomalyTrendCountSmallContainer flexDirection="column">
            {mediumSeverity}
            {lowSeverity}
          </StyledAnomalyTrendCountSmallContainer> */}
          {investigations}
        </StyledAnomalyTrendRow>
      </StyledCard>
    );
  }

  return null;
};

export default TrendData;
