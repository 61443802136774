import { Flexbox } from "@sede-x/shell-ds-react-framework";

import { BACK_BUTTON_TEST_ID, BACK_BUTTON_TEXT } from "./BackButton.constants";
import { StyledButtonLink, StyledChevronLeft } from "./BackButton.styles";
import { IBackButtonProps } from "./BackButton.types";

const BackButton = ({ onClick }: IBackButtonProps) => (
  <StyledButtonLink onClick={onClick} data-testid={BACK_BUTTON_TEST_ID}>
    <Flexbox>
      <StyledChevronLeft />
      <span>{BACK_BUTTON_TEXT}</span>
    </Flexbox>
  </StyledButtonLink>
);

export default BackButton;
