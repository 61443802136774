import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../../theme";
import {
  HIGH_SCORE_FONT_WEIGHT,
  OTHER_SCORE_FONT_WEIGHT,
} from "./ZScoredCellContainer.constants";
import { IZScoredContentContainerProps } from "./ZScoredCellContainer.types";

export const ZScoredContentContainer = styled(
  Flexbox
)<IZScoredContentContainerProps>`
  position: relative;
  padding: 11px 10px;
  background-color: ${(props) =>
    props.score === "high"
      ? COLORS.alizarinCrimsonWithOpacity
      : props.score === "medium"
      ? COLORS.champagne
      : props.score === "low"
      ? COLORS.funGreenWithOpacitiy
      : "transparent"};

  .z-score-container__flexbox {
    width: ${(props) => (props.width ? props.width : "100%")};
    font-weight: ${(props) =>
      props.score === "high"
        ? HIGH_SCORE_FONT_WEIGHT
        : OTHER_SCORE_FONT_WEIGHT};
  }

  text-align: right;
`;
