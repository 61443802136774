import { Button, Drawer } from "@sede-x/shell-ds-react-framework";
import { FilterSquare } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { Property } from "csstype";
import styled from "styled-components";

import { COLORS } from "../../theme";
import { breakpoint } from "../../theme/breakpoints";

export const StyledDrawer = styled(Drawer)`
  .shell-drawer-mask {
    opacity: 0 !important;
  }

  .shell-drawer-content-wrapper {
    width: 100%;

    @media ${breakpoint.mediaQuery.sm} {
      width: 80%;
    }

    @media ${breakpoint.mediaQuery.md} {
      width: 60%;
    }

    @media ${breakpoint.mediaQuery.lg} {
      width: 50%;
    }
  }

  .shell-drawer-content {
    height: 100%;

    > div:nth-child(1) .shell-button {
      display: none;
    }

    > div:nth-child(2) {
      height: 100%;
      overflow: auto;
    }

    > div:nth-child(3) {
      gap: 0.75rem;

      > button:nth-child(1) {
        background-color: transparent;
        border: 1px solid ${COLORS.greyGoose};

        &:hover {
          border-color: ${COLORS.midBlue};
        }
      }
    }
  }
`;

export const StyledButton = styled(Button)<{
  padding?: Property.Padding;
}>`
  padding: ${({ padding }) => padding};
  margin-top: 1px;
  @media ${breakpoint.mediaQuery.lg} {
    padding: 0 15px;
  }
`;

export const StyledFilterSquare = styled(FilterSquare)`
  margin-top: 4px !important;
`;

export const StyledClearButton = styled(Button)`
  align-self: end;
  margin-top: -8px;
  margin-bottom: -16px;
`;
