export const RESOLUTION_HEADER = "Resolution";
export const RESOLUTION_LABEL = "Resolution";
export const RESOLUTION_COMMENT = "Comment (optional)";
export const DISCARD = "Discard";
export const SAVE_CHANGES = "Save changes";
export const PLACEHOLDER = "Please select";
export const TEXTAREA_RESOLUTION_PLACEHOLDER = "Add a comment";
export const DEFAULT_COMMENT_TEXT = "Change of Status";

export const CREATED = "Created";
export const CLOSED_CASE = "Closed - Case";
export const INVESTIGATION = "Investigation";
export const CLOSED_NO_CASE = "Closed - No case";
export const DATA_QUALITY_ISSUE = "Data Quality Issue";
export const TUNING_NEEDED = "Tuning Needed";
