import { Table } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../theme";
import { EXPAND_ICON_TEST_ID } from "../TableAddons/ExpandIcon/ExpandIcon.constants";

export const StyledTable = styled(Table)`
  .shell-table-thead th {
    background-color: ${COLORS.athensGray} !important;
    border: none;
    font-size: 0.688rem !important;
    color: ${COLORS.tundora} !important;
    height: auto;
    padding: 0.5rem 0.75rem;
  }

  .shell-table-placeholder {
    display: none;
  }

  .shell-table--text-align-right {
    text-align: right !important;
  }

  .shell-table--text-align-left {
    text-align: left !important;
  }

  .shell-table--text-align-center {
    text-align: center !important;
  }

  .shell-table--max-width {
    max-width: 90px;
  }

  .shell-table-tbody {
    .shell-table-row {
      &:hover .shell-table-cell {
        background-color: ${COLORS.whiteLilac};
      }

      &:has(.${EXPAND_ICON_TEST_ID}) {
        border-bottom: none;

        .shell-table-cell {
          background-color: ${COLORS.blackSqueeze};
        }
      }
    }

    .shell-table-expanded-row {
      .shell-table-cell {
        padding: 1.5rem;
        background-color: ${COLORS.blackSqueeze};
      }

      &:hover .shell-table-cell {
        background-color: ${COLORS.blackSqueeze};
      }
    }
  }
`;
