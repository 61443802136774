import {
  Option,
  Select,
  SingleDatePicker,
} from "@sede-x/shell-ds-react-framework";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import { TAnomaliesInvestigatorFilterOptions } from "../../../../app/services/services.types";
import { useLazyGetAdvancedFiltersOptionsQuery } from "../../../../app/services/telescope.dropdowns.slice";
import AdvancedFilter from "../../../../components/AdvancedFilter";
import { TAnomalyFilter } from "../../Landing.types";
import {
  ANOMALIES_ADVANCED_BROKER_FILTER_ID,
  ANOMALIES_ADVANCED_BROKER_FILTER_LABEL,
  ANOMALIES_ADVANCED_BROKER_FILTER_OPTION_TEST_ID,
  ANOMALIES_ADVANCED_BROKER_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_BROKER_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_ID,
  ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_LABEL,
  ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_OPTION_TEST_ID,
  ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_ID,
  ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_LABEL,
  ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_OPTION_TEST_ID,
  ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_END_DATE_FILTER_ID,
  ANOMALIES_ADVANCED_END_DATE_FILTER_LABEL,
  ANOMALIES_ADVANCED_END_DATE_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_END_DATE_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_FILTERS_FORM_TEST_ID,
  ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_ID,
  ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_LABEL,
  ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_OPTION_TEST_ID,
  ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_LOCATION_FILTER_ID,
  ANOMALIES_ADVANCED_LOCATION_FILTER_LABEL,
  ANOMALIES_ADVANCED_LOCATION_FILTER_OPTION_TEST_ID,
  ANOMALIES_ADVANCED_LOCATION_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_LOCATION_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_START_DATE_FILTER_ID,
  ANOMALIES_ADVANCED_START_DATE_FILTER_LABEL,
  ANOMALIES_ADVANCED_START_DATE_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_START_DATE_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_ID,
  ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_LABEL,
  ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_OPTION_TEST_ID,
  ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_TEST_ID,
  ANOMALIES_ADVANCED_TRADER_FILTER_ID,
  ANOMALIES_ADVANCED_TRADER_FILTER_LABEL,
  ANOMALIES_ADVANCED_TRADER_FILTER_OPTION_TEST_ID,
  ANOMALIES_ADVANCED_TRADER_FILTER_PLACE_HOLDER,
  ANOMALIES_ADVANCED_TRADER_FILTER_TEST_ID,
} from "./AnomaliesAdvancedFilter.constants";
import { StyledFormField } from "./AnomaliesAdvancedFilter.styles";
import {
  anomaliesAdvancedFiltersToFormParams,
  formParamsToAnomaliesAdvancedFilters,
  IAnomaliesAdvancedFilterDatePickerControllerProps,
  IAnomaliesAdvancedFilterProps,
  IAnomaliesAdvancedFilterSelectControllerProps,
  TAnomaliesAdvancedFilterFormParams,
} from "./AnomaliesAdvancedFilter.types";

dayjs.extend(weekday);
dayjs.extend(localeData);

export const defaultAnomaliesAdvancedFilterValues: TAnomaliesAdvancedFilterFormParams =
  {
    startDate: "",
    endDate: "",
    location: [],
    trader: [],
    broker: [],
    tradeGroup: [],
    counterparty: [],
    dealType: [],
    investigator: [],
  };

const DatePickerController = ({
  control,
  id,
  dataTestId,
  label,
  placeHolder,
  disabled,
  invalid,
}: IAnomaliesAdvancedFilterDatePickerControllerProps) => (
  <Controller
    name={id}
    control={control}
    render={({ field }) => (
      <StyledFormField id={id} label={label} size="medium">
        <SingleDatePicker
          size="medium"
          placeholder={placeHolder}
          onChange={field.onChange}
          value={typeof field.value === "string" ? undefined : field.value}
          showToday={false}
          data-testid={dataTestId}
          format="MM/DD/YYYY"
          disabled={disabled}
          invalid={invalid}
        />
      </StyledFormField>
    )}
  />
);

const SelectController = ({
  control,
  isLoading,
  selectOptions,
  id,
  dataTestId,
  optionsDataTestId,
  label,
  placeHolder,
  optionValueTransformer,
}: IAnomaliesAdvancedFilterSelectControllerProps) => (
  <Controller
    name={id}
    control={control}
    render={({ field }) => (
      <StyledFormField id={id} label={label} size="medium">
        <Select
          placeholder={placeHolder}
          size="medium"
          mode="multiple"
          disabled={isLoading}
          showSearch
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          data-testid={dataTestId}
        >
          {selectOptions.map(
            (optionValue: string | TAnomaliesInvestigatorFilterOptions) => {
              const { value, label: displayValue } = optionValueTransformer
                ? optionValueTransformer(optionValue)
                : ({
                    value: optionValue,
                    label: optionValue,
                  } as TAnomalyFilter);
              return (
                <Option
                  data-testid={optionsDataTestId}
                  key={value}
                  value={value}
                >
                  {displayValue}
                </Option>
              );
            }
          )}
        </Select>
      </StyledFormField>
    )}
  />
);

const AnomaliesAdvancedFilter = ({
  currentAdvancedFiltersValues,
  onFiltersApplied,
}: IAnomaliesAdvancedFilterProps) => {
  const [loadFiltersOptions, { isLoading, data: filtersOptions }] =
    useLazyGetAdvancedFiltersOptionsQuery();
  const currentFormValues = anomaliesAdvancedFiltersToFormParams(
    currentAdvancedFiltersValues
  );

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: {
      ...defaultAnomaliesAdvancedFilterValues,
      ...currentFormValues,
    },
  });

  const startDateWatched = useWatch({
    control,
    name: "startDate",
  });

  const endDateWatched = useWatch({
    control,
    name: "endDate",
  });

  const isEndDateBeforeStartDate = useMemo(() => {
    if (startDateWatched && endDateWatched) {
      return dayjs(startDateWatched).isAfter(dayjs(endDateWatched));
    }
    return false;
  }, [endDateWatched]);

  // #region useEffects
  useEffect(() => {
    reset({
      ...defaultAnomaliesAdvancedFilterValues,
      ...currentFormValues,
    });
  }, [currentAdvancedFiltersValues]);

  useEffect(() => {
    if (!startDateWatched) {
      // set end date to undefined if start date is cleared
      setValue("endDate", "");
    }
  }, [startDateWatched]);
  // #endregion

  // #region handlers
  const onValidFormApplied = (reactHookFormData: object) => {
    onFiltersApplied(
      formParamsToAnomaliesAdvancedFilters(
        reactHookFormData as TAnomaliesAdvancedFilterFormParams,
        filtersOptions
      )
    );
  };

  const handleFiltersAppliance = (): boolean => {
    const { startDate: startDateValue, endDate: endDateValue } = getValues();

    if (endDateValue && !startDateValue) {
      return false;
    }

    if (
      startDateValue &&
      endDateValue &&
      dayjs(startDateValue).isAfter(dayjs(endDateValue))
    ) {
      return false;
    }

    handleSubmit(onValidFormApplied)();
    return isValid;
  };

  const handleResetForm = () => {
    reset({ ...defaultAnomaliesAdvancedFilterValues, ...currentFormValues });
  };

  const handleClearForm = () => {
    reset(defaultAnomaliesAdvancedFilterValues);
  };
  // #endregion

  return (
    <AdvancedFilter
      hasFiltersApplied={
        Object.values(currentFormValues).filter((v) =>
          Array.isArray(v) ? v.length !== 0 : !!v
        ).length > 0
      }
      isClearAvailable={
        Object.values(getValues()).filter((v) =>
          Array.isArray(v) ? v.length !== 0 : !!v
        ).length > 0 || isDirty
      }
      onDrawerOpened={loadFiltersOptions}
      onFiltersApplied={handleFiltersAppliance}
      onFiltersCleared={handleClearForm}
      onCancel={handleResetForm}
    >
      <form data-testid={ANOMALIES_ADVANCED_FILTERS_FORM_TEST_ID}>
        <DatePickerController
          control={control}
          id={ANOMALIES_ADVANCED_START_DATE_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_START_DATE_FILTER_TEST_ID}
          label={ANOMALIES_ADVANCED_START_DATE_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_START_DATE_FILTER_PLACE_HOLDER}
        />
        <DatePickerController
          control={control}
          id={ANOMALIES_ADVANCED_END_DATE_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_END_DATE_FILTER_TEST_ID}
          label={ANOMALIES_ADVANCED_END_DATE_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_END_DATE_FILTER_PLACE_HOLDER}
          disabled={!startDateWatched}
          invalid={isEndDateBeforeStartDate}
        />
        <SelectController
          control={control}
          isLoading={isLoading}
          selectOptions={filtersOptions?.location || []}
          id={ANOMALIES_ADVANCED_LOCATION_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_LOCATION_FILTER_TEST_ID}
          optionsDataTestId={ANOMALIES_ADVANCED_LOCATION_FILTER_OPTION_TEST_ID}
          label={ANOMALIES_ADVANCED_LOCATION_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_LOCATION_FILTER_PLACE_HOLDER}
        />
        <SelectController
          control={control}
          isLoading={isLoading}
          selectOptions={filtersOptions?.trader || []}
          id={ANOMALIES_ADVANCED_TRADER_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_TRADER_FILTER_TEST_ID}
          optionsDataTestId={ANOMALIES_ADVANCED_TRADER_FILTER_OPTION_TEST_ID}
          label={ANOMALIES_ADVANCED_TRADER_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_TRADER_FILTER_PLACE_HOLDER}
        />
        <SelectController
          control={control}
          isLoading={isLoading}
          selectOptions={filtersOptions?.broker || []}
          id={ANOMALIES_ADVANCED_BROKER_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_BROKER_FILTER_TEST_ID}
          optionsDataTestId={ANOMALIES_ADVANCED_BROKER_FILTER_OPTION_TEST_ID}
          label={ANOMALIES_ADVANCED_BROKER_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_BROKER_FILTER_PLACE_HOLDER}
        />
        <SelectController
          control={control}
          isLoading={isLoading}
          selectOptions={filtersOptions?.tradeGroup || []}
          id={ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_TEST_ID}
          optionsDataTestId={
            ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_OPTION_TEST_ID
          }
          label={ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_PLACE_HOLDER}
        />
        <SelectController
          control={control}
          isLoading={isLoading}
          selectOptions={filtersOptions?.counterparty || []}
          id={ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_TEST_ID}
          optionsDataTestId={
            ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_OPTION_TEST_ID
          }
          label={ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_PLACE_HOLDER}
        />
        <SelectController
          control={control}
          isLoading={isLoading}
          selectOptions={filtersOptions?.dealType || []}
          id={ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_TEST_ID}
          optionsDataTestId={ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_OPTION_TEST_ID}
          label={ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_PLACE_HOLDER}
        />
        <SelectController
          control={control}
          isLoading={isLoading}
          selectOptions={filtersOptions?.investigator || []}
          id={ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_ID}
          dataTestId={ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_TEST_ID}
          optionsDataTestId={
            ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_OPTION_TEST_ID
          }
          label={ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_LABEL}
          placeHolder={ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_PLACE_HOLDER}
          optionValueTransformer={(option) => {
            const { id: value, name: label } =
              option as TAnomaliesInvestigatorFilterOptions;
            return { value, label };
          }}
        />
      </form>
    </AdvancedFilter>
  );
};

export default AnomaliesAdvancedFilter;
