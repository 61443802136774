import { Modal, TextArea } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .shell-modal-container-header {
    border-bottom: none;
  }

  .shell-modal-container-close {
    display: none;
  }

  .shell-modal-container-footer {
    border-top: none;
  }

  textarea::placeholder {
    // italic
    font-style: italic;
  }
`;

export const StyledTextArea = styled(TextArea)`
  & textarea {
    min-height: 15.563rem !important;
  }
`;
