import { Button, Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { TopHeader } from "../../../../components/TopHeader/TopHeader.styles";
import { COLORS } from "../../../../theme";
import { breakpoint } from "../../../../theme/breakpoints";

export const CommentContainer = styled(Flexbox)<{
  removeSpaces?: boolean;
  removeBorder?: boolean;
}>`
  background: #ffffff;
  border-radius: 2px;
  padding: ${({ removeSpaces }) => (removeSpaces ? "0" : "2.25rem 1rem")};
  border-bottom: ${({ removeBorder }) =>
    removeBorder ? 0 : `0.063rem solid ${COLORS.athensGray}`};

  .bolder {
    font-weight: 600;
  }
`;

export const OldCommentContainer = styled(CommentContainer)`
  background-color: transparent;
`;

export const NoCommentsContainer = styled(CommentContainer)`
  padding: 2.875rem 15.063rem;
  border-bottom: none;
`;

export const AnomalyCommentsContainer = styled(Flexbox)`
  .max-content {
    width: max-content;
  }

  @media ${breakpoint.mediaQuery.md} {
    min-width: 25.938rem;
  }
`;

export const CommentButton = styled(Button)``;

export const HeaderSection = styled(Flexbox)`
  margin-bottom: 2.25rem;
  place-items: baseline;
`;

export const StyledTopHeader = styled(TopHeader)`
  width: max-content;
`;

export const CommentsSection = styled(Flexbox)<{ hideScroll: boolean }>`
  max-height: 46.5rem;
  overflow-y: ${({ hideScroll }) => (hideScroll ? "hidden" : "scroll")};
  overflow-x: hidden;
  > .comment-item:last-child {
    border-bottom: none;
  }
  padding-right: 0.25rem;
`;
