import { Button, Tag } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../theme";

export const StyledTag = styled(Tag)`
  background-color: ${COLORS.whiteSmoke};
  color: ${COLORS.mirage};
`;

export const StyledButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  min-height: fit-content;

  svg path {
    fill: ${COLORS.mirage};
  }
`;

export const StyledClearButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  min-height: fit-content;
  font-size: 11px;
`;
