import { cleanNullishValues, toStringParams } from "../../common/utils";
import {
  IEndurTraderAnomaliesRowData,
  IEndurTraderAnomaliesTableData,
} from "../../features/Landing/Landing.types";
import {
  ANOMALY_COMMENT_TAG_TYPE,
  ENDUR_TRADER_ANOMALIES_TAG_TYPE,
  ENDUR_TRADER_ANOMALY_TAG_TYPE,
  TELESCOPE_API_ROUTES,
} from "./services.constants";
import {
  IAnomaliesCountSummary,
  IAnomalyComment,
  IDataEndurTraderAnomaly,
  IDeal,
  IResolutionStatus,
  ITelescopeAPIPagedResponse,
  ITelescopeAPIResponse,
  IUpdateAnomalyComment,
  TAddAnomalyCommentArgs,
  TEndurTraderAnomalyQueryParams,
  TGetAnomalyCommentsArgs,
  TGetAnomalyDealsArgs,
  TGetEndurTraderAnomaliesArgs,
  TRawDataTableRow,
} from "./services.types";
import { telescopeBaseAPIService } from "./telescope.base.service";

export const anomaliesAPISlice = telescopeBaseAPIService.injectEndpoints({
  endpoints: (builder) => ({
    getTrendData: builder.query<IAnomaliesCountSummary | null, void>({
      query: () => ({
        url: `${TELESCOPE_API_ROUTES.anomaly.anomalies}/summary`,
      }),
      transformResponse: (
        response: ITelescopeAPIResponse<IAnomaliesCountSummary>
      ) => response.data,
    }),
    getAnomalyResolutionsStatus: builder.query<IResolutionStatus | null, void>({
      query: () => ({
        url: "anomaly/resolutions/status",
      }),
      transformResponse: (response: ITelescopeAPIResponse<IResolutionStatus>) =>
        response.data,
    }),
    getEndurTraderAnomalies: builder.query<
      IEndurTraderAnomaliesTableData,
      TGetEndurTraderAnomaliesArgs
    >({
      query: ({ queryParams }) => {
        let url = `${TELESCOPE_API_ROUTES.anomaly.anomalies}`;

        if (queryParams) {
          url = `${url}?${toStringParams(cleanNullishValues(queryParams))}`;
        }

        return {
          method: "GET",
          url,
        };
      },
      transformResponse: ({
        data,
      }: ITelescopeAPIPagedResponse<IDataEndurTraderAnomaly>) => {
        if (!data || !data.pagedAnomalies?.length) {
          return {
            totalAnomalies: 0,
            anomalies: [],
          };
        }

        return {
          totalAnomalies: data.totalAnomalies,
          anomalies: data.pagedAnomalies?.map((anomaly) => ({
            id: anomaly.id,
            descriptor: anomaly.identifiers,
            tradeDateCount: anomaly.tradingDate.count,
            tradeDateVolume: anomaly.tradingDate.volume,
            zScoreCount: anomaly.zScore.count,
            zScoreVolume: anomaly.zScore.volume,
            zScoreAverage: anomaly.zScore.average,
            investigators: anomaly.investigators ?? [],
            resolution: anomaly.resolution,
            stdDeviation: anomaly.stdDeviation,
            tradingDate: anomaly.tradingDate,
            term: anomaly.term,
            reportName: anomaly.reportName,
            status: anomaly.status,
            reportingDate: anomaly.reportingDate,
          })),
        };
      },
      // this property is used to enabled automated re-fetching
      providesTags: [ENDUR_TRADER_ANOMALIES_TAG_TYPE],
    }),
    getEndurTraderAnomaly: builder.query<
      IEndurTraderAnomaliesRowData | null,
      TEndurTraderAnomalyQueryParams
    >({
      query: ({ id }) => {
        const url = `${TELESCOPE_API_ROUTES.anomaly.endurDeals}/${id}`;

        return {
          method: "GET",
          url,
        };
      },
      transformResponse: ({
        data: anomaly,
      }: ITelescopeAPIResponse<IDataEndurTraderAnomaly>) => {
        if (!anomaly) {
          return anomaly;
        }

        return {
          id: anomaly.id,
          descriptor: anomaly.identifiers,
          tradeDateCount: anomaly.tradingDate.count,
          tradeDateVolume: anomaly.tradingDate.volume,
          zScoreCount: anomaly.zScore.count,
          zScoreVolume: anomaly.zScore.volume,
          zScoreAverage: anomaly.zScore.average,
          investigators: anomaly.investigators ?? [],
          resolution: anomaly.resolution,
          stdDeviation: anomaly.stdDeviation,
          tradingDate: anomaly.tradingDate,
          term: anomaly.term,
          reportName: anomaly.reportName,
          status: anomaly.status,
          reportingDate: anomaly.reportingDate,
        };
      },
      // this property is used to enabled automated re-fetching
      providesTags: [ENDUR_TRADER_ANOMALY_TAG_TYPE],
    }),
    getAnomalyDeals: builder.query<TRawDataTableRow[], TGetAnomalyDealsArgs>({
      query: ({ anomalyId }) => {
        const url = `${TELESCOPE_API_ROUTES.anomaly.endurDeals}/${anomalyId}/deals`;

        return {
          method: "GET",
          url,
        };
      },
      transformResponse: ({ data }: ITelescopeAPIResponse<IDeal[]>) => {
        if (!data || !data?.length) {
          return [];
        }

        return data.map((deal) => ({
          uniqueDealKey: deal.dealKey,
          traderLocation: {
            trader: deal.trader,
            location: deal.location,
          },
          volume: deal.volume,
          companyName: deal.counterparty,
          broker: deal.broker,
          legalIdentity: {
            internal: deal.internalLegalEntity,
            external: deal.externalLegalEntity,
          },
          direction: deal.direction,
        }));
      },
    }),
    getAnomalyComments: builder.query<
      IAnomalyComment[],
      TGetAnomalyCommentsArgs
    >({
      query: ({ anomalyId }) => {
        const url = `anomaly/${anomalyId}/comments`;

        return {
          method: "GET",
          url,
        };
      },

      transformResponse: ({
        data,
      }: ITelescopeAPIResponse<IAnomalyComment[]>) => {
        if (!data || !data?.length) {
          return [];
        }

        return data;
      },
      // this property is used to enabled automated re-fetching
      providesTags: [ANOMALY_COMMENT_TAG_TYPE],
    }),
    addAnomalyComment: builder.mutation<void, TAddAnomalyCommentArgs>({
      query: ({ anomalyId, ...params }) => {
        const url = `anomaly/${anomalyId}/comments`;

        return {
          method: "POST",
          url,
          body: {
            ...params,
          },
        };
      },
      invalidatesTags: [
        ANOMALY_COMMENT_TAG_TYPE,
        ENDUR_TRADER_ANOMALY_TAG_TYPE,
      ],
    }),
    updateAnomalyComment: builder.mutation<void, IUpdateAnomalyComment>({
      query: ({ anomalyId, investigatorID, status, resolution, comment }) => ({
        url: `anomaly/${anomalyId}/comments`,
        method: "POST",
        body: {
          investigatorID,
          status,
          resolution,
          comment,
        },
      }),
      invalidatesTags: [
        ANOMALY_COMMENT_TAG_TYPE,
        ENDUR_TRADER_ANOMALY_TAG_TYPE,
      ],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useLazyGetTrendDataQuery,
  useLazyGetEndurTraderAnomaliesQuery,
  useLazyGetAnomalyDealsQuery,
  useLazyGetAnomalyCommentsQuery,
  useAddAnomalyCommentMutation,
  useUpdateAnomalyCommentMutation,
  useGetAnomalyResolutionsStatusQuery,
  useGetEndurTraderAnomalyQuery,
  useLazyGetEndurTraderAnomalyQuery,
} = anomaliesAPISlice;
