import { ISvgIconInterface } from "../icons.types";

const HighSeverityIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M13.6569 2.34311L9.49753 4.38472L8 0L6.50247 4.38472L2.34311 2.34311L4.38472 6.50247L0 8L4.38472 9.49753L2.34311 13.6569L6.50247 11.6153L8 16L9.49753 11.6153L13.6569 13.6569L11.6153 9.49753L16 8L11.6153 6.50247L13.6569 2.34311Z"
      fill="#DD1D21"
    />
  </svg>
);

export default HighSeverityIcon;
