import { Button } from "@sede-x/shell-ds-react-framework";
import { Download } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { Property } from "csstype";
import styled from "styled-components";

import { breakpoint } from "../../theme/breakpoints";

export const StyledButton = styled(Button)<{
  padding?: Property.Padding;
}>`
  padding: ${({ padding }) => padding};
  margin-top: 0.0625rem;
  @media ${breakpoint.mediaQuery.lg} {
    padding: 0 0.9375rem;
  }
`;

export const StyledDownloadIcon = styled(Download)`
  margin-top: 4px !important;
`;
