import "./styles/index.css";

import { Route, Routes } from "react-router-dom";

import { useAppSelector } from "./app/hooks";
import { login, logout } from "./Authentication";
import { LAST_PAGE_VISITED_SS_KEY } from "./Authentication/Authentication.constants";
import { selectIsAuthenticated } from "./Authentication/Authentication.slice";
import useAuthCallbackRedirect from "./Authentication/hooks/useAuthCallbackRedirect.hook";
import useRequestRefreshToken from "./Authentication/hooks/useRequestRefreshToken.hook";
import IdleTimer from "./components/IdleTimer";
import Layout from "./components/Layout";
import { appRoutes } from "./config/routes";
import Anomaly from "./features/Anomaly";
import Landing from "./features/Landing";
import Login from "./features/Login";
import NoAccess from "./features/NoAccess";
import Reports from "./features/Reports";

function App() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  useAuthCallbackRedirect({
    route:
      sessionStorage.getItem(LAST_PAGE_VISITED_SS_KEY) || appRoutes.landing,
  });
  useRequestRefreshToken();

  return (
    <>
      {isAuthenticated && <IdleTimer logout={logout} />}
      <Routes>
        <Route path={appRoutes.login} element={<Login login={login} />} />
        <Route element={<Layout />}>
          <Route element={<Landing />} index />
          <Route path={appRoutes.reports} element={<Reports />} />
          <Route path={appRoutes.anomalyDetect}>
            <Route path=":id" element={<Anomaly />}></Route>
          </Route>
          <Route path={appRoutes.alerts} element={<div>Alert Page</div>} />
          <Route path={appRoutes.noAccess} element={<NoAccess />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
