/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { access_token } from "../../Authentication";
import {
  ANOMALY_COMMENT_TAG_TYPE,
  BASE_API_URL,
  ENDUR_TRADER_ANOMALIES_TAG_TYPE,
  ENDUR_TRADER_ANOMALY_TAG_TYPE,
} from "./services.constants";

export const telescopeBaseAPIService = createApi({
  tagTypes: [
    ENDUR_TRADER_ANOMALY_TAG_TYPE,
    ENDUR_TRADER_ANOMALIES_TAG_TYPE,
    ANOMALY_COMMENT_TAG_TYPE,
  ],

  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers) => {
      if (access_token) {
        headers.set("authorization", `Bearer ${access_token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
