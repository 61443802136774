import "react-toastify/dist/ReactToastify.css";

import { ShellThemeProvider } from "@sede-x/shell-ds-react-framework";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import store from "../app/store";

const Providers = ({ children }: { children: React.ReactNode }) => (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ShellThemeProvider theme="light">{children}</ShellThemeProvider>
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

export default Providers;
