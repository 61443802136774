import { appRoutes } from "../../config/routes";
import LogOutIcon from "../icons/LogOutIcon/index";
import {
  StyledHomeSolidIcon,
  StyledMapIcon,
  StyledRefineryIcon,
} from "./Navbar.styles";
import { INavbarMenuItem } from "./Navbar.types";

// #region Test IDs
export const NAVBAR_TEST_IDS = {
  logoutIconTestId: "logout-icon",
  dropDownContainerTestId: "dropdown-container",
};
// #endregion

export const MENU_ITEMS: INavbarMenuItem[] = [
  {
    label: "Home",
    path: appRoutes.landing,
    icon: <StyledHomeSolidIcon width="20px" height="20px" />,
  },
  {
    label: "Reports",
    path: appRoutes.reports,
    icon: <StyledRefineryIcon disabled width="20px" height="20px" />,
    disabled: true,
  },
  {
    label: "Alerts",
    path: appRoutes.alerts,
    icon: <StyledMapIcon disabled width="20px" height="20px" />,
    disabled: true,
  },
];

export const NAVBAR_DROPDOWN_MENU_ITEMS: INavbarMenuItem[] = [
  {
    label: "Logout",
    icon: <LogOutIcon testId={NAVBAR_TEST_IDS.logoutIconTestId} />,
  },
];
