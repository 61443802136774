import { Button } from "@sede-x/shell-ds-react-framework";

import {
  CONTINUE_BUTTON_TEXT,
  INACTIVITY_ACTION,
  INACTIVITY_MODAL,
  INACTIVITY_QUESTION,
  LOGOUT_BUTTON_TEXT,
} from "./InactivityModal.constants";
import {
  RemainingTime,
  StyledActionText,
  StyledButtonWrapper,
  StyledLogoutButton,
  StyledModalCard,
  StyledModalHeading,
  StyledModalWrapper,
  StyledQuestionText,
} from "./InactivityModal.styles";
import { InactivityModalType } from "./InactivityModal.types";
import { showSeconds } from "./InactivityModal.utils";

const InactivityModal = ({
  logout,
  remaining,
  stillHere,
}: InactivityModalType) => (
  <StyledModalWrapper
    justifyContent="center"
    alignItems="center"
    data-testid="modal-inactivity"
  >
    <StyledModalCard>
      <StyledModalHeading type="h3">{INACTIVITY_MODAL}</StyledModalHeading>
      <StyledActionText>{INACTIVITY_ACTION}</StyledActionText>
      <RemainingTime type="h3">{`00:${showSeconds(
        remaining
      )} s`}</RemainingTime>
      <StyledQuestionText>{INACTIVITY_QUESTION}</StyledQuestionText>
      <StyledButtonWrapper justifyContent="flex-end">
        <StyledLogoutButton
          onClick={logout}
          variant="outlined"
          data-testid="idle-modal-logout-button"
        >
          {LOGOUT_BUTTON_TEXT}
        </StyledLogoutButton>
        <Button onClick={stillHere} data-testid="idle-modal-continue-button">
          {CONTINUE_BUTTON_TEXT}
        </Button>
      </StyledButtonWrapper>
    </StyledModalCard>
  </StyledModalWrapper>
);

export default InactivityModal;
