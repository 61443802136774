import { useAtom } from "jotai";
import { useState } from "react";

import { DefaultRecordType } from "../../common/types";
import { expandedAnomaliesAtom } from "../../features/Landing/Landing.atoms";
import { COLORS } from "../../theme";
import ExpandableRowContent from "../TableAddons/ExpandableRowContent";
import { RowContent, StyledCard, TitleContainer } from "./CardView.styles";
import { ICardViewProps } from "./CardView.types";

const CardView = <T extends DefaultRecordType>({
  columns,
  className,
  testId,
  record,
  expandable,
  variant,
}: ICardViewProps<T>) => {
  const [expandedAnomalies, setExpandedAnomalies] = useAtom(
    expandedAnomaliesAtom
  );
  const [openExpandableRow, setOpenExpandableRow] = useState(
    variant === "tableView" && expandedAnomalies.includes(`${record.id}`)
  );

  return (
    <StyledCard
      className={className}
      data-testid={testId}
      data-card-key={record.id}
      backgroundColor={openExpandableRow ? COLORS.blackSqueeze : undefined}
    >
      {columns.map((column, index) => (
        <RowContent key={`${column.key}`}>
          <TitleContainer>{column.title}</TitleContainer>
          {column.render ? (
            <>{column.render(record[`${column.dataIndex}`], record, index)}</>
          ) : (
            <span>{record && record[`${column.dataIndex}`]}</span>
          )}
        </RowContent>
      ))}
      <ExpandableRowContent
        isExpanded={openExpandableRow}
        onExpand={() => {
          if (variant === "tableView") {
            setExpandedAnomalies(
              openExpandableRow
                ? expandedAnomalies.filter((k) => k !== `${record.id}`)
                : [...expandedAnomalies, `${record.id}`]
            );
          }
          setOpenExpandableRow(!openExpandableRow);
        }}
        onDetailsClick={
          expandable?.onDetailsClick &&
          function () {
            expandable.onDetailsClick && expandable.onDetailsClick(record);
          }
        }
      ></ExpandableRowContent>
      {openExpandableRow &&
        expandable &&
        expandable.expandedRowRender &&
        expandable.expandedRowRender(record)}
    </StyledCard>
  );
};

export default CardView;
