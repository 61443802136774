import { Button } from "@sede-x/shell-ds-react-framework";
import {
  ChevronRight,
  ChevronUp,
  FileSolid,
} from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";

import {
  COLLAPSE_ICON_TEST_ID,
  EXPAND_ICON_TEST_ID,
} from "../ExpandIcon/ExpandIcon.constants";
import {
  DETAILS_BTN_LABEL,
  MORE_DATA_BTN_LABEL,
} from "./ExpandableRowContent.constants";
import { MoreDataBtn, RowContent } from "./ExpandableRowContent.styles";
import { IExpandableRowContentProps } from "./ExpandableRowContent.types";

const ExpandableRowContent = ({
  isExpanded,
  onExpand,
  onDetailsClick,
}: IExpandableRowContentProps) => (
  <RowContent justifyContent="space-between">
    <MoreDataBtn
      icon={
        isExpanded ? (
          <ChevronUp data-testid={EXPAND_ICON_TEST_ID} />
        ) : (
          <ChevronRight data-testid={COLLAPSE_ICON_TEST_ID} />
        )
      }
      variant="transparent"
      iconPosition="right"
      type="button"
      onClick={() => {
        onExpand(!isExpanded);
      }}
    >
      {MORE_DATA_BTN_LABEL}
    </MoreDataBtn>
    {onDetailsClick && (
      <Button
        onClick={onDetailsClick}
        icon={<FileSolid />}
        iconPosition="right"
        variant="outlined"
      >
        {DETAILS_BTN_LABEL}
      </Button>
    )}
  </RowContent>
);

export default ExpandableRowContent;
