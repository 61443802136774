import { ISvgIconInterface } from "../icons.types";

const AlertIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.51801 21C1.11957 21 0.870746 20.5941 1.07038 20.2698L11.5524 3.24271C11.7516 2.9191 12.2484 2.9191 12.4476 3.24271L22.9296 20.2698C23.1293 20.5941 22.8804 21 22.482 21H1.51801ZM11.5 18C11.2239 18 11 17.7761 11 17.5V16.5C11 16.2239 11.2239 16 11.5 16L12.5 16C12.7761 16 13 16.2239 13 16.5V17.5C13 17.7761 12.7761 18 12.5 18H11.5ZM12.5 9C12.7761 9 13 9.22386 13 9.5L13 13.5C13 13.7761 12.7761 14 12.5 14H11.5C11.2239 14 11 13.7761 11 13.5L11 9.5C11 9.22386 11.2239 9 11.5 9H12.5Z"
      fill="#0097A9"
    />
  </svg>
);

export default AlertIcon;
