import styled from "styled-components";

import { breakpoint } from "../../../../theme/breakpoints";
import { BaseStyledSelect } from "../../Landing.styles";

export const StyledSelect = styled(BaseStyledSelect)`
  .shell-select-container-selection-item-content {
    display: inline-block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 1.375rem;
    white-space: nowrap;
  }

  .shell-select-container-item-option-content {
    display: block;
    width: 100%;
  }

  .shell-select-container-item-option {
    padding-left: 16px;
  }

  .shell-select-container-item-option-header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 16px;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .shell-select-container-item-option-selected {
    background: none;
  }

  .shell-select-container-item-option-grouped {
    padding: 9px 16px 9px 16px;
  }

  @media ${breakpoint.mediaQuery.sm} {
    min-width: 10.3125rem;
  }

  @media ${breakpoint.mediaQuery.lg} {
    max-width: 11.438rem;
  }

  @media ${breakpoint.mediaQuery.md} {
    .shell-select-container-selection-item-content {
      max-width: 2rem;
    }
  }

  @media ${breakpoint.mediaQuery.lg} {
    min-width: 23.75rem;

    .shell-select-container-selection-item-content {
      max-width: 13.75rem;
    }
  }
`;
