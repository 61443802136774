/* eslint-disable camelcase */

import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import env from "../../common/env";
import { appRoutes } from "../../config/routes";
import { isUserAuthorized, refresh_token, refreshToken } from "..";
import { HasNoAccessAtom } from "../Authentication.atoms";
import {
  selectIsAuthenticated,
  setIsAuthenticated,
} from "../Authentication.slice";

/**
 * This Hook is used to automatically request a token every fixed seconds.
 */
export default function useRequestRefreshToken() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [, setHasNoAccess] = useAtom(HasNoAccessAtom);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        if (refresh_token) {
          await refreshToken();
        }

        if (isAuthenticated) {
          const isUserAuth = await isUserAuthorized();
          if (!isUserAuth) {
            setHasNoAccess(true);
            navigate(appRoutes.noAccess);
          }
        }
      } catch (e) {
        console.log(e);
        dispatch(setIsAuthenticated(false));
        setHasNoAccess(true);
        navigate(appRoutes.login);
      }
    }, env.REACT_APP_REQUEST_REFRESH_TOKEN_INTERVAL);

    return () => clearInterval(interval);
  }, [isAuthenticated]);
}
