import { ISvgIconInterface } from "../icons.types";

const NoAnomaliesIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="105"
    height="111"
    viewBox="0 0 105 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M33.8176 16.0139L76.7933 19.3406L95.842 59.5681L71.1824 94.9217L27.6724 90.4666L9.52434 52.1411L33.8176 16.0139Z"
      fill="#E2E5EA"
    />
    <path
      d="M39.0875 52.6891L42.35 40.7266H62.2875L66.275 52.6891V55.2266H39.0875V52.6891Z"
      fill="#CDD1D6"
    />
    <path
      d="M65.9643 60.7148H57.1607C56.8748 60.7148 56.6429 60.9467 56.6429 61.2327V62.7863H48.3571V61.2327C48.3571 60.9467 48.1252 60.7148 47.8393 60.7148H39.0357C38.4636 60.7148 38 61.1785 38 61.7506V67.9648C38 68.5369 38.4636 69.0006 39.0357 69.0006H65.9643C66.5364 69.0006 67 68.5369 67 67.9648V61.7506C67 61.1785 66.5364 60.7148 65.9643 60.7148Z"
      fill="#999EA3"
    />
    <path
      d="M67 52.4286L62.8571 40H42.1429L38 52.4286V58.6429C38 59.2149 38.4636 59.6786 39.0357 59.6786H65.9643C66.5364 59.6786 67 59.2149 67 58.6429V52.4286ZM57.1607 51.3929C56.8748 51.3929 56.6429 51.6248 56.6429 51.9107V53.4643H48.3571V51.9107C48.3571 51.6248 48.1252 51.3929 47.8393 51.3929H40.0714L43.1786 41.0357H61.8214L64.9286 51.3929H57.1607Z"
      fill="#999EA3"
    />
    <path
      d="M86.7992 35.9804L53.4879 17.0536C52.8752 16.7054 52.1245 16.7054 51.5118 17.0536L19.2125 35.4054C18.587 35.7609 18.2006 36.4249 18.2006 37.1444V74.5952C18.2006 75.3025 18.5742 75.9572 19.1831 76.317L51.5021 95.4146C52.1192 95.7793 52.8843 95.7858 53.5075 95.4317L85.7872 77.091C86.4127 76.7355 86.7992 76.0715 86.7992 75.352V53.9096"
      stroke="#999EA3"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default NoAnomaliesIcon;
