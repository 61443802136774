import { useTabletMode } from "../../common/hooks";
import {
  ANOMALY_EXPORT_BUTTON_TEST_ID,
  ANOMALY_EXPORT_BUTTON_TEXT,
} from "./AnomalyExportButton.constants";
import { StyledButton, StyledDownloadIcon } from "./AnomalyExportButton.styles";
import { IAnomalyExportButtonProps } from "./AnomalyExportButton.types";

const AnomalyExportButton = ({ onExportClick }: IAnomalyExportButtonProps) => {
  const isTabletMode = useTabletMode();

  return (
    <StyledButton
      icon={<StyledDownloadIcon />}
      variant="filled"
      iconOnly={isTabletMode}
      onClick={onExportClick}
      padding={isTabletMode ? "1.375rem 1.5rem" : undefined}
      data-testid={ANOMALY_EXPORT_BUTTON_TEST_ID}
    >
      {ANOMALY_EXPORT_BUTTON_TEXT}
    </StyledButton>
  );
};

export default AnomalyExportButton;
