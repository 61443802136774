import dayjs from "dayjs";

import { TAnomaliesFilters } from "../../features/Landing/Landing.types";

type TFiltersKeys =
  | "status"
  | "report"
  | "resolution"
  | "severity"
  | "startDate"
  | "endDate"
  | "trader"
  | "location"
  | "counterparty"
  | "broker"
  | "tradeGroup"
  | "dealType"
  | "investigator";

export type TActiveFiltersBarVariant = "advancedFilters" | "exportFilters";

export interface IActiveFiltersBarProps {
  currentFilters: TAnomaliesFilters;
  onFiltersRemoved?: (newValues: TAnomaliesFilters) => void;
  variant: TActiveFiltersBarVariant;
}

type TFilterOrderAndLabel = {
  label: string;
  variant?: TActiveFiltersBarVariant;
  valueTransformer?: (value: string) => string;
};

export type TActiveFilter = {
  key: string;
  label: string;
  value: string;
  isMultipleValueFilter: boolean;
  variant?: TActiveFiltersBarVariant;
};

const dateValueTransformer = (value: string) =>
  dayjs(value).format("MM/DD/YYYY");

export const filtersOrderAndLabels: {
  [key in TFiltersKeys]: TFilterOrderAndLabel;
} = {
  status: { label: "Status", variant: "exportFilters" },
  report: { label: "Report", variant: "exportFilters" },
  resolution: { label: "Resolution", variant: "exportFilters" },
  severity: { label: "Severity", variant: "exportFilters" },
  startDate: { label: "Start Date", valueTransformer: dateValueTransformer },
  endDate: { label: "End Date", valueTransformer: dateValueTransformer },
  location: { label: "Location" },
  trader: { label: "Trader" },
  broker: { label: "Broker" },
  tradeGroup: { label: "Trade Group" },
  counterparty: { label: "Counterparty" },
  dealType: { label: "Deal Type" },
  investigator: { label: "Reviewed By" },
};
