import { Loading as LoadingSpinner } from "@sede-x/shell-ds-react-framework";

import { LOADING_SPINNER } from "./loading.constants";
import { StyledFlexbox } from "./Loading.styles";
import { ILoadingProps } from "./loading.types";

export const Loading = ({
  isLoading,
  size,
  className,
  removeMargin = false,
}: ILoadingProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <StyledFlexbox
      removeMargin={removeMargin}
      className={className}
      justifyContent="center"
    >
      <LoadingSpinner
        className="loading--supernova-color"
        size={size}
        data-testid={LOADING_SPINNER}
      />
    </StyledFlexbox>
  );
};
