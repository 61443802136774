import { Option } from "@sede-x/shell-ds-react-framework";

import { BaseStyledSelect } from "../../Landing.styles";
import { RESOLUTION_SELECT_OPTION_TEST_ID } from "./ResolutionsSelect.constants";
import { StyledSelect } from "./ResolutionsSelect.styles";
import { IResolutionsSelectProps } from "./ResolutionsSelect.types";

const ResolutionsSelect = ({
  resolutionOptions,
  disableMaxWidth,
  ...props
}: IResolutionsSelectProps) => {
  if (disableMaxWidth) {
    return (
      <BaseStyledSelect {...props}>
        {resolutionOptions?.map((resolution, index) => (
          <Option
            data-testid={RESOLUTION_SELECT_OPTION_TEST_ID}
            key={`${resolution.value}${index}`}
            value={resolution.value}
          >
            {resolution.label}
          </Option>
        ))}
      </BaseStyledSelect>
    );
  }

  return (
    <StyledSelect {...props}>
      {resolutionOptions?.map((resolution, index) => (
        <Option
          data-testid={RESOLUTION_SELECT_OPTION_TEST_ID}
          key={`${resolution.value}${index}`}
          value={resolution.value}
        >
          {resolution.label}
        </Option>
      ))}
    </StyledSelect>
  );
};

export default ResolutionsSelect;
