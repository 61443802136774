import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import authenticationReducer, {
  AUTHENTICATION_NAMESPACE,
} from "../Authentication/Authentication.slice";
import { telescopeBaseAPIService } from "./services/telescope.base.service";

const store = configureStore({
  reducer: {
    [telescopeBaseAPIService.reducerPath]: telescopeBaseAPIService.reducer,
    [AUTHENTICATION_NAMESPACE]: authenticationReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(telescopeBaseAPIService.middleware),
  devTools: process.env.REACT_APP_ENVIRONMENT === "development",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export default store;
