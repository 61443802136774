import { ISvgIconInterface } from "../icons.types";

const LowSeverityIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="13"
    height="6"
    viewBox="0 0 13 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M6.0044 6C9.33093 6 12.0044 3.32652 12.0044 0.000216845L0.00439453 0C0.00439453 3.29538 2.67775 5.99978 6.0044 5.99978V6Z"
      fill="#008542"
    />
  </svg>
);

export default LowSeverityIcon;
