import { ISvgIconInterface } from "../icons.types";

const MediumSeverityIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M8 2C4.68619 2 2 4.68619 2 8C2 11.3138 4.68619 14 8 14C11.3138 14 14 11.3138 14 8C14 4.68619 11.3138 2 8 2ZM8 13.3333C5.05452 13.3333 2.66667 10.9455 2.66667 8C2.66667 5.05452 5.05452 2.66667 8 2.66667C10.9455 2.66667 13.3333 5.05452 13.3333 8C13.3333 10.9455 10.9455 13.3333 8 13.3333Z"
      fill="#EB8705"
    />
    <path
      d="M12 8C12 10.2092 10.2091 12 8 12C5.7909 12 4 10.2091 4 8C4 5.7909 5.7909 4 8 4C10.2091 4 12 5.7909 12 8Z"
      fill="#EB8705"
    />
  </svg>
);

export default MediumSeverityIcon;
