import { Flexbox } from "@sede-x/shell-ds-react-framework";

import AlertIcon from "../../../../components/icons/Alert";
import {
  ALERT_ICON_TEST_ID,
  ANOMALY_EXPORT_SUB_TITLE_ACTIVE_TEXT,
  ANOMALY_EXPORT_TITLE_ACTIVE_DESCRIPTION_TEXT,
  ANOMALY_EXPORT_TITLE_ACTIVE_TEXT,
  ANOMALY_EXPORT_TITLE_FULL_DESCRIPTION_TEXT,
  ANOMALY_EXPORT_TITLE_FULL_TEXT,
} from "./AnomalyExportSectionMessage.constants";
import { RoundedContainer, Title } from "./AnomalyExportSectionMessage.styles";
import { IAnomalyExportSectionMessageProps } from "./AnomalyExportSectionMessage.types";

const AnomalyExportSectionMessage = ({
  areFiltersActive: isFiltersActive,
}: IAnomalyExportSectionMessageProps) => {
  const title = isFiltersActive
    ? ANOMALY_EXPORT_TITLE_ACTIVE_TEXT
    : ANOMALY_EXPORT_TITLE_FULL_TEXT;
  const description = isFiltersActive
    ? ANOMALY_EXPORT_TITLE_ACTIVE_DESCRIPTION_TEXT
    : ANOMALY_EXPORT_TITLE_FULL_DESCRIPTION_TEXT;
  const subTitle = isFiltersActive
    ? ANOMALY_EXPORT_SUB_TITLE_ACTIVE_TEXT
    : null;

  return (
    <>
      <RoundedContainer>
        <Flexbox gap="0.625rem">
          <div>
            <AlertIcon data-testid={ALERT_ICON_TEST_ID} />
          </div>
          <div>
            <Title>{title}</Title>
            {subTitle && <p>{subTitle}</p>}
          </div>
        </Flexbox>
      </RoundedContainer>
      {description && <p>{description}</p>}
    </>
  );
};

export default AnomalyExportSectionMessage;
