export const ANOMALIES_ADVANCED_FILTERS_FORM_TEST_ID =
  "anomalies-advanced-filters-form";

export const ANOMALIES_ADVANCED_START_DATE_FILTER_ID = "startDate";
export const ANOMALIES_ADVANCED_START_DATE_FILTER_LABEL = "Start Date";
export const ANOMALIES_ADVANCED_START_DATE_FILTER_PLACE_HOLDER = "From";
export const ANOMALIES_ADVANCED_START_DATE_FILTER_TEST_ID =
  "anomalies-advanced-start-date-filter";

export const ANOMALIES_ADVANCED_END_DATE_FILTER_ID = "endDate";
export const ANOMALIES_ADVANCED_END_DATE_FILTER_LABEL = "End Date";
export const ANOMALIES_ADVANCED_END_DATE_FILTER_PLACE_HOLDER = "To";
export const ANOMALIES_ADVANCED_END_DATE_FILTER_TEST_ID =
  "anomalies-advanced-end-date-filter";

export const ANOMALIES_ADVANCED_LOCATION_FILTER_ID = "location";
export const ANOMALIES_ADVANCED_LOCATION_FILTER_LABEL = "Location";
export const ANOMALIES_ADVANCED_LOCATION_FILTER_PLACE_HOLDER =
  "Type or choose Location";
export const ANOMALIES_ADVANCED_LOCATION_FILTER_TEST_ID =
  "anomalies-advanced-location-filter";
export const ANOMALIES_ADVANCED_LOCATION_FILTER_OPTION_TEST_ID =
  "anomalies-advanced-location-filter-option";

export const ANOMALIES_ADVANCED_TRADER_FILTER_ID = "trader";
export const ANOMALIES_ADVANCED_TRADER_FILTER_LABEL = "Trader";
export const ANOMALIES_ADVANCED_TRADER_FILTER_PLACE_HOLDER =
  "Type or choose Trader";
export const ANOMALIES_ADVANCED_TRADER_FILTER_TEST_ID =
  "anomalies-advanced-trader-filter";
export const ANOMALIES_ADVANCED_TRADER_FILTER_OPTION_TEST_ID =
  "anomalies-advanced-trader-filter-option";

export const ANOMALIES_ADVANCED_BROKER_FILTER_ID = "broker";
export const ANOMALIES_ADVANCED_BROKER_FILTER_LABEL = "Broker";
export const ANOMALIES_ADVANCED_BROKER_FILTER_PLACE_HOLDER =
  "Type or choose Broker";
export const ANOMALIES_ADVANCED_BROKER_FILTER_TEST_ID =
  "anomalies-advanced-broker-filter";
export const ANOMALIES_ADVANCED_BROKER_FILTER_OPTION_TEST_ID =
  "anomalies-advanced-broker-filter-option";

export const ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_ID = "tradeGroup";
export const ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_LABEL = "Trade Group";
export const ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_PLACE_HOLDER =
  "Type or choose Trade Group";
export const ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_TEST_ID =
  "anomalies-advanced-trade-group-filter";
export const ANOMALIES_ADVANCED_TRADE_GROUP_FILTER_OPTION_TEST_ID =
  "anomalies-advanced-trade-group-filter-option";

export const ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_ID = "counterparty";
export const ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_LABEL = "Counterparty";
export const ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_PLACE_HOLDER =
  "Type or choose Counterparty";
export const ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_TEST_ID =
  "anomalies-advanced-counterparty-filter";
export const ANOMALIES_ADVANCED_COUNTERPARTY_FILTER_OPTION_TEST_ID =
  "anomalies-advanced-counterparty-filter-option";

export const ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_ID = "dealType";
export const ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_LABEL = "Deal Type";
export const ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_PLACE_HOLDER =
  "Type or choose Deal Type";
export const ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_TEST_ID =
  "anomalies-advanced-deal-type-filter";
export const ANOMALIES_ADVANCED_DEAL_TYPE_FILTER_OPTION_TEST_ID =
  "anomalies-advanced-deal-type-filter-option";

export const ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_ID = "investigator";
export const ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_LABEL = "Reviewed by";
export const ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_PLACE_HOLDER =
  "Type or choose Reviewed by";
export const ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_TEST_ID =
  "anomalies-advanced-investigator-filter";
export const ANOMALIES_ADVANCED_INVESTIGATOR_FILTER_OPTION_TEST_ID =
  "anomalies-advanced-investigator-filter-option";
