import {
  endurReports,
  nucleusReports,
} from "../../../../app/services/services.constants";
import { TReportType } from "../../../../app/services/services.types";

const addSelectedReports = (
  reports: TReportType[],
  finalSelectedReports: TReportType[]
) => {
  reports.forEach((el) => {
    if (!finalSelectedReports.includes(el)) {
      finalSelectedReports.push(el);
    }
  });
};

const addSelectedEndurReports = (
  allEndursPrev: boolean,
  finalSelectedReports: TReportType[],
  allEndurs: boolean | undefined = false
) => {
  if (!allEndursPrev && allEndurs) {
    addSelectedReports(endurReports, finalSelectedReports);
  }
};

const addSelectedNucleusReports = (
  allNucleusPrev: boolean,
  finalSelectedReports: TReportType[],
  allNucleus: boolean | undefined = false
) => {
  if (!allNucleusPrev && allNucleus) {
    addSelectedReports(nucleusReports, finalSelectedReports);
  }
};

const removeReportOption = (
  finalSelectedReports: TReportType[],
  reportType: TReportType
) => {
  finalSelectedReports.splice(finalSelectedReports.indexOf(reportType), 1);
};

const removeAllEndurReportsOption = (
  allEndursPrev: boolean,
  finalSelectedReports: TReportType[]
) => {
  if (
    allEndursPrev &&
    finalSelectedReports.filter((el) => endurReports.includes(el)).length <
      endurReports.length
  ) {
    removeReportOption(finalSelectedReports, "all_endur_reports");
  }
};

const removeAllNucleusReportsOption = (
  allNucleusPrev: boolean,
  finalSelectedReports: TReportType[]
) => {
  if (
    allNucleusPrev &&
    finalSelectedReports.filter((el) => nucleusReports.includes(el)).length <
      nucleusReports.length
  ) {
    removeReportOption(finalSelectedReports, "all_nucleus_reports");
  }
};

export const filterReports = (
  report: TReportType[] | undefined,
  previousReports: TReportType[]
): TReportType[] => {
  const allEndursPrev = previousReports?.includes("all_endur_reports");
  const allEndurs = report?.includes("all_endur_reports");
  const allNucleusPrev = previousReports?.includes("all_nucleus_reports");
  const allNucleus = report?.includes("all_nucleus_reports");
  let finalSelectedReports: TReportType[] = report || [];

  /**
   * Add all respective reports and mark them as selected
   */
  addSelectedEndurReports(allEndursPrev, finalSelectedReports, allEndurs);

  addSelectedNucleusReports(allNucleusPrev, finalSelectedReports, allNucleus);

  /**
   * Deselect singles report and all respective reports
   */

  if (allEndursPrev) {
    if (!allEndurs) {
      finalSelectedReports = finalSelectedReports.filter(
        (el) => !endurReports.includes(el)
      );
    } else {
      removeAllEndurReportsOption(allEndursPrev, finalSelectedReports);
    }
  }

  if (allNucleusPrev) {
    if (!allNucleus) {
      finalSelectedReports = finalSelectedReports.filter(
        (el) => !nucleusReports.includes(el)
      );
    } else {
      removeAllNucleusReportsOption(allNucleusPrev, finalSelectedReports);
    }
  }

  return finalSelectedReports;
};
