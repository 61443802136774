import NoAccessIcon from "../../components/icons/NoAccessIcon";
import { ShellEnergyLogo } from "../../components/icons/ShellEnergyLogo";
import {
  NO_ACCESS_CONTACT_EMAIL,
  NO_ACCESS_HEADER_TEXT,
  NO_ACCESS_ICON_TEST_ID,
  NO_ACCESS_MESSAGE,
  NO_ACCESS_MESSAGE_CONTACT,
  SHELL_ENERGY_LOGO_TEST_ID,
} from "./NoAccess.constants";
import {
  NoAccessContainer,
  NoAccessHeader,
  NoAccessShellLogoContainer,
  NoAccessText,
  StyledAnchor,
} from "./NoAccess.styles";

const NoAccess = () => (
  <NoAccessContainer
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <NoAccessIcon testId={NO_ACCESS_ICON_TEST_ID} />
    <NoAccessHeader>{NO_ACCESS_HEADER_TEXT}</NoAccessHeader>
    <NoAccessText>{NO_ACCESS_MESSAGE}</NoAccessText>
    <NoAccessText
      style={{
        marginTop: "2.375rem",
      }}
    >
      {NO_ACCESS_MESSAGE_CONTACT}
    </NoAccessText>
    <StyledAnchor href={`mailto:${NO_ACCESS_CONTACT_EMAIL}`}>
      {NO_ACCESS_CONTACT_EMAIL}
    </StyledAnchor>
    <NoAccessShellLogoContainer>
      <ShellEnergyLogo testId={SHELL_ENERGY_LOGO_TEST_ID} />
    </NoAccessShellLogoContainer>
  </NoAccessContainer>
);

export default NoAccess;
