import { Grid } from "@sede-x/shell-ds-react-framework";
import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useLazyGetEndurTraderAnomalyQuery } from "../../app/services/telescope.anomalies.slice";
import { useTabletMode } from "../../common/hooks";
import { Loading } from "../../components/Loading";
import { anomalyRowDataAtom } from "../Landing/Landing.atoms";
import { AnomalyContainer, AnomalyContentContainer } from "./Anomaly.styles";
import AnomalyComments from "./components/AnomalyComments";
import AnomalyHeaderSection from "./components/AnomalyHeaderSection";
import AnomalyRawDataSection from "./components/AnomalyRawDataSection";
import AnomalyResolution from "./components/AnomalyResolution";
import AnomalyTableDetailSection from "./components/AnomalyTableDetailSection";

const Anomaly = () => {
  // #region Atoms
  const [, updateRowData] = useAtom(anomalyRowDataAtom);
  // #endregion

  // #region isTabletMode
  const isTabletMode = useTabletMode();

  const columnsValue = useMemo(
    () => (isTabletMode ? "1fr" : "2fr 2fr"),
    [isTabletMode]
  );
  // #endregion

  // #region Navigation
  const { id } = useParams<{ id: string }>();
  // #endregion

  // #region Queries
  const [triggerEndurTraderAnomaly, { data: anomaly, isFetching }] =
    useLazyGetEndurTraderAnomalyQuery();
  // #endregion

  // #region useEffect
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (id) {
      triggerEndurTraderAnomaly({ id: +id });
    }
  }, []);

  useEffect(() => {
    if (anomaly?.id) {
      updateRowData(anomaly);
    }
  }, [anomaly]);
  // #endregion

  return isFetching ? (
    <Loading isLoading />
  ) : (
    <AnomalyContainer>
      <AnomalyHeaderSection />
      <AnomalyContentContainer gap="4rem" flexDirection="column">
        <AnomalyTableDetailSection />
        <AnomalyRawDataSection />
        <Grid gap="4rem" columns={columnsValue}>
          <AnomalyComments />
          <AnomalyResolution />
        </Grid>
      </AnomalyContentContainer>
    </AnomalyContainer>
  );
};

export default Anomaly;
