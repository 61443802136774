import { ISvgIconInterface } from "../icons.types";

const LogOutIcon = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5022 0.00219727C7.77834 0.00219727 8.0022 0.226055 8.0022 0.502198V1.5022C8.0022 1.77834 7.77834 2.0022 7.5022 2.0022H3.0022C2.44991 2.0022 2.0022 2.44991 2.0022 3.0022L2.0022 15.0022C2.0022 15.5545 2.44991 16.0022 3.0022 16.0022H7.5022C7.77834 16.0022 8.0022 16.2261 8.0022 16.5022V17.5022C8.0022 17.7783 7.77834 18.0022 7.5022 18.0022H3.0022C1.34534 18.0022 0.00219727 16.6591 0.00219727 15.0022V3.0022C0.00219727 1.34534 1.34534 0.00219727 3.0022 0.00219727H7.5022ZM17.8513 9.35575C18.0466 9.16049 18.0466 8.84391 17.8513 8.64864L13.8513 4.64864C13.656 4.45338 13.3395 4.45338 13.1442 4.64864L12.4371 5.35575C12.2418 5.55101 12.2418 5.8676 12.4371 6.06286L14.3764 8.0022H5.29065C5.01451 8.0022 4.79065 8.22605 4.79065 8.5022V9.5022C4.79065 9.77834 5.01451 10.0022 5.29065 10.0022H14.3764L12.4371 11.9415C12.2418 12.1368 12.2418 12.4534 12.4371 12.6486L13.1442 13.3558C13.3395 13.551 13.656 13.551 13.8513 13.3558L17.8513 9.35575Z"
      fill="#404040"
    />
  </svg>
);

export default LogOutIcon;
