import { Flexbox } from "@sede-x/shell-ds-react-framework";

import { useTabletMode } from "../../../common/hooks";
import ZScoredAnomalyIcon from "../ZScoredAnomalyIcon/index";
import { TWO_FRACTION_DIGITS } from "./ZScoredCellContainer.constants";
import { ZScoredContentContainer } from "./ZScoredCellContainer.styles";
import { IZScoredCellContainerProps } from "./ZScoredCellContainer.types";

const ZScoredCellContainer = ({ score, value }: IZScoredCellContainerProps) => {
  const isTabletMode = useTabletMode();

  return (
    <ZScoredContentContainer
      score={score}
      width={isTabletMode ? "7.125rem" : "100%"}
    >
      <Flexbox
        className="z-score-container__flexbox"
        justifyContent={score !== "none" ? "space-between" : "flex-end"}
        alignItems="center"
      >
        {score !== "none" && <ZScoredAnomalyIcon score={score} />}
        {value.toFixed(TWO_FRACTION_DIGITS)}
      </Flexbox>
    </ZScoredContentContainer>
  );
};

export default ZScoredCellContainer;
