import { Cross } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { useState } from "react";

import { useTabletMode } from "../../common/hooks";
import { COLORS } from "../../theme";
import CornerColoredIndicator from "../CornerColoredIndicator";
import {
  ADVANCED_FILTER_APPLY_BUTTON_TEXT,
  ADVANCED_FILTER_CANCEL_BUTTON_TEXT,
  ADVANCED_FILTER_CLEAR_BUTTON_TEST_ID,
  ADVANCED_FILTER_CLEAR_BUTTON_TEXT,
  ADVANCED_FILTER_HEADER_TEXT,
  ADVANCED_FILTER_OPEN_BUTTON_TEST_ID,
  ADVANCED_FILTER_OPEN_BUTTON_TEXT,
} from "./AdvancedFilter.constants";
import {
  StyledButton,
  StyledClearButton,
  StyledDrawer,
  StyledFilterSquare,
} from "./AdvancedFilter.styles";
import { IAdvanceFilterProps } from "./AdvancedFilter.types";

const AdvancedFilter = ({
  children,
  hasFiltersApplied,
  isClearAvailable,
  onDrawerOpened: onDrawerOpenned,
  onFiltersApplied,
  onFiltersCleared,
  onCancel,
}: IAdvanceFilterProps) => {
  const isTabletMode = useTabletMode();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    onDrawerOpenned();
    setOpen(true);
  };

  const handleClose = () => {
    onCancel();
    setOpen(false);
  };

  const handleClear = () => {
    onFiltersCleared();
  };

  const handleConfirm = () => {
    const success = onFiltersApplied();
    setOpen(!success);
  };

  return (
    <>
      <StyledButton
        data-testid={ADVANCED_FILTER_OPEN_BUTTON_TEST_ID}
        icon={
          <CornerColoredIndicator
            showIndicador={hasFiltersApplied}
            color={COLORS.midBlue}
          >
            <StyledFilterSquare />
          </CornerColoredIndicator>
        }
        variant="outlined"
        iconOnly={isTabletMode}
        onClick={handleOpen}
        padding={isTabletMode ? "1.375rem 1.5rem" : undefined}
      >
        {ADVANCED_FILTER_OPEN_BUTTON_TEXT}
      </StyledButton>
      {open && (
        <StyledDrawer
          open={open}
          placement="right"
          onClose={handleClose}
          onConfirm={handleConfirm}
          onCancel={handleClose}
          confirmText={ADVANCED_FILTER_APPLY_BUTTON_TEXT}
          cancelText={ADVANCED_FILTER_CANCEL_BUTTON_TEXT}
          header={ADVANCED_FILTER_HEADER_TEXT}
          size="medium"
          paddings="tight"
          bodyPadding
          borders
        >
          {isClearAvailable && (
            <StyledClearButton
              data-testid={ADVANCED_FILTER_CLEAR_BUTTON_TEST_ID}
              icon={<Cross />}
              iconPosition="right"
              onClick={handleClear}
              size="xsmall"
            >
              {ADVANCED_FILTER_CLEAR_BUTTON_TEXT}
            </StyledClearButton>
          )}
          {children}
        </StyledDrawer>
      )}
    </>
  );
};

export default AdvancedFilter;
