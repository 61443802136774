import { CORNER_COLORED_INDICATOR_TEST_ID } from "./CornerColoredIndicator.constants";
import { StyledCornerIndicator } from "./CornerColoredIndicator.styles";
import { ICornerColoredIndicator } from "./CornerColoredIndicator.types";

const CornerColoredIndicator = ({
  children,
  showIndicador,
  color,
}: ICornerColoredIndicator) => (
  <div>
    {(showIndicador && (
      <>
        <StyledCornerIndicator
          color={color}
          data-testid={CORNER_COLORED_INDICATOR_TEST_ID}
        />
        {children}
      </>
    )) ||
      children}
  </div>
);

export default CornerColoredIndicator;
