import { ButtonLink } from "@sede-x/shell-ds-react-framework";
import { ChevronLeft } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import styled from "styled-components";

import { COLORS } from "../../theme";

export const StyledButtonLink = styled(ButtonLink)`
  color: ${COLORS.bondiBlue};
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledChevronLeft = styled(ChevronLeft)`
  margin: 0 !important;
  path {
    fill: ${COLORS.bondiBlue} !important;
  }
`;
