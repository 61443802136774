import { Button } from "@sede-x/shell-ds-react-framework";

import { useTabletMode } from "../../../../common/hooks";
import { StyledModal } from "../../../../components/Common.styles";
import { Loading } from "../../../../components/Loading";
import {
  ANOMALY_EXPORT_MODAL_TITLE,
  ANOMALY_MODAL_PRIMARY_ACTION_LABEL,
  ANOMALY_MODAL_SECONDARY_ACTION_LABEL,
} from "./AnomalyExportModal.constants";
import {
  AnomalyExportButtonContainer,
  AnomalyExportButtonModalContainer,
} from "./AnomalyExportModal.styles";
import { IAnomalyExportModalProps } from "./AnomalyExportModal.types";

const AnomalyExportModal = ({
  onConfirmClick,
  children,
  openModal,
  onOpenModalChange,
  isLoading = false,
}: IAnomalyExportModalProps) => {
  const isTabletMode = useTabletMode();

  const handleClose = () => {
    onOpenModalChange(false);
  };

  const handlePrimaryActionClick = () => {
    onConfirmClick();
  };

  return (
    <>
      <StyledModal
        open={openModal}
        title={ANOMALY_EXPORT_MODAL_TITLE}
        onClose={() => {
          handleClose();
        }}
        showFooter={false}
      >
        <AnomalyExportButtonModalContainer>
          {isLoading ? (
            <Loading removeMargin size="large" isLoading={isLoading} />
          ) : (
            children
          )}
          <AnomalyExportButtonContainer>
            <Button
              size={!isTabletMode ? "medium" : "small"}
              variant="outlined"
              onClick={handleClose}
              type="button"
              disabled={isLoading}
            >
              {ANOMALY_MODAL_SECONDARY_ACTION_LABEL}
            </Button>
            <Button
              size={!isTabletMode ? "medium" : "small"}
              onClick={handlePrimaryActionClick}
              type="button"
              disabled={isLoading}
            >
              {ANOMALY_MODAL_PRIMARY_ACTION_LABEL}
            </Button>
          </AnomalyExportButtonContainer>
        </AnomalyExportButtonModalContainer>
      </StyledModal>
    </>
  );
};

export default AnomalyExportModal;
