import env from "../../common/env";
import { TReportType, TTagType } from "./services.types";

// #region tagTypes
export const ENDUR_TRADER_ANOMALY_TAG_TYPE: TTagType = "Endur Trader Anomaly";
export const ENDUR_TRADER_ANOMALIES_TAG_TYPE: TTagType =
  "Endur Trader Anomalies";
export const ANOMALY_COMMENT_TAG_TYPE: TTagType = "Anomaly Comment";
// #endregion

export const TELESCOPE_API_ROUTES = {
  anomaly: {
    reports: "/anomaly/reports",
    resolutions: "/anomaly/resolutions",
    resolutionsMapping: "/anomaly/status/resolutions",
    anomalies: "/anomalies",
    anomaliesParamsValues: "/anomalies/params_values",
    endurDeals: "/anomaly",
  },
  admin: {
    platts: "/admin/platts",
  },
};

export const endurReports: TReportType[] = [
  "endur_trader",
  "endur_broker_trade_group",
  "endur_counterparty_trade_group",
  "endur_location_trade_group",
  "endur_non_bid_days",
  "endur_bid_week_day_1",
  "endur_bid_week_day_1_2",
  "endur_all_bid_week",
];

export const nucleusReports: TReportType[] = [
  "nucleus_trader_location",
  "nucleus_broker_trade_group",
  "nucleus_counterparty_trade_group",
  "nucleus_trader_deal_type",
];

export const ANOMALIES_PER_PAGE = env.REACT_APP_ANOMALIES_PER_PAGE;
export const BASE_API_HOST = env.REACT_APP_BASE_API_HOST;
export const BASE_API_URL = `${BASE_API_HOST}/api/v1`;
