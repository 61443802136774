import { Option } from "@sede-x/shell-ds-react-framework";

import {
  REPORTS_SELECT_ALL_ENDUR_REPORTS_TEST_ID,
  REPORTS_SELECT_ALL_NUCLEUS_REPORTS_TEST_ID,
  REPORTS_SELECT_OPTION_TEST_ID,
} from "./ReportsSelect.constants";
import { StyledSelect } from "./ReportsSelect.styles";
import { IReportsSelectProps } from "./ReportsSelect.types";

const ReportsSelect = ({
  anomalyReportOptions,
  ...props
}: IReportsSelectProps) => (
  <StyledSelect {...props} mode="tags" maxTagCount={1}>
    <Option
      data-testid={REPORTS_SELECT_ALL_ENDUR_REPORTS_TEST_ID}
      value={"all_endur_reports"}
      className="shell-select-container-item-option-header"
    >
      <div aria-hidden="true">
        <span>All Endur Reports</span>
      </div>
    </Option>
    {anomalyReportOptions
      ?.filter((item) => item.systemName === "endur")
      .map((item) => (
        <Option
          data-testid={`${REPORTS_SELECT_OPTION_TEST_ID}-endur`}
          key={item.reportName}
          value={item.reportName}
        >
          {item.displayName}
        </Option>
      ))}
    <Option
      data-testid={REPORTS_SELECT_ALL_NUCLEUS_REPORTS_TEST_ID}
      value={"all_nucleus_reports"}
      className="shell-select-container-item-option-header"
    >
      <div aria-hidden="true">
        <span>All Nucleus Reports</span>
      </div>
    </Option>
    {anomalyReportOptions
      ?.filter((item) => item.systemName === "nucleus")
      .map((item) => (
        <Option
          data-testid={`${REPORTS_SELECT_OPTION_TEST_ID}-nucleus`}
          key={item.reportName}
          value={item.reportName}
        >
          {item.displayName}
        </Option>
      ))}
  </StyledSelect>
);
export default ReportsSelect;
