import { ISvgIconInterface } from "../icons.types";

export const ShellEnergyLogo = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="154"
    height="37"
    viewBox="0 0 154 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.8576 5.05478C67.2636 4.56705 66.5156 4.24751 65.7216 4.24751C65.1386 4.24751 64.3466 4.5906 64.3466 5.25862C64.3466 5.96027 65.2142 6.23205 65.7759 6.41571L66.5947 6.65655C68.3182 7.16244 69.6451 8.00536 69.6451 9.96905C69.6451 11.1665 69.3509 12.4016 68.3664 13.2183C67.4039 14.0229 66.1086 14.3606 64.8539 14.3606C63.3043 14.3606 61.7671 13.848 60.5158 12.9802L61.8956 10.4494C62.6986 11.1315 63.6405 11.6953 64.7432 11.6953C65.5036 11.6953 66.3004 11.3313 66.3004 10.4776C66.3004 9.59704 65.032 9.28556 64.3466 9.09182C62.3294 8.54018 61.0067 8.01882 61.0067 5.68782C61.0067 3.21218 62.7894 1.61916 65.2671 1.61916C66.5156 1.61916 68.0398 2.00329 69.1371 2.61345L67.8576 5.05478ZM73.9152 0.719727V6.85029H73.9578C74.6487 5.94884 75.4841 5.53645 76.6556 5.53645C78.7854 5.53645 79.8077 6.9068 79.8077 9.00235V14.037H76.8267V9.81769C76.8267 8.86174 76.6769 7.86073 75.4841 7.86073C74.2954 7.86073 73.9152 8.86174 73.9152 9.81769V14.037H70.9356V0.719727H73.9152ZM87.4878 8.7198C87.3434 7.93675 86.5996 7.42682 85.7979 7.42682C84.9344 7.42682 84.2181 7.8762 84.0359 8.7198H87.4878ZM90.2708 10.4117H83.9767C83.9767 11.5944 84.6196 12.2348 85.8502 12.2348C86.4875 12.2348 86.9426 12.0363 87.2685 11.4908H90.1381C89.6658 13.4282 87.7882 14.3149 85.866 14.3149C83.0782 14.3149 81.0019 12.7723 81.0019 9.93542C81.0019 7.18464 82.9118 5.53645 85.657 5.53645C88.5692 5.53645 90.2708 7.30034 90.2708 10.1029V10.4117Z"
      fill="#EE282A"
    />
    <mask
      id="mask0_204_50829"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="154"
      height="37"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 37H153.565V0H0V37Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_204_50829)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.4589 14.037H94.4454V0.719727H91.4589V14.037ZM96.2432 14.037H99.2352V0.719727H96.2432V14.037Z"
        fill="#EE282A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0971 17.9328H77.7597L86.2999 28.6662H86.3473V17.9328H91.0099V35.4869H86.3473L77.8072 24.7314H77.7597V35.4863H73.0971V17.9328ZM135.675 17.9328H141.266L144.62 22.7065L147.974 17.9328H153.565L146.952 27.0597V35.4869H142.288V27.0604L135.675 17.9328ZM137.852 25.6853C137.804 28.2471 137.614 30.4348 136.021 32.6231C134.26 35.0214 131.62 36.0689 128.622 36.0689C122.984 36.0689 119.059 32.3204 119.059 26.8034C119.059 21.0993 123.008 17.3509 128.789 17.3509C132.476 17.3509 135.33 18.9809 136.877 22.2631L132.452 24.0795C131.809 22.4031 130.454 21.2857 128.574 21.2857C125.506 21.2857 123.912 24.1495 123.912 26.8269C123.912 29.5501 125.577 32.3204 128.646 32.3204C130.668 32.3204 132.213 31.2959 132.405 29.2709H128.598V25.6853H137.852Z"
        fill="#17BEE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.445 28.2935C116.991 27.8509 118.371 25.7553 118.371 23.3349C118.371 19.5864 115.683 17.9335 112.138 17.9335H105.168V21.7943H109.713V19.7311L115.333 23.3167L109.713 26.9023V24.7261H105.168V35.487H109.83V28.7355H109.878L114.279 35.4877H120.084L114.445 28.2935ZM65.6811 21.797V24.7314H70.9384V28.5963H65.6811V31.6222H71.2251V35.487H61.0184V17.9329H71.2251V21.7963H65.6811V21.797ZM97.7516 21.797V24.7314H103.009V28.5963H97.7516V31.6222H103.296V35.487H93.0896V17.9329H103.296V21.7963H97.7516V21.797Z"
        fill="#17BEE7"
      />
      <path
        d="M18.1377 35.2858H8.15103L7.22635 28.0701L1.16947 23.7748C0.905341 22.5123 0.772207 21.2269 0.772095 19.9382C0.772095 9.34949 9.58791 0.755402 20.449 0.755402C31.3115 0.755402 40.1274 9.35017 40.1274 19.9382C40.1274 21.2265 39.9954 22.5127 39.73 23.7748L33.6745 28.0701L32.7484 35.2858H22.7632L22.187 35.6935C21.682 36.0516 21.0742 36.2444 20.4504 36.2444C19.8267 36.2444 19.2189 36.0516 18.7138 35.6935L18.1377 35.2858V35.2858Z"
        stroke="white"
        strokeWidth="1.02727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1377 35.2858H8.15103L7.22635 28.0702L1.16947 23.7748C0.905345 22.5123 0.772211 21.2269 0.772095 19.9382C0.772095 9.34949 9.58791 0.755402 20.449 0.755402C31.3115 0.755402 40.1273 9.35017 40.1273 19.9382C40.1273 21.2265 39.9947 22.5127 39.73 23.7748L33.6738 28.0702L32.7477 35.2858H22.7632L22.1863 35.6935C21.6814 36.0516 21.0737 36.2444 20.4501 36.2444C19.8264 36.2444 19.2188 36.0516 18.7138 35.6935L18.1377 35.2858Z"
        fill="#FFCD02"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4498 31.9276H11.1919L10.4583 26.2094L4.4798 21.9712C4.00848 20.186 4.17139 18.297 4.9418 16.6143L15.5272 27.0483L5.23674 14.6634C5.64257 13.0561 6.53895 11.6078 7.80661 10.5113L17.0308 26.0331L8.8578 9.04004C9.91689 7.66759 11.391 6.65659 13.0708 6.15067L18.7111 25.4162L14.5902 5.35551C16.1157 4.51773 17.8706 4.16729 19.6096 4.35315L20.4497 25.2144L21.2899 4.35315C23.0287 4.1675 24.7834 4.51818 26.3086 5.35618L22.1891 25.4156L27.8294 6.15C29.5093 6.65602 30.9834 7.66727 32.0424 9.04004L23.8687 26.0331L33.0922 10.5113C34.3606 11.6076 35.2573 13.0563 35.6627 14.664L25.3722 27.0489L35.9577 16.6149C36.4358 17.6604 36.6832 18.7928 36.6837 19.9382C36.6837 20.6244 36.595 21.3072 36.4197 21.9712L30.4419 26.2094L29.7076 31.9276H22.4497L21.3765 32.6891C21.1069 32.8798 20.7827 32.9824 20.4501 32.9824C20.1174 32.9824 19.7933 32.8798 19.5237 32.6891L18.4498 31.9282V31.9276ZM18.137 35.2845H8.15174L7.22705 28.0695L1.1688 23.7748C0.904637 22.5123 0.771503 21.2269 0.771423 19.9382C0.771423 9.34949 9.58655 0.755402 20.4491 0.755402C31.3109 0.755402 40.1267 9.34949 40.1267 19.9382C40.1267 21.2265 39.9933 22.5127 39.7293 23.7748L33.6731 28.0695L32.7477 35.2851H22.7625L22.1864 35.6942C21.6812 36.0521 21.0734 36.2447 20.4497 36.2445C19.8262 36.2449 19.2186 36.0522 18.7138 35.6942L18.137 35.2851V35.2845Z"
        fill="#EE282A"
      />
    </g>
  </svg>
);
