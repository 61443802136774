import {
  AvatarWithText,
  Dropdown,
  Flexbox,
  Menu,
  MenuItem,
} from "@sede-x/shell-ds-react-framework";
import { ChevronDown } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../app/hooks";
import { logout } from "../../Authentication";
import { EMPTY_USER_INFO } from "../../Authentication/Authentication.constants";
import {
  setIsAuthenticated,
  setUserInformation,
} from "../../Authentication/Authentication.slice";
import {
  anomaliesParamsAtom,
  initialParams,
} from "../../features/Landing/Landing.atoms";
import { SHELL_LOGO_TEST_ID } from "../../features/Login/Login.constants";
import { ShellLogo } from "../icons/ShellLogo";
import {
  MENU_ITEMS,
  NAVBAR_DROPDOWN_MENU_ITEMS,
  NAVBAR_TEST_IDS,
} from "./Navbar.constants";
import { StyledMenu, StyledNavBar } from "./Navbar.styles";
import { INavbarMenuItem, INavbarProps } from "./Navbar.types";

const Navbar = ({
  fullName,
  email,
  nameAbbreviation,
  hideMenu,
}: INavbarProps) => {
  // #region atoms
  const [, setFilters] = useAtom(anomaliesParamsAtom);

  // #endregion
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [menuSelected, setMenuSelected] = useState<string[] | undefined>();

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(setUserInformation({ ...EMPTY_USER_INFO }));
      dispatch(setIsAuthenticated(false));
    } catch (e) {
      console.error(e);
    }
  };

  const handleDropdownClick = (item: INavbarMenuItem) => {
    switch (item.label) {
      case "Logout":
        handleLogout();
        setFilters(initialParams);
        break;
      default:
        break;
    }
  };

  const dropDownItems = (
    <Menu>
      {NAVBAR_DROPDOWN_MENU_ITEMS.map((item, index) => (
        <MenuItem
          key={`${item.label}${index}`}
          onClick={() => {
            handleDropdownClick(item);
          }}
        >
          {item.icon}
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );

  // #region UseEffects
  useEffect(() => {
    // get the first part of the path before the second slash
    const selectedMenu = [`/${location.pathname.split("/")[1]}`];
    const menuItem = MENU_ITEMS.find((item) => item.path === selectedMenu[0]);

    setMenuSelected(menuItem ? selectedMenu : undefined);
  }, [location.pathname]);
  // #endregion

  return (
    <StyledNavBar
      leftArea={
        <ShellLogo width="36" height="34" testId={SHELL_LOGO_TEST_ID} />
      }
      rightArea={
        <Dropdown
          className={NAVBAR_TEST_IDS.dropDownContainerTestId}
          overlay={dropDownItems}
        >
          <Flexbox alignItems="center" gap="0.7rem">
            <AvatarWithText
              title={fullName}
              abbreviation={nameAbbreviation}
              description={email}
            />
            <ChevronDown width="15px" height="15px" />
          </Flexbox>
        </Dropdown>
      }
      orientation="horizontal"
      float
    >
      <Flexbox justifyContent="center">
        <StyledMenu
          style={{ gap: "1.5rem" }}
          mode="horizontal"
          selectedKeys={menuSelected}
        >
          {!hideMenu &&
            MENU_ITEMS.map((item) => (
              <MenuItem
                key={item.path}
                onClick={() => {
                  navigate(item.path ?? "");
                }}
                disabled={item.disabled}
              >
                <Flexbox flexDirection="column" alignItems="center">
                  {item.icon}
                  <span>{item.label}</span>
                </Flexbox>
              </MenuItem>
            ))}
        </StyledMenu>
      </Flexbox>
    </StyledNavBar>
  );
};

export default Navbar;
