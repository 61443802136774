/* eslint-disable max-len */

import { ISvgIconInterface } from "../icons.types";

export const UpArrow = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      d="M8.29289 15.7929C8.56904 15.7929 8.79289 15.569 8.79289 15.2929V3.62289L14.0281 8.85809C14.2238 9.05384 14.5414 9.05328 14.7364 8.85684L15.4418 8.14644C15.6359 7.95098 15.6354 7.63536 15.4406 7.44059L8.14645 0.146446C7.95118 -0.0488158 7.6346 -0.0488152 7.43934 0.146447L0.146446 7.43934C-0.0488156 7.6346 -0.0488156 7.95118 0.146447 8.14645L0.849656 8.84966C1.0448 9.0448 1.36113 9.04494 1.55645 8.84997L6.79289 3.62289L6.79289 15.2929C6.79289 15.569 7.01675 15.7929 7.29289 15.7929H8.29289Z"
      fill="currentColor"
    />
  </svg>
);
