import {
  Flexbox,
  Menu,
  Pagination,
  Select,
} from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../theme";
import { breakpoint } from "../../theme/breakpoints";

export const LandingContainer = styled.div`
  padding: 0 1.25rem;
  margin-top: 2rem;
  min-width: 40.625rem;
`;

export const StyledMenu = styled(Menu)`
  .shell-menu-overflow,
  .shell-menu,
  .shell-menu-root,
  .shell-menu-horizontal {
    background-color: transparent;
  }
  .shell-menu-item-content {
    font-weight: 600;
    color: ${COLORS.boulder};
  }

  .shell-menu-item-selected .shell-menu-item-content {
    color: ${COLORS.tundora};
  }
`;

export const BaseDropdownContainer = styled(Flexbox)`
  border: 1px solid ${COLORS.greyGoose};
  border-radius: 2px;
  padding: 0.75rem 1rem;
`;

export const BaseStyledSelect = styled(Select)`
  .shell-select-container-selector {
    height: 3rem;
  }
`;

export const AllReportsDropdownContainer = styled(BaseDropdownContainer)`
  min-width: 11.438rem;

  @media ${breakpoint.mediaQuery.sm} {
    min-width: 25.938rem;
  }
`;

export const AllResolutionsDropdownContainer = styled(BaseDropdownContainer)`
  min-width: 11.438rem;

  @media ${breakpoint.mediaQuery.sm} {
    min-width: 15rem;
  }
`;

export const FiltersSection = styled(Flexbox)`
  background-color: ${COLORS.white};
  margin-top: 1.875rem;
  margin-bottom: 0;
  min-height: 5rem;
  padding: 1rem 0.75rem;

  font-weight: 400;
  font-size: 1rem;
  font-style: italic;

  color: ${COLORS.boulder};
`;

export const NoDataRowContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledPagination = styled(Pagination)`
  margin-top: 1.5rem;
  justify-content: flex-end;
`;

export const TrendDataContainer = styled.div`
  margin-bottom: 1.6875rem;
`;

export const StyledAdvancedFilterContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;
