import { Button, Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../../../theme";

export const ResolutionDropdownContainer = styled(Flexbox)`
  width: 100%;
`;

export const ResolutionLabel = styled(Flexbox)`
  font-size: 11px;
  line-height: 16px;
  color: ${COLORS.tundora};
  font-weight: 600;
  border: none;
  margin-bottom: 5px;
`;

export const ResolutionFieldContainer = styled(Flexbox)`
  flex-direction: column;
  margin-bottom: 30px;

  textarea::placeholder {
    font-style: italic;
  }
`;

export const ResolutionButtonContainer = styled(Flexbox)`
  justify-content: flex-end;
`;

export const StyledResolutionButton = styled(Button)`
  margin-left: 5px;
`;
