import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { useAppDispatch } from "../../app/hooks";
import { EMPTY_USER_INFO } from "../../Authentication/Authentication.constants";
import {
  setIsAuthenticated,
  setUserInformation,
} from "../../Authentication/Authentication.slice";
import InactivityModal from "../InactivityModal";
import {
  IDLE_TIMEOUT,
  MAX_IDLE_TIMEOUT,
  PROMPT_DURATION,
} from "./IdleTimer.constants";
import { IIdleTimer } from "./IdleTimer.types";

const IdleTimer = ({
  idleTimeout = IDLE_TIMEOUT,
  promptTimer = PROMPT_DURATION,
  logout,
}: IIdleTimer) => {
  const promptSeconds = promptTimer / 1000;

  const dispatch = useAppDispatch();
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);

  if (idleTimeout > MAX_IDLE_TIMEOUT) {
    idleTimeout = MAX_IDLE_TIMEOUT;
  }

  useEffect(() => {
    setRemaining(promptTimer / 1000);
  }, []);

  const handleLogout = async () => {
    await logout();
    dispatch(setUserInformation({ ...EMPTY_USER_INFO }));
    dispatch(setIsAuthenticated(false));
    setIsIdleModalOpen(false);
  };

  const closeModal = () => {
    setIsIdleModalOpen(false);
    setRemaining(promptSeconds);
  };

  const onPrompt = () => {
    setIsIdleModalOpen(true);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    onIdle: handleLogout,
    onPrompt,
    onActive: closeModal,
    timeout: idleTimeout,
    promptBeforeIdle: promptTimer,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 4000,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const handleStillHere = () => {
    setIsIdleModalOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <div data-testid="idle-timer-test-div">
      {isIdleModalOpen ? (
        <InactivityModal
          logout={handleLogout}
          remaining={remaining}
          stillHere={handleStillHere}
          data-testid="idle-modal-open"
        />
      ) : (
        <div data-testid="idle-modal-closed" />
      )}
    </div>
  );
};

export default IdleTimer;
