import { COMMENT_MAXIMUM_LENGTH } from "../../Anomaly.constants";
import { COUNTER_LEGEND } from "./CommentCharacterCounter.constants";
import { CommentMaximumCharactersContainer } from "./CommentCharacterCounter.styles";
import { ICommentCharacterCounterProps } from "./CommentCharacterCounter.types";

const CommentCharacterCounter = ({
  commentLength,
}: ICommentCharacterCounterProps) => (
  <CommentMaximumCharactersContainer>
    {COUNTER_LEGEND}
    <span> {COMMENT_MAXIMUM_LENGTH - commentLength}</span>
  </CommentMaximumCharactersContainer>
);

export default CommentCharacterCounter;
