import { Flexbox, Label } from "@sede-x/shell-ds-react-framework";
import { Cross } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";

import { TAnomaliesFilters } from "../../features/Landing/Landing.types";
import {
  ACTIVE_FILTERS_BAR_CLEAR_BUTTON_TEST_ID,
  ACTIVE_FILTERS_BAR_CLEAR_BUTTON_TEXT,
  ACTIVE_FILTERS_BAR_LABEL_TEST_ID,
  ACTIVE_FILTERS_BAR_LABEL_TEXT,
  ACTIVE_FILTERS_BAR_REMOVE_BUTTON_TEST_ID,
  ACTIVE_FILTERS_BAR_TAG_TEST_ID,
} from "./ActiveFiltersBar.constants";
import {
  StyledButton,
  StyledClearButton,
  StyledTag,
} from "./ActiveFiltersBar.styles";
import {
  filtersOrderAndLabels,
  IActiveFiltersBarProps,
  TActiveFilter,
  TActiveFiltersBarVariant,
} from "./ActiveFiltersBar.types";

export const defaultActiveFiltersBarValues: TAnomaliesFilters = {
  startDate: "",
  endDate: "",
  location: [],
  trader: [],
  broker: [],
  tradeGroup: [],
  counterparty: [],
  dealType: [],
  investigator: [],
};

const flatFiltersBarParams = (
  filters: TAnomaliesFilters,
  variant: TActiveFiltersBarVariant
): TActiveFilter[] =>
  Object.entries(filtersOrderAndLabels).flatMap(
    ([key, { label, variant: filterVariant, valueTransformer }]) => {
      const filterValue = filters[key as keyof TAnomaliesFilters];
      const transformer = valueTransformer
        ? valueTransformer
        : (value: string) => value;

      const shouldDisplayFilter =
        filterValue &&
        (filterVariant === undefined || variant === filterVariant);

      if (!shouldDisplayFilter) {
        return [];
      }

      return Array.isArray(filterValue)
        ? filterValue.map(
            (value, i) =>
              ({
                key: `${key}-${i}`,
                value: transformer(
                  typeof value === "string" ? value : value.label
                ),
                label,
                isMultipleValueFilter: true,
                variant,
              } as TActiveFilter)
          )
        : ({
            key,
            value: transformer(
              typeof filterValue === "string" ? filterValue : filterValue.label
            ),
            label,
            isMultipleValueFilter: false,
            variant,
          } as TActiveFilter);
    }
  );

const ActiveFiltersBar = ({
  currentFilters,
  onFiltersRemoved,
  variant,
}: IActiveFiltersBarProps) => {
  const filtersToDisplay = flatFiltersBarParams(currentFilters, variant);

  return filtersToDisplay.length > 0 ? (
    <Flexbox flexWrap="wrap" gap="0.5rem">
      {variant === "advancedFilters" && (
        <Label size="medium" data-testid={ACTIVE_FILTERS_BAR_LABEL_TEST_ID}>
          {ACTIVE_FILTERS_BAR_LABEL_TEXT}
        </Label>
      )}
      {filtersToDisplay.map(({ key, label, value, isMultipleValueFilter }) => (
        <StyledTag
          key={key}
          data-testid={ACTIVE_FILTERS_BAR_TAG_TEST_ID}
          label={`${label}: ${value}`}
          size="small"
          iconPosition="right"
          icon={
            variant === "advancedFilters" && (
              <StyledButton
                data-testid={ACTIVE_FILTERS_BAR_REMOVE_BUTTON_TEST_ID}
                iconOnly={true}
                icon={<Cross />}
                size="xsmall"
                variant="transparent"
                onClick={() => {
                  const filterKey = key.split(
                    "-"
                  )[0] as keyof TAnomaliesFilters;
                  const newFilterValue = isMultipleValueFilter
                    ? {
                        [filterKey]: (
                          (currentFilters[filterKey] || []) as string[]
                        ).filter((_v, i) => `${i}` !== key.split("-")[1]),
                      }
                    : { [filterKey]: "" };

                  onFiltersRemoved &&
                    onFiltersRemoved({
                      ...defaultActiveFiltersBarValues,
                      ...currentFilters,
                      ...newFilterValue,
                    });
                }}
              />
            )
          }
        />
      ))}
      {variant === "advancedFilters" && (
        <StyledClearButton
          data-testid={ACTIVE_FILTERS_BAR_CLEAR_BUTTON_TEST_ID}
          icon={<Cross />}
          iconPosition="right"
          size="xsmall"
          onClick={() => {
            onFiltersRemoved && onFiltersRemoved(defaultActiveFiltersBarValues);
          }}
        >
          {ACTIVE_FILTERS_BAR_CLEAR_BUTTON_TEXT}
        </StyledClearButton>
      )}
    </Flexbox>
  ) : null;
};

export default ActiveFiltersBar;
