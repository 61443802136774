import { atom } from "jotai";

import { ANOMALIES_PER_PAGE } from "../../app/services/services.constants";
import {
  IEndurTraderAnomaliesRowData,
  TAnomaliesParams,
} from "./Landing.types";

export const anomalyRowDataAtom = atom<IEndurTraderAnomaliesRowData | null>(
  null
);

export const initialParams: TAnomaliesParams = {
  limit: ANOMALIES_PER_PAGE,
  offset: 0,
  sort: undefined,
  direction: undefined,
  anomaliesFilters: {
    status: undefined,
    severity: undefined,
    report: undefined,
    resolution: undefined,
    startDate: undefined,
    endDate: undefined,
    trader: undefined,
    location: undefined,
    counterparty: undefined,
    broker: undefined,
    tradeGroup: undefined,
    dealType: undefined,
    investigator: undefined,
  },
};

export const anomaliesParamsAtom = atom<TAnomaliesParams>(initialParams);
export const expandedAnomaliesAtom = atom<React.Key[]>([]);
