import {
  Button,
  Card,
  Flexbox,
  Heading,
  Text,
} from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import { COLORS } from "../../theme";

export const StyledActionText = styled(Text)`
  color: ${({ theme }) => theme.text.onSurface.subtle};
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: "SF Pro Text", sans-serif;
  line-height: 24px;
  color: ${COLORS.outerSpace};
`;

export const StyledButtonWrapper = styled(Flexbox)`
  margin-top: 2.5rem;
`;

export const StyledModalCard = styled(Card)`
  width: 31.25rem;
  padding: 2.5rem;
  color: #${COLORS.blackSqueeze};
`;

export const StyledModalHeading = styled(Heading)`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-family: "Shell Font";
  line-height: 44px;
  color: ${COLORS.trout};
`;

export const StyledModalWrapper = styled(Flexbox)`
  background-color: rgba(0, 0, 0, 0.15);
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 9998;
`;

export const StyledLogoutButton = styled(Button)`
  margin-right: 0.5rem;
`;

export const StyledQuestionText = styled(Text)`
  font-size: 1rem;
  font-family: "SF Pro Text", sans-serif;
  line-height: 24px;
  color: ${COLORS.outerSpace};
`;

export const RemainingTime = styled(Heading)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-family: "Shell Font";
  line-height: 44px;
  background: ${COLORS.blackSqueeze};
  color: ${COLORS.trout};
  height: 4.0625rem;
`;
