import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

export const AnomalyContainer = styled.div`
  min-width: 40.625rem;
`;

export const AnomalyContentContainer = styled(Flexbox)`
  padding: 4rem 2.813rem 2.063rem 2.813rem;
`;
