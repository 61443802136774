const size = {
  xs: "320px",
  sm: "768px",
  md: "991px",
  lg: "1200px",
};

const mediaQuery = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
};

const devices = {
  tablet: 991,
};

export const breakpoint = { size, mediaQuery, devices };
