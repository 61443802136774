import {
  Button,
  Flexbox,
  Grid,
  Heading,
} from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import LoginBg from "../../assets/images/login-bg.png";
import { breakpoint } from "../../theme/breakpoints";

export const StyledGrid = styled(Grid)`
  width: 100vw;
  height: 100vh;
  background: ${`url(${LoginBg})`};
  background-size: cover;
  color: #fff;
`;

export const StyledHeading = styled(Heading)`
  color: #fff;
  font-weight: 600;
  font-size: 6.9vw;

  @media ${breakpoint.mediaQuery.sm} {
    font-size: 2.75rem;
  }
`;

export const StyledLoginButton = styled(Button)`
  width: 17.3125rem;
  height: 3rem;
`;

export const LoginTextColumn = styled(Flexbox)`
  padding: 0 8vw;
  justify-content: flex-end;
  max-width: 700px;

  @media ${breakpoint.mediaQuery.lg} {
    max-width: 60vw;
  }
`;

export const ShellEnergyLogoColumn = styled(Flexbox)`
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
`;

export const StyledParagraph = styled.p`
  font-size: 0.875rem;
`;
