import { Button, Flexbox, MenuItem } from "@sede-x/shell-ds-react-framework";
import classnames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useAtom } from "jotai";
import { TdHTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ANOMALIES_PER_PAGE } from "../../app/services/services.constants";
import {
  IIdentifier,
  IScoreLevel,
  TAnomalyInvestigationStatus,
  TAnomalyResolution,
  TReportType,
  TSortDirection,
} from "../../app/services/services.types";
import { useLazyGetEndurTraderAnomaliesQuery } from "../../app/services/telescope.anomalies.slice";
import {
  useLazyGetAnomalyReportOptionsQuery,
  useLazyGetResolutionsMappingQuery,
  useLazyGetSupportedResolutionsQuery,
} from "../../app/services/telescope.dropdowns.slice";
import { useLazyGetAnomalyDealsExportQuery } from "../../app/services/telescope.export.slice";
import { useTabletMode } from "../../common/hooks";
import { capitalizeFirstLetter } from "../../common/utils";
import ActiveFiltersBar from "../../components/ActiveFiltersBar";
import Header from "../../components/Header";
import { Loading } from "../../components/Loading";
import { INavbarMenuItem } from "../../components/Navbar/Navbar.types";
import DescriptorCellContainer from "../../components/TableAddons/DescriptorCellContainer";
import ExpandIcon from "../../components/TableAddons/ExpandIcon/index";
import NoDataMessageRow from "../../components/TableAddons/NoDataMessageRow";
import ResolutionCellContainer from "../../components/TableAddons/ResolutionCellContainer";
import { SortIcon } from "../../components/TableAddons/TableSortIcon/SortIcon";
import ZScoredCellContainer from "../../components/TableAddons/ZScoredCellContainer";
import { StyledTable } from "../../components/TableWrapper/TableWrapper.styles";
import { appRoutes } from "../../config/routes";
import AnomaliesAdvancedFilter from "./components/AnomaliesAdvancedFilter";
import {
  cleanAnomaliesAdvancedFilterParams,
  TAnomaliesAdvancedFilterParams,
} from "./components/AnomaliesAdvancedFilter/AnomaliesAdvancedFilter.types";
import AnomalyExportSection from "./components/AnomalyExportSection";
import LandingEndurTraderExpandedRow from "./components/LandingEndurTraderExpandedRow";
import ReportsSelect from "./components/ReportsSelect";
import {
  REPORT_SELECT_PLACEHOLDER,
  REPORT_SELECTOR_TEST_ID,
} from "./components/ReportsSelect/ReportsSelect.constants";
import { filterReports } from "./components/ReportsSelect/ReportsSelect.utils";
import ResolutionsSelect from "./components/ResolutionsSelect/index";
import { RESOLUTION_SELECT_PLACEHOLDER } from "./components/ResolutionsSelect/ResolutionsSelect.constants";
import ResponsiveDataView from "./components/ResponsiveDataView";
import TrendData from "./components/TrendData";
import {
  anomaliesParamsAtom,
  anomalyRowDataAtom,
  expandedAnomaliesAtom,
} from "./Landing.atoms";
import {
  ANOMALIES_COLUMNS,
  COLUMN_HEADER_ARIA_LABELS,
  COLUMN_HEADER_TEXTS,
  EXPORT_ERROR_MESSAGE,
  EXPORT_SUCCESS_MESSAGE,
  LANDING_TOP_TABLE_HEADER_TEXT,
  SHELL_TABLE_MAX_WIDTH_CLASS,
  SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
  STATUS_MENU_ITEMS,
} from "./Landing.constants";
import {
  FiltersSection,
  LandingContainer,
  NoDataRowContainer,
  StyledAdvancedFilterContainer,
  StyledMenu,
  StyledPagination,
  TrendDataContainer,
} from "./Landing.styles";
import {
  anomalyParamsToQueryParams,
  IEndurTraderAnomaliesRowData,
  IEndurTraderAnomaliesTableData,
  TAnomaliesFilters,
  TLandingHeaderKeys,
} from "./Landing.types";

dayjs.extend(utc);

const Landing = () => {
  // #region atoms
  const [anomaliesParams, setAnomaliesParams] = useAtom(anomaliesParamsAtom);
  const [expandedAnomalies, setExpandedAnomalies] = useAtom(
    expandedAnomaliesAtom
  );
  const [anomalyRowData, setAnomalyRowData] = useAtom(anomalyRowDataAtom);
  // #endregion

  // #region Navigation
  const navigate = useNavigate();
  // #endregion

  // #region Queries
  const [triggerEndurTraderAnomalies, { isFetching, isError: tableDataError }] =
    useLazyGetEndurTraderAnomaliesQuery();
  const [triggerSupportedResolutions, { data: supportedResolutionsData }] =
    useLazyGetSupportedResolutionsQuery();
  const [triggerResolutionsMapping, { data: resolutionsMappingData }] =
    useLazyGetResolutionsMappingQuery();
  const [triggerAnomalyReportOptions, { data: anomalyReportOptions }] =
    useLazyGetAnomalyReportOptionsQuery();
  const [
    triggerAnomalyDealsExport,
    {
      isError: exportError,
      isSuccess: exportSuccess,
      isLoading: exportLoading,
    },
  ] = useLazyGetAnomalyDealsExportQuery();
  // #endregion

  // #region Local State
  const isDropOpen = useRef(false);
  const [openExportModal, setExportOpenModal] = useState(false);
  const [tableData, setTableData] = useState<
    IEndurTraderAnomaliesTableData | undefined
  >();
  const [resolutionSelected, setResolutionSelected] = useState<
    string | undefined
  >(undefined);
  const [resolutionOptions, setResolutionOptions] = useState<
    INavbarMenuItem<string>[]
  >([]);
  const [reportSelected, setReportSelected] = useState<
    TReportType[] | undefined
  >(anomaliesParams.anomaliesFilters.report?.map((el) => el.value));
  const [finalReportSelected, setFinalReportSelected] = useState<
    TReportType[] | undefined
  >(reportSelected);
  const [order, setOrder] = useState<TSortDirection | undefined>(
    anomaliesParams.direction
  );
  const [orderedBy, setOrderedBy] = useState<TLandingHeaderKeys | undefined>(
    anomaliesParams.sort
  );
  const isTabletMode = useTabletMode();
  const areFiltersActive = useMemo(() => {
    const queryParams = {
      ...anomalyParamsToQueryParams(anomaliesParams),
      limit: undefined,
      offset: undefined,
    };

    return Object.values(queryParams).some((param) => {
      if (Array.isArray(param)) {
        return param.length > 0;
      }
      return !!param;
    });
  }, [anomaliesParams.anomaliesFilters]);
  // #endregion

  // #region Handlers
  const getAnomalies = async () => {
    try {
      const result = await triggerEndurTraderAnomalies({
        queryParams: anomalyParamsToQueryParams(anomaliesParams),
      }).unwrap();

      setTableData(result);
    } catch (error) {
      setTableData(undefined);
    }
  };

  const handleSortColumnClicked = (column: TLandingHeaderKeys) => {
    if (orderedBy === column) {
      switch (order) {
        case "ascending":
          setOrder("descending");
          setAnomaliesParams((prevParams) => ({
            ...prevParams,
            direction: "descending",
            sort: column,
          }));
          break;
        case "descending":
          setOrder(undefined);
          setOrderedBy(undefined);
          setAnomaliesParams((prevParams) => ({
            ...prevParams,
            direction: undefined,
            sort: undefined,
          }));
          break;
        default:
          break;
      }
    } else {
      setOrder("ascending");
      setOrderedBy(column);
      setAnomaliesParams((prevParams) => ({
        ...prevParams,
        direction: "ascending",
        sort: column,
      }));
    }
  };

  const handlePaginationChange = (page: number) => {
    // anomalies per page
    setAnomaliesParams((prevParams) => ({
      ...prevParams,
      offset: (page - 1) * ANOMALIES_PER_PAGE,
    }));
  };

  const handleStatusTabChange = (status?: TAnomalyInvestigationStatus) => {
    setAnomaliesParams((prevParams) => ({
      ...prevParams,
      offset: 0,
      anomaliesFilters: {
        ...prevParams.anomaliesFilters,
        resolution: undefined,
        status: status
          ? {
              label:
                STATUS_MENU_ITEMS.find((s) => s.value === status)?.label ||
                status,
              value: status,
            }
          : undefined,
      },
    }));

    if (status !== "closed" && resolutionSelected) {
      setResolutionSelected(undefined);
    }
  };

  // const handleSeveritySelected = (severity: TScoreLevel) => {

  //   setAnomaliesParams((prevParams) => ({
  //     ...prevParams,
  //     offset: 0,
  //     anomaliesFilters: {
  //       ...prevParams.anomaliesFilters,
  //       severity:
  //         severity !== "none"
  //           ? {
  //               label:
  //                 SEVERITY_OPTIONS.find((s) => s.value === severity)?.label ||
  //                 severity,
  //               value: severity,
  //             }
  //           : undefined,
  //     },
  //   }));
  // };

  const handleAllResolutionSelectChange = (resolution?: string) => {
    setResolutionSelected(resolution);

    setAnomaliesParams((prevParams) => ({
      ...prevParams,
      offset: 0,
      anomaliesFilters: {
        ...prevParams.anomaliesFilters,
        resolution: resolution
          ? {
              label:
                (supportedResolutionsData || []).find(
                  (r) => r.value === resolution
                )?.label || resolution,
              value: resolution,
            }
          : undefined,
      },
    }));
  };

  const handleReportsSelectChange = (report?: TReportType[]) => {
    const previousReports = reportSelected || [];
    const filteredReports = filterReports(report, previousReports);

    setReportSelected(filteredReports);
  };

  const handleDropdownVisibleChange = (isOpen: boolean) => {
    if (!isOpen) {
      setAnomaliesParams((prevParams) => ({
        ...prevParams,
        offset: 0,
        anomaliesFilters: {
          ...prevParams.anomaliesFilters,
          report:
            finalReportSelected &&
            finalReportSelected
              .filter((v) => v !== "all_endur_reports")
              .filter((v) => v !== "all_nucleus_reports")
              .map((v) => ({
                label:
                  (anomalyReportOptions || []).find((r) => r.reportName === v)
                    ?.displayName || v,
                value: v,
              })),
        },
      }));
    }
  };

  const handleFiltersChange = (
    newValues: TAnomaliesAdvancedFilterParams | TAnomaliesFilters
  ) => {
    setAnomaliesParams({
      ...anomaliesParams,
      limit: 0,
      offset: 0,
      anomaliesFilters: { ...anomaliesParams.anomaliesFilters, ...newValues },
    });
  };

  const handleConfirmClick = async () => {
    const queryParams = anomalyParamsToQueryParams(anomaliesParams);

    triggerAnomalyDealsExport({
      queryParams: {
        ...queryParams,
        limit: undefined,
        offset: undefined,
      },
    });
  };

  // #endregion

  // #region Effects - data synchronization
  useEffect(() => {
    if (!isDropOpen.current) {
      setFinalReportSelected(reportSelected);
    }
  }, [reportSelected]);

  useEffect(() => {
    handleDropdownVisibleChange(false);
  }, [finalReportSelected]);

  useEffect(() => {
    setExportOpenModal(false);
    if (exportSuccess) {
      toast.success(EXPORT_SUCCESS_MESSAGE);
      return;
    }

    if (exportError) {
      toast.error(EXPORT_ERROR_MESSAGE);
    }
  }, [exportSuccess, exportError]);

  useEffect(() => {
    // initial synchronization effect
    getAnomalies();
    triggerSupportedResolutions();
    triggerResolutionsMapping();
    triggerAnomalyReportOptions();
  }, []);

  useEffect(() => {
    getAnomalies();
  }, [anomaliesParams]);

  useEffect(() => {
    if (
      anomalyRowData?.id &&
      tableData?.anomalies &&
      tableData.anomalies.length > 0
    ) {
      document
        .querySelector(`[data-row-key="${anomalyRowData?.id}"]`)
        ?.scrollIntoView();
      document
        .querySelector(`[data-card-key="${anomalyRowData?.id}"]`)
        ?.scrollIntoView();
      setAnomalyRowData(null);
    }
  }, [tableData]);

  useEffect(() => {
    if (
      resolutionsMappingData &&
      supportedResolutionsData &&
      supportedResolutionsData.length &&
      anomaliesParams.anomaliesFilters.status?.value
    ) {
      const resolutionsBySeverity =
        resolutionsMappingData[anomaliesParams.anomaliesFilters.status.value];
      // filter supportedResolutionsData by resolutionsBySeverity
      const filteredResolutionOptions = supportedResolutionsData.filter(
        (resolution) =>
          resolutionsBySeverity.includes(resolution.value as TAnomalyResolution)
      );
      setResolutionOptions(filteredResolutionOptions);
    }
  }, [resolutionsMappingData, anomaliesParams, supportedResolutionsData]);
  // #endregion

  const getNoDataMessageRowProps = () => {
    const noAnomalies = !tableData?.anomalies.length;
    const noFilters =
      Object.values(anomaliesParams.anomaliesFilters).filter((v) =>
        Array.isArray(v) ? v.length !== 0 : !!v
      ).length > 0;

    const showNoData = tableDataError && noAnomalies;
    const showNoDataYet = !tableDataError && noAnomalies && noFilters;
    const showNoResultsFound = !tableDataError && noAnomalies && !noFilters;

    return { showNoData, showNoDataYet, showNoResultsFound };
  };

  return (
    <LandingContainer>
      <TrendDataContainer>
        <TrendData />
      </TrendDataContainer>
      <Header text={LANDING_TOP_TABLE_HEADER_TEXT}>
        <StyledMenu
          mode="horizontal"
          size="medium"
          defaultSelectedKeys={[
            capitalizeFirstLetter(
              anomaliesParams.anomaliesFilters.status?.value || "all"
            ),
          ]}
        >
          {STATUS_MENU_ITEMS.map((item) => (
            <MenuItem
              onClick={() => {
                handleStatusTabChange(item.value);
              }}
              key={`${item.label}`}
            >
              {item.label}
            </MenuItem>
          ))}
        </StyledMenu>
      </Header>
      <Flexbox flexDirection="column">
        {/* dropdowns section */}
        <FiltersSection flexDirection="column" gap="1.5rem">
          <Flexbox gap="0.75rem" justifyContent="space-between">
            <Flexbox gap="0.625rem" flexGrow="1">
              <ReportsSelect
                data-testid={REPORT_SELECTOR_TEST_ID}
                placeholder={REPORT_SELECT_PLACEHOLDER}
                anomalyReportOptions={anomalyReportOptions}
                onDropdownVisibleChange={(isDropdownOpen) => {
                  isDropOpen.current = isDropdownOpen;
                  if (!isDropdownOpen) {
                    setFinalReportSelected(reportSelected);
                  }
                }}
                onChange={(value) =>
                  handleReportsSelectChange(value as TReportType[])
                }
                value={reportSelected}
              />
              <ResolutionsSelect
                placeholder={RESOLUTION_SELECT_PLACEHOLDER}
                disabled={
                  anomaliesParams.anomaliesFilters.status?.value !== "closed"
                }
                onChange={(value) =>
                  handleAllResolutionSelectChange(value as string)
                }
                value={resolutionSelected}
                resolutionOptions={resolutionOptions}
              ></ResolutionsSelect>
              {/* <SeverityFilter
                initialValue={
                  anomaliesParams.anomaliesFilters.severity?.value || "none"
                }
                onSeveritySelected={handleSeveritySelected}
              />
              */}
            </Flexbox>
            {isTabletMode ? (
              <StyledAdvancedFilterContainer>
                <AnomaliesAdvancedFilter
                  currentAdvancedFiltersValues={cleanAnomaliesAdvancedFilterParams(
                    anomaliesParams.anomaliesFilters
                  )}
                  onFiltersApplied={handleFiltersChange}
                />
                <AnomalyExportSection
                  areFiltersActive={areFiltersActive}
                  onConfirmClick={handleConfirmClick}
                  isLoading={exportLoading}
                  openModal={openExportModal}
                  onOpenModalChange={(open) => {
                    setExportOpenModal(open);
                  }}
                  onExportClick={() => {
                    setExportOpenModal(true);
                  }}
                >
                  <ActiveFiltersBar
                    currentFilters={anomaliesParams.anomaliesFilters}
                    variant="exportFilters"
                  />
                </AnomalyExportSection>
              </StyledAdvancedFilterContainer>
            ) : (
              <Flexbox gap="0.625rem">
                <AnomaliesAdvancedFilter
                  currentAdvancedFiltersValues={cleanAnomaliesAdvancedFilterParams(
                    anomaliesParams.anomaliesFilters
                  )}
                  onFiltersApplied={handleFiltersChange}
                />
                <AnomalyExportSection
                  areFiltersActive={areFiltersActive}
                  onConfirmClick={handleConfirmClick}
                  isLoading={exportLoading}
                  openModal={openExportModal}
                  onOpenModalChange={(open) => {
                    setExportOpenModal(open);
                  }}
                  onExportClick={() => {
                    setExportOpenModal(true);
                  }}
                >
                  <ActiveFiltersBar
                    currentFilters={anomaliesParams.anomaliesFilters}
                    variant="exportFilters"
                  />
                </AnomalyExportSection>
              </Flexbox>
            )}
          </Flexbox>
          <ActiveFiltersBar
            currentFilters={anomaliesParams.anomaliesFilters}
            onFiltersRemoved={handleFiltersChange}
            variant={"advancedFilters"}
          />
        </FiltersSection>
        {/* Table section */}
        {isFetching ? (
          <Loading isLoading={isFetching} />
        ) : (
          <>
            {isTabletMode ? (
              <ResponsiveDataView
                data={tableData?.anomalies.length ? tableData.anomalies : []}
                columns={ANOMALIES_COLUMNS}
                rowKey={({ id }) => `${id}`}
                expandable={{
                  expandedRowRender: (row) => (
                    <LandingEndurTraderExpandedRow
                      row={row as IEndurTraderAnomaliesRowData}
                      displayMobileView
                    />
                  ),
                  onDetailsClick: (row) => {
                    navigate(`${appRoutes.anomalyDetect}/${row.id}`);
                  },
                }}
                variant="tableView"
              />
            ) : (
              <StyledTable
                data={tableData?.anomalies.length ? tableData.anomalies : []}
                columns={[
                  {
                    title: COLUMN_HEADER_TEXTS.descriptor,
                    dataIndex: "descriptor",
                    width: "300px",
                    className: "descriptor",
                    key: "descriptor",
                    render: (descriptor: IIdentifier[]) => (
                      <DescriptorCellContainer descriptor={descriptor} />
                    ),
                  },
                  {
                    title: (
                      <Flexbox
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="0.75rem"
                      >
                        {COLUMN_HEADER_TEXTS.trade_date_count}
                        <Button
                          key={COLUMN_HEADER_ARIA_LABELS.trade_date_count}
                          variant="transparent"
                          aria-label={`button-${COLUMN_HEADER_ARIA_LABELS.trade_date_count}`}
                          iconOnly
                          icon={
                            <SortIcon<TLandingHeaderKeys>
                              orderedBy={orderedBy}
                              order={order}
                              field={"trade_date_count"}
                            />
                          }
                          onClick={() => {
                            handleSortColumnClicked("trade_date_count");
                          }}
                          value={COLUMN_HEADER_ARIA_LABELS.trade_date_count}
                          size="small"
                          data-testid={`button-${COLUMN_HEADER_ARIA_LABELS.trade_date_count}`}
                        />
                      </Flexbox>
                    ),
                    dataIndex: "tradeDateCount",
                    key: "tradeDateCount",
                    className: classnames(
                      SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                      SHELL_TABLE_MAX_WIDTH_CLASS
                    ),
                    onHeaderCell: () => {
                      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
                      props.className = classnames(
                        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                        SHELL_TABLE_MAX_WIDTH_CLASS
                      );
                      return props;
                    },
                  },
                  {
                    title: (
                      <Flexbox
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="0.75rem"
                      >
                        {COLUMN_HEADER_TEXTS.zscore_count}
                        <Button
                          key={COLUMN_HEADER_ARIA_LABELS.zscore_count}
                          variant="transparent"
                          aria-label={`button-${COLUMN_HEADER_ARIA_LABELS.zscore_count}`}
                          iconOnly
                          icon={
                            <SortIcon<TLandingHeaderKeys>
                              orderedBy={orderedBy}
                              order={order}
                              field={"zscore_count"}
                            />
                          }
                          onClick={() => {
                            handleSortColumnClicked("zscore_count");
                          }}
                          value={COLUMN_HEADER_ARIA_LABELS.zscore_count}
                          size="small"
                          data-testid={`button-${COLUMN_HEADER_ARIA_LABELS.zscore_count}`}
                        />
                      </Flexbox>
                    ),
                    dataIndex: "zScoreCount",
                    key: "zScoreCount",
                    className: classnames(
                      SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                      SHELL_TABLE_MAX_WIDTH_CLASS
                    ),
                    render: ({ value, score }: IScoreLevel) => (
                      <ZScoredCellContainer value={value} score={score} />
                    ),
                    onHeaderCell: () => {
                      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
                      props.className = classnames(
                        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                        SHELL_TABLE_MAX_WIDTH_CLASS
                      );
                      return props;
                    },
                  },
                  {
                    title: (
                      <Flexbox
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="0.75rem"
                      >
                        {COLUMN_HEADER_TEXTS.zscore_volume}
                        <Button
                          key={COLUMN_HEADER_ARIA_LABELS.zscore_volume}
                          variant="transparent"
                          aria-label={`button-${COLUMN_HEADER_ARIA_LABELS.zscore_volume}`}
                          iconOnly
                          icon={
                            <SortIcon<TLandingHeaderKeys>
                              orderedBy={orderedBy}
                              order={order}
                              field={"zscore_volume"}
                            />
                          }
                          onClick={() => {
                            handleSortColumnClicked("zscore_volume");
                          }}
                          value={COLUMN_HEADER_ARIA_LABELS.zscore_volume}
                          size="small"
                          data-testid={`button-${COLUMN_HEADER_ARIA_LABELS.zscore_volume}`}
                        >
                          <div className="animate">
                            {COLUMN_HEADER_ARIA_LABELS.zscore_volume}
                          </div>
                        </Button>
                      </Flexbox>
                    ),
                    dataIndex: "zScoreVolume",
                    key: "zScoreVolume",
                    className: SHELL_TABLE_MAX_WIDTH_CLASS,
                    render: ({ value, score }: IScoreLevel) => (
                      <ZScoredCellContainer value={value} score={score} />
                    ),
                    onHeaderCell: () => {
                      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
                      props.className = classnames(
                        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                        SHELL_TABLE_MAX_WIDTH_CLASS
                      );
                      return props;
                    },
                  },
                  {
                    title: (
                      <Flexbox
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="0.75rem"
                      >
                        {COLUMN_HEADER_TEXTS.zscore_average}
                        <Button
                          key={COLUMN_HEADER_ARIA_LABELS.zscore_average}
                          variant="transparent"
                          aria-label={`button-${COLUMN_HEADER_ARIA_LABELS.zscore_average}`}
                          iconOnly
                          icon={
                            <SortIcon<TLandingHeaderKeys>
                              orderedBy={orderedBy}
                              order={order}
                              field={"zscore_average"}
                            />
                          }
                          onClick={() => {
                            handleSortColumnClicked("zscore_average");
                          }}
                          value={COLUMN_HEADER_ARIA_LABELS.zscore_average}
                          size="small"
                          data-testid={`button-${COLUMN_HEADER_ARIA_LABELS.zscore_average}`}
                        >
                          <div className="animate">
                            {COLUMN_HEADER_ARIA_LABELS.zscore_average}
                          </div>
                        </Button>
                      </Flexbox>
                    ),
                    dataIndex: "zScoreAverage",
                    key: "zScoreAverage",
                    className: SHELL_TABLE_MAX_WIDTH_CLASS,
                    render: ({ value, score }: IScoreLevel) => (
                      <ZScoredCellContainer value={value} score={score} />
                    ),
                    onHeaderCell: () => {
                      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
                      props.className = classnames(
                        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                        SHELL_TABLE_MAX_WIDTH_CLASS
                      );
                      return props;
                    },
                  },
                  {
                    title: (
                      <Flexbox
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="0.75rem"
                      >
                        {COLUMN_HEADER_TEXTS.reporting_date}
                        <Button
                          key={COLUMN_HEADER_ARIA_LABELS.reporting_date}
                          variant="transparent"
                          aria-label={`button-${COLUMN_HEADER_ARIA_LABELS.reporting_date}`}
                          iconOnly
                          icon={
                            <SortIcon<TLandingHeaderKeys>
                              orderedBy={orderedBy}
                              order={order}
                              field={"reporting_date"}
                            />
                          }
                          onClick={() => {
                            handleSortColumnClicked("reporting_date");
                          }}
                          value={COLUMN_HEADER_ARIA_LABELS.reporting_date}
                          size="small"
                          data-testid={`button-${COLUMN_HEADER_ARIA_LABELS.reporting_date}`}
                        >
                          <div className="animate">
                            {COLUMN_HEADER_ARIA_LABELS.reporting_date}
                          </div>
                        </Button>
                      </Flexbox>
                    ),
                    dataIndex: "reportingDate",
                    key: "reportingDate",
                    className: classnames(
                      SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                      SHELL_TABLE_MAX_WIDTH_CLASS
                    ),
                    render: (reportingDate) =>
                      dayjs.utc(reportingDate).format("MM/DD/YYYY"),
                    onHeaderCell: () => {
                      const props: TdHTMLAttributes<HTMLTableCellElement> = {};
                      props.className = classnames(
                        SHELL_TABLE_TEXT_ALIGN_RIGHT_CLASS,
                        SHELL_TABLE_MAX_WIDTH_CLASS
                      );
                      return props;
                    },
                  },
                  {
                    title: (
                      <Flexbox
                        alignItems="center"
                        justifyContent="flex-start"
                        gap="0.75rem"
                      >
                        {COLUMN_HEADER_TEXTS.resolution}
                        <Button
                          key={COLUMN_HEADER_ARIA_LABELS.resolution}
                          variant="transparent"
                          aria-label={`button-${COLUMN_HEADER_ARIA_LABELS.resolution}`}
                          iconOnly
                          icon={
                            <SortIcon<TLandingHeaderKeys>
                              orderedBy={orderedBy}
                              order={order}
                              field={"resolution"}
                            />
                          }
                          onClick={() => {
                            handleSortColumnClicked("resolution");
                          }}
                          value={COLUMN_HEADER_ARIA_LABELS.resolution}
                          size="small"
                          data-testid={`button-${COLUMN_HEADER_ARIA_LABELS.resolution}`}
                        >
                          <div className="animate">
                            {COLUMN_HEADER_ARIA_LABELS.resolution}
                          </div>
                        </Button>
                      </Flexbox>
                    ),
                    dataIndex: "resolution",
                    key: "resolution",
                    width: "150px",
                    render: (resolution: TAnomalyResolution) => (
                      <ResolutionCellContainer
                        resolution={resolution}
                      ></ResolutionCellContainer>
                    ),
                  },
                ]}
                rowKey={({ id }) => `${id}`}
                expandable={{
                  expandIcon: ExpandIcon,
                  expandedRowRender: (row) => (
                    <LandingEndurTraderExpandedRow
                      onClick={() => {
                        navigate(`${appRoutes.anomalyDetect}/${row.id}`);
                      }}
                      row={row as IEndurTraderAnomaliesRowData}
                    />
                  ),
                  onExpandedRowsChange: (expandedRows) => {
                    setExpandedAnomalies([...expandedRows]);
                  },
                  defaultExpandedRowKeys: expandedAnomalies,
                }}
              />
            )}

            <div className="pagination-container">
              {tableData && tableData?.totalAnomalies !== 0 && (
                <StyledPagination
                  pageSize={ANOMALIES_PER_PAGE}
                  total={tableData.totalAnomalies}
                  onChange={handlePaginationChange}
                  current={anomaliesParams.offset / ANOMALIES_PER_PAGE + 1}
                />
              )}
            </div>
            <div className="message-container">
              {!tableData?.anomalies.length && (
                <NoDataRowContainer>
                  <NoDataMessageRow {...getNoDataMessageRowProps()} />
                </NoDataRowContainer>
              )}
            </div>
          </>
        )}
      </Flexbox>
    </LandingContainer>
  );
};

export default Landing;
