import { Flexbox } from "@sede-x/shell-ds-react-framework";
import {
  CommentAddSolid,
  InboxSolid,
} from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../../../app/hooks";
import { IAnomalyComment } from "../../../../app/services/services.types";
import {
  useAddAnomalyCommentMutation,
  useLazyGetAnomalyCommentsQuery,
} from "../../../../app/services/telescope.anomalies.slice";
import { selectUserInformation } from "../../../../Authentication/Authentication.slice";
import { Loading } from "../../../../components/Loading";
import { anomalyRowDataAtom } from "../../../Landing/Landing.atoms";
import CommentItem from "../CommentItem";
import CommentModal from "../CommentModal";
import {
  ADD_COMMENT_BUTTON_TEXT,
  ADD_COMMENT_MODAL_TEST_ID,
  COMMENTS_HEADER,
  NO_COMMENTS_TEXT,
} from "./AnomalyComments.constants";
import {
  AnomalyCommentsContainer,
  CommentButton,
  CommentsSection,
  HeaderSection,
  NoCommentsContainer,
  StyledTopHeader,
} from "./AnomalyComments.styles";

const AnomalyComments = () => {
  // #region Atoms
  const [anomaly] = useAtom(anomalyRowDataAtom);
  // #endregion

  // #region Local state
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  // #endregion

  // #region global state
  const userInformation = useAppSelector(selectUserInformation);
  // #endregion

  // #region Queries
  const [
    triggerAnomalyComments,
    { data: anomalyDealCommentsData, isFetching },
  ] = useLazyGetAnomalyCommentsQuery();
  // #endregion

  // #region Mutations
  const [addComment] = useAddAnomalyCommentMutation();

  // #region useEffect
  useEffect(() => {
    if (anomaly?.id) {
      triggerAnomalyComments({ anomalyId: anomaly.id });
    }
  }, [anomaly]);
  // #endregion

  const renderComments = () =>
    anomalyDealCommentsData?.map((comment: IAnomalyComment, index: number) => {
      const commentProps = {
        className: "comment-item",
        anomalyComment: comment,
        latestComment: index === 0,
      };

      return <CommentItem key={comment.id} {...commentProps} />;
    });

  const renderNoCommentsOrLoading = () => {
    if (!isFetching) {
      return (
        <NoCommentsContainer
          justifyContent="center"
          alignItems="center"
          gap="0.625rem"
        >
          <InboxSolid width="1.25rem" height="1rem" />
          <span className="max-content">{NO_COMMENTS_TEXT}</span>
        </NoCommentsContainer>
      );
    } else {
      return (
        <Flexbox justifyContent="center">
          <Loading isLoading={isFetching} />
        </Flexbox>
      );
    }
  };

  return (
    <AnomalyCommentsContainer flexDirection="column">
      <HeaderSection gap="2rem">
        <StyledTopHeader>{COMMENTS_HEADER}</StyledTopHeader>
        <CommentButton
          iconPosition="right"
          icon={<CommentAddSolid width="1rem" height="0.938rem" />}
          onClick={() => {
            setIsCommentModalOpen(true);
          }}
        >
          <span className="max-content">{ADD_COMMENT_BUTTON_TEXT}</span>
        </CommentButton>
      </HeaderSection>
      {/* Comments section */}
      <CommentsSection
        hideScroll={
          isFetching ||
          !anomalyDealCommentsData?.length ||
          (anomalyDealCommentsData && anomalyDealCommentsData.length === 1)
        }
        flexDirection="column"
      >
        {!isFetching &&
        anomalyDealCommentsData &&
        anomalyDealCommentsData.length > 0
          ? renderComments()
          : renderNoCommentsOrLoading()}
      </CommentsSection>
      <CommentModal
        data-testid={ADD_COMMENT_MODAL_TEST_ID}
        open={isCommentModalOpen}
        onClose={() => {
          setIsCommentModalOpen(false);
        }}
        onSecondaryActionClick={() => {
          setIsCommentModalOpen(false);
        }}
        onSaveClick={(comment) => {
          if (anomaly?.id) {
            addComment({
              anomalyId: anomaly.id,
              investigatorID: userInformation?.id,
              resolution: anomaly.resolution,
              status: anomaly.status,
              comment,
            });
            setIsCommentModalOpen(false);
          }
        }}
      />
    </AnomalyCommentsContainer>
  );
};

export default AnomalyComments;
