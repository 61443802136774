import { Flexbox } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

import DescriptorCellContainer from "../../../../components/TableAddons/DescriptorCellContainer";
import { COLORS } from "../../../../theme";

export const TopHeaderContainer = styled(Flexbox)`
  background-color: ${COLORS.athensGray};
  padding: 2.5rem 2.813rem 2.063rem 2.813rem;
  border-bottom: 2px solid ${COLORS.regentStBlue};
`;

export const StyledDescriptorCellContainer = styled(DescriptorCellContainer)`
  > div:first-child {
    font-size: 1.75rem;
    font-weight: 700;

    & > span {
      font-weight: 700;
    }
  }

  > div:nth-child(2) {
    font-size: 1.25rem;
    margin-top: 0.5rem;

    & > span {
      font-weight: 400;
    }
  }
`;

export const AnomalyModalIntroContainer = styled.div`
  margin-bottom: 2.188rem;
  font-family: "SF Pro Text", sans-serif;
  color: ${COLORS.outerSpace};
`;
