import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";
import { IAuthenticatedState, IUserInformation } from "./Authentication.types";

export const AUTHENTICATION_NAMESPACE = "authentication";

const initialState: IAuthenticatedState = {
  isAuthenticated: false,
  userInformation: {
    firstName: "",
    lastName: "",
    email: "",
    id: "",
  },
};

export const authenticatedSlice = createSlice({
  name: AUTHENTICATION_NAMESPACE,
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUserInformation: (state, action: PayloadAction<IUserInformation>) => {
      state.userInformation = action.payload;
    },
  },
});

// selectors
export const selectIsAuthenticated = (state: RootState) =>
  state.authentication.isAuthenticated;

export const selectUserInformation = (state: RootState) =>
  state.authentication.userInformation;

// Action creators are generated for each case reducer function
export const { setIsAuthenticated, setUserInformation } =
  authenticatedSlice.actions;

export default authenticatedSlice.reducer;
