import { Flexbox } from "@sede-x/shell-ds-react-framework";

import { useTabletMode } from "../../../common/hooks";
import { StyledSpan } from "./DescriptorCellContainer.styles";
import { IDescriptorCellContainerProps } from "./DescriptorCellContainer.types";

const DescriptorCellContainer = ({
  descriptor,
  ...props
}: IDescriptorCellContainerProps) => {
  const isTabletMode = useTabletMode();

  return (
    <Flexbox flexDirection="column" {...props}>
      {descriptor.map((descr, index) => (
        <div key={index}>
          <StyledSpan fontWeight={isTabletMode ? "normal" : "600"}>
            {descr.displayName}:
          </StyledSpan>{" "}
          {descr.value}
        </div>
      ))}
    </Flexbox>
  );
};

export default DescriptorCellContainer;
