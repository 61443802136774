import { Flexbox } from "@sede-x/shell-ds-react-framework";

import { ShellEnergyLogo } from "../../components/icons/ShellEnergyLogo";
import { ShellLogo } from "../../components/icons/ShellLogo";
import {
  LOGIN_BUTTON_TEXT,
  LOGIN_HEADING_TEXT,
  LOGIN_MESSAGE,
  SHELL_ENERGY_LOGO_TEST_ID,
  SHELL_LOGO_TEST_ID,
} from "./Login.constants";
import {
  LoginTextColumn,
  ShellEnergyLogoColumn,
  StyledGrid,
  StyledHeading,
  StyledLoginButton,
  StyledParagraph,
} from "./Login.styles";
import { ILoginProps } from "./login.types";

const Login = ({ login, ...props }: ILoginProps) => (
  <StyledGrid {...props}>
    {/* first column */}
    <LoginTextColumn flexDirection="column" gap="1.25rem">
      {/* heading and logo */}
      <Flexbox alignItems="center" gap="2rem">
        <ShellLogo testId={SHELL_LOGO_TEST_ID} width={"64"} height={"64"} />
        <StyledHeading>{LOGIN_HEADING_TEXT}</StyledHeading>
      </Flexbox>
      <StyledParagraph>{LOGIN_MESSAGE}</StyledParagraph>
      <StyledLoginButton
        name="Log in"
        variant="filled"
        onClick={() => {
          login();
        }}
      >
        {LOGIN_BUTTON_TEXT}
      </StyledLoginButton>
    </LoginTextColumn>

    {/* second column */}
    <ShellEnergyLogoColumn
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <ShellEnergyLogo testId={SHELL_ENERGY_LOGO_TEST_ID} />
    </ShellEnergyLogoColumn>
  </StyledGrid>
);

export default Login;
