import { TextArea } from "@sede-x/shell-ds-react-framework";
import { useAtom } from "jotai";
import { ChangeEvent, useState } from "react";

import { useAppSelector } from "../../../../app/hooks";
import { TAnomalyResolution } from "../../../../app/services/services.types";
import {
  useGetAnomalyResolutionsStatusQuery,
  useUpdateAnomalyCommentMutation,
} from "../../../../app/services/telescope.anomalies.slice";
import { useGetSupportedResolutionsQuery } from "../../../../app/services/telescope.dropdowns.slice";
import { selectUserInformation } from "../../../../Authentication/Authentication.slice";
import ResolutionsSelect from "../../../Landing/components/ResolutionsSelect/index";
import {
  anomalyRowDataAtom,
  expandedAnomaliesAtom,
} from "../../../Landing/Landing.atoms";
import { COMMENT_MAXIMUM_LENGTH } from "../../Anomaly.constants";
import {
  AnomalyCommentsContainer,
  CommentContainer,
  HeaderSection,
  StyledTopHeader,
} from "../AnomalyComments/AnomalyComments.styles";
import { IAnomalyResolutionProps } from "../AnomalyComments/AnomalyComments.types";
import CommentCharacterCounter from "../CommentCharacterCounter";
import {
  DEFAULT_COMMENT_TEXT,
  DISCARD,
  PLACEHOLDER,
  RESOLUTION_COMMENT,
  RESOLUTION_HEADER,
  RESOLUTION_LABEL,
  SAVE_CHANGES,
  TEXTAREA_RESOLUTION_PLACEHOLDER,
} from "./AnomalyResolution.constants";
import {
  ResolutionButtonContainer,
  ResolutionDropdownContainer,
  ResolutionFieldContainer,
  ResolutionLabel,
  StyledResolutionButton,
} from "./AnomalyResolution.styles";

const AnomalyResolution = ({
  enableModalView = false,
  onDiscardClick,
  onSaveSuccessfully,
}: IAnomalyResolutionProps) => {
  // #region Atoms
  const [anomaly] = useAtom(anomalyRowDataAtom);
  const [expandedAnomalies, setExpandedAnomalies] = useAtom(
    expandedAnomaliesAtom
  );
  // #endregion

  // #region Queries & Mutations
  const { data: supportedResolutions } = useGetSupportedResolutionsQuery();
  const [updateAnomalyComment] = useUpdateAnomalyCommentMutation();
  const statusOptions = useGetAnomalyResolutionsStatusQuery().data;
  // #endregion

  // #region Global State
  const userInformation = useAppSelector(selectUserInformation);
  // #endregion

  // #region State
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [resolutionComment, setResolutionComment] = useState<string>("");
  const [invalidComment, setInvalidComment] = useState(false);
  const [selectedResolution, setSelectedResolution] =
    useState<TAnomalyResolution | null>(null);
  const [isInValid, setIsInvalid] = useState(false);
  // #endregion

  // #region Callbacks & Handlers
  const reset = () => {
    setSelectedResolution(null);
    setResolutionComment("");
    setIsInvalid(false);
    setIsSaving(false);
  };

  const validateInput = () => {
    if (!selectedResolution) {
      setIsInvalid(true);
      return false;
    }

    if (resolutionComment.length > COMMENT_MAXIMUM_LENGTH) {
      setInvalidComment(true);
      return false;
    }

    return true;
  };

  const saveAnomalyComment = async () => {
    try {
      if (anomaly && statusOptions && selectedResolution) {
        setIsSaving(true);
        const result = await updateAnomalyComment({
          anomalyId: anomaly.id,
          investigatorID: userInformation.id,
          status: statusOptions[selectedResolution][0],
          resolution: selectedResolution,
          comment: resolutionComment || DEFAULT_COMMENT_TEXT,
        });

        if (result) {
          reset();
          setExpandedAnomalies(
            expandedAnomalies.filter((id) => id !== `${anomaly.id}`)
          );
          onSaveSuccessfully && onSaveSuccessfully();
        }
      }
    } catch (e) {
      console.log(e);
      reset();
    }
  };

  const validateAndSave = async () => {
    if (validateInput()) {
      await saveAnomalyComment();
    }
  };

  // #endregion

  return (
    <AnomalyCommentsContainer flexDirection="column">
      {!enableModalView && (
        <HeaderSection gap="2rem">
          <StyledTopHeader>{RESOLUTION_HEADER}</StyledTopHeader>
        </HeaderSection>
      )}
      <CommentContainer
        flexDirection="column"
        removeSpaces={enableModalView}
        removeBorder={enableModalView}
      >
        <ResolutionFieldContainer>
          <ResolutionLabel>{RESOLUTION_LABEL}</ResolutionLabel>
          <ResolutionDropdownContainer>
            <ResolutionsSelect
              data-testid={
                isInValid ? "resolution-select-error" : "resolution-select"
              }
              invalid={isInValid}
              value={selectedResolution}
              onChange={(value) => {
                setSelectedResolution(value as TAnomalyResolution);
                if (value && isInValid) {
                  setIsInvalid(false);
                }
              }}
              aria-label={RESOLUTION_LABEL}
              optionLabelProp="label"
              options={supportedResolutions}
              placeholder={PLACEHOLDER}
              disableMaxWidth
            />
          </ResolutionDropdownContainer>
        </ResolutionFieldContainer>
        <ResolutionFieldContainer>
          <ResolutionLabel>{RESOLUTION_COMMENT}</ResolutionLabel>
          <TextArea
            rows={5}
            value={resolutionComment}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              const value = e.target.value.trim();
              setResolutionComment(value);
              if (value && invalidComment) {
                setInvalidComment(false);
              }
            }}
            data-testid="resolution-comments"
            placeholder={TEXTAREA_RESOLUTION_PLACEHOLDER}
            invalid={invalidComment}
          ></TextArea>
          <CommentCharacterCounter commentLength={resolutionComment.length} />
        </ResolutionFieldContainer>
        <ResolutionButtonContainer>
          <StyledResolutionButton
            size={!enableModalView ? "medium" : "small"}
            variant="outlined"
            onClick={() => {
              reset();
              onDiscardClick && onDiscardClick();
            }}
          >
            {DISCARD}
          </StyledResolutionButton>
          <StyledResolutionButton
            size={!enableModalView ? "medium" : "small"}
            onClick={validateAndSave}
            loading={isSaving}
          >
            {SAVE_CHANGES}
          </StyledResolutionButton>
        </ResolutionButtonContainer>
      </CommentContainer>
    </AnomalyCommentsContainer>
  );
};

export default AnomalyResolution;
