import styled from "styled-components";

import { COLORS } from "../../../../theme";

export const RoundedContainer = styled.div`
  border: 1.5px solid ${COLORS.bondiBlue};
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.25rem 2.375rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.625rem;
`;

export const Title = styled.h2`
  font-weight: bold;
`;
