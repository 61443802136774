/* eslint-disable max-len */

import { ISvgIconInterface } from "../icons.types";

export const Sort = ({ testId, ...rest }: ISvgIconInterface) => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.54645 6.83934C1.35118 7.0346 1.0346 7.0346 0.83934 6.83934L0.146446 6.14645C-0.0488156 5.95118 -0.0488156 5.6346 0.146447 5.43934L5.43934 0.146447C5.6346 -0.0488155 5.95119 -0.0488155 6.14645 0.146447L11.4393 5.43934C11.6346 5.6346 11.6346 5.95118 11.4393 6.14645L10.7464 6.83934C10.5512 7.0346 10.2346 7.0346 10.0393 6.83934L5.79289 2.59289L1.54645 6.83934ZM1.54645 12.7464C1.35118 12.5512 1.0346 12.5512 0.83934 12.7464L0.146447 13.4393C-0.048815 13.6346 -0.048815 13.9512 0.146447 14.1464L5.43934 19.4393C5.6346 19.6346 5.95118 19.6346 6.14645 19.4393L11.4393 14.1464C11.6346 13.9512 11.6346 13.6346 11.4393 13.4393L10.7464 12.7464C10.5512 12.5512 10.2346 12.5512 10.0393 12.7464L5.79289 16.9929L1.54645 12.7464Z"
      fill=""
    />
  </svg>
);
