export const COLORS = {
  boulder: "#757575",
  white: "#ffffff",
  athensGray: "#e9ecf1",
  tundora: "#404040",
  greyGoose: "#D0D0D0",
  alizarinCrimsonWithOpacity: "#DD1D2033",
  champagne: "#FBE6CB",
  funGreenWithOpacitiy: "#00844233",
  funGreen: "#008542",
  iceberg: "#D0ECF1",
  tahitiGold: "#EB8705",
  cinnabar: "#E4494C",
  eggSour: "#FFF3DB",
  supernova: "#FBCF09",
  alizarinCrimson: "#DD1D21",
  trout: "#49505A",
  bondiBlue: "#0097A9",
  regentStBlue: "#99D5E4",
  midBlue: "#0097bb",
  outerSpace: "#363D44",
  mariner: "#3173C9",
  mirage: "#14191F",
  whiteSmoke: "#F5F5F5",
  blackSqueeze: "#F4FAFB",
  whiteLilac: "#F5F7FA",
};
