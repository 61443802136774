import HighSeverityIcon from "../../icons/HighSeverity";
import LowSeverityIcon from "../../icons/LowSeverity";
import MediumSeverityIcon from "../../icons/MediumSeverity";
import {
  HIGH_SCORE_ICON_TEST_ID,
  LOW_SCORE_ICON_TEST_ID,
  MEDIUM_SCORE_ICON_TEST_ID,
} from "./ZScoredAnomalyIcon.constants";
import { IZScoredAnomalyIconProps } from "./ZScoredAnomalyIcon.types";

const ZScoredAnomalyIcon = ({ score }: IZScoredAnomalyIconProps) => {
  if (score === "high") {
    return <HighSeverityIcon testId={HIGH_SCORE_ICON_TEST_ID} />;
  }

  if (score === "medium") {
    return <MediumSeverityIcon testId={MEDIUM_SCORE_ICON_TEST_ID} />;
  }

  if (score === "low") {
    return <LowSeverityIcon testId={LOW_SCORE_ICON_TEST_ID} />;
  }

  return null;
};

export default ZScoredAnomalyIcon;
