export function abbrevName(name: string) {
  if (!name) {
    return "";
  }

  // split the name into an array of strings
  const nameArray = name.split(" ");

  if (nameArray.length === 1) {
    return `${nameArray[0][0].toUpperCase()}${nameArray[0][0].toUpperCase()}`;
  }

  return `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase();
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function cleanNullishValues<T extends Record<string, unknown>>(
  obj: T
): T {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, v]) =>
      Array.isArray(v) ? v.length > 0 : v != null
    )
  ) as T;
}

export function toStringParams(params: Record<string, unknown>) {
  return Object.entries(params)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((arrayValue) => `${key}=${arrayValue}`).join("&")
        : `${key}=${value}`
    )
    .join("&");
}
